import React, { Component, Fragment,useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import alertService from "../services/sweetAlert";
import { useNavigate } from 'react-router-dom';

function Forgot () {
  const [email, setEmail] = useState("");
  const { forgetpwd } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit  =async (e)=> {
    e.preventDefault();
    alertService.info("Reset password", "if we find your email address in system, we will send you a link to reset");
  
     forgetpwd( email);
    navigate('/login');
    
  }

    return (
      <Fragment>
    
          <div className="nav-header bg-transparent shadow-none border-0">
          
        <div className="nav-top">
            <Link to="/">
            {" "}
            <img className="w60" src="https://files.geetsuhane.com/logo/gs.gif"></img>
          </Link>
              <button className="nav-menu me-0 ms-auto"></button>

              <Link
                to="/login"
                className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl"
              >
                Login
              </Link>
              <Link
                to="/register"
                className="header-btn d-none d-lg-block bg-current fw-500 text-white "
              >
                Register
              </Link>
            </div>
          </div>

          <div className="row">
           
            <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
              <div className="card shadow-none border-0 ms-auto me-auto login-card">
                <div className="card-body rounded-0 text-left">
                  <h2 className="fw-700 display1-size display2-md-size mb-4">
                    Change <br />
                    your password
                  </h2>
                  <form onSubmit={handleSubmit}>
                    <div className="form-group icon-input mb-3">
                    <input
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      type="email"
                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                      placeholder="Your Email Address"
                    />
                      <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                    </div>
                    <div className="col-sm-12 p-0 text-left">
                    <div className="form-group mb-1">
                      
                    <button
                    type="submit"
                    className="col-12 col-md-12 p-0 border-0 text-left"
                  >
                    <div className="form-group mb-1">
                      <div className="form-control text-center w-100 style2-input text-white fw-600 bg-dark border-0 p-0 ">
                        Reset password
                      </div>
                    </div>
                  </button>
                    </div>
                  </div>
                 
                  </form>
                  <div className="font-xsssss">Please contact support@geetsuhane.com or whatsup +1 332 287 8300 if you have any queries.</div>

             
                </div>
              </div>
            </div>
          </div>

      </Fragment>
    );
  }
  export default Forgot;
