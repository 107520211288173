import React, {Fragment,useContext,useEffect,useState } from "react";
import profileService from "../services/profile.service";
import { AuthContext } from "../context/authContext";
import { Link, NavLink } from "react-router-dom";
import dayjs from "dayjs";
import { useNavigate } from 'react-router-dom';




export default function  Notification() {
    const { currentUser } = useContext(AuthContext);
    const [active, setActive] = useState(null);
    const [notiType, setNotitType] = useState(null);
    const [msg, setMsg] = useState('sent you gift on your post');
    const [gifts, setGifts] = useState([]);
    const [ncount, setNcount] = useState([]);
    const giftcdn = 'https://files.geetsuhane.com/gifts/';
    const usercdn = 'https://files.geetsuhane.com/users/';
    const [loading, setLoading] = useState(false);
    const [bgtype,setBgtype] = useState(null);
    const navigate = useNavigate()

    const updateGiftReadStatus = async (gtxnid) => {
        var ncounts = await profileService.getResult({
            qry: `update gsglobal.giftxn gx set gx.status=2 where gx.gtxnid=${gtxnid}) `
        });
    }
    if(currentUser==null)
    {
      localStorage.setItem("redirect", "/notification")
      navigate('/login')
    } 

 

    const refreshCount = async (section) => {
        setLoading(true);
        setActive(section);
        var sp = '';
        if(section=='gifts') {
            sp = 'sp_getGiftsN';
            setMsg('sent you gift on your post');
            setNotitType('feather-gift');
            setBgtype('bg-gold-gradiant');
        } else if(section=='comments') {
            sp = 'sp_getComments';
            setMsg('commented on your post');
            setNotitType('feather-message-square');
            setBgtype('bg-primary-gradiant');
        } else if(section=='likes') {
            sp = 'sp_getLikes';
            setMsg('liked your post');
            setNotitType('feather-heart');
            setBgtype('bg-red-gradiant');
        } else if(section=='share') {
            sp = 'sp_getShared';
            setMsg('shared your post');
            setNotitType('feather-send');
            setBgtype('bg-secondary');
        }
            var giftsdata = await profileService.getResult({
                qry: `CALL ${sp}('${currentUser.id}') `
            });
            console.log(giftsdata[0].length);
            if(giftsdata[0][0]) {
                setGifts(giftsdata[0]);
            } 
        setLoading(false);
    }

    const notification = async () => {  
        if(currentUser) {
         setLoading(true);
          var ncounts = await profileService.getResult({
            qry: `CALL sp_getNotifications('${currentUser.id}') `
        });
        if(ncounts[0][0]) {
            setNcount(ncounts[0][0]);
        //   if(ncounts[0][0].totalN) {
            await refreshCount('likes');
        //   }
        } else {
            setNcount(0);
        }   
         
        setLoading(false);
      } 
    }

    useEffect(() => {
        async function fetchList() {
          await notification();
        }
        fetchList();
      }, []);

        return (

            <Fragment> 
               

                    <div className="main-content theme-dark-bg ">
                    
                    
                        <div className="middle-sidebar-bottom">
                            <div className="middle-sidebar-left">
                                <div className="row">
                                    <div className="col-lg">
                                        <div className=" p-1 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                            <h6 className="fw-700 mb-4 mt-2 font-md text-grey-900 d-flex align-items-center">Notification
                                            <span className="circle-count bg-warning text-white font-xsssss rounded-3 ms-2 ls-3 fw-600 p-2  mt-0">{ncount?.totalN}</span> 

                                            {/* <a  className="ms-auto btn-round-sm bg-greylight rounded-3"><i className="feather-hard-drive font-xss text-grey-500"></i></a> 
                                            <a  className="ms-2 btn-round-sm bg-greylight rounded-3"><i className="feather-alert-circle font-xss text-grey-500"></i></a> 
                                            <a  className="ms-2 btn-round-sm bg-greylight rounded-3"><i className="feather-trash-2 font-xss text-grey-500"></i></a> */}
                                            </h6>


                                            <ul
                                                className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0"
                                                id="pills-tab"
                                                role="tablist"
                                            >
                                                     <li className="list-inline-item me-5">
                                                            <a onClick={() => refreshCount('gifts')} 
                                                            className={`fw-700 font-xssss text-grey-500  pb-1 ls-1 d-inline-block pointer ${active=='gifts'?'text-decoration-underline':''}`}
                                                            data-toggle="tab"
                                                            >
                                                            <span className="circle-count bg-gold-gradiant text-white font-xsssss rounded-3 ms-2 ls-3 fw-600 p-1  mt-0">{ncount?.gifts}</span> 
                                                            Gifts
                                                            </a>
                                                        </li>
                                                        <li className="list-inline-item me-5">
                                                        <a onClick={() => refreshCount('comments')} 
                                                            className={`fw-700 font-xssss text-grey-500  pb-1 ls-1 d-inline-block pointer ${active=='comments'?'text-decoration-underline':''}`}
                                                            data-toggle="tab"
                                                            >
                                                         <span className="circle-count bg-primary text-white font-xsssss rounded-3 ms-2 ls-3 fw-600 p-1  mt-0">{ncount?.comments}</span> 

                                                            Comments
                                                            </a>
                                                        </li>
                                                        <li className="list-inline-item me-5">
                                                        <a onClick={() => refreshCount('likes')} 
                                                            className={`fw-700 font-xssss text-grey-500  pb-1 ls-1 d-inline-block pointer ${active=='likes'?'text-decoration-underline':''}`}
                                                            data-toggle="tab"
                                                            >
                                                     <span className="circle-count bg-red-gradiant text-white font-xsssss rounded-3 ms-2 ls-3 fw-600 p-1  mt-0">{ncount?.likes}</span> 

                                                            Likes
                                                            </a>
                                                        </li>
                                                        <li className="list-inline-item me-5">
                                                        <a onClick={() => refreshCount('share')} 
                                                            className={`fw-700 font-xssss text-grey-500  pb-1 ls-1 d-inline-block pointer ${active=='share'?'text-decoration-underline':''}`}
                                                            data-toggle="tab"
                                                            >
                                             <span className="circle-count bg-secondary text-white font-xsssss rounded-3 ms-2 ls-3 fw-600 p-1  mt-0">{ncount?.sharec}</span> 

                                                            Share
                                                            </a>
                                                        </li>
          
                                             </ul>
                                             <div className="text-gray font-xsssss">{loading?'Loading......':''}</div> 


                                            <ul className="notification-box">
                                                {gifts.length>0?gifts.map((value , index) => (

                                                <li key={index}>
                                                    <Link  to={`/comments?postId=${value?.postid}`} onClick={() => updateGiftReadStatus(value?.gtxnid)}  className={`d-flex align-items-center p-3 pointer rounded-3 ${value?.status==1?'bg-lightblue theme-light-bg':''}`}>
                                                        <img src={`${value?.picture?usercdn+value?.picture:'assets/images/user.png'}`} alt={value?.sender} className="w45 me-3" />
                                                        <i className={`text-white me-2 font-xssss notification-react ${notiType} ${bgtype}`}></i>
                                                        <h6 className="font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-500 lh-20"><strong>{value?.sender}</strong>
                                                         <span className="pl-1"> {msg} : {value?.comments?value.comments.substring(0,30):value.text.substring(0,35)}...        </span>                                                  
                                                        <span className="d-block text-grey-500 font-xssss fw-600 mb-0 mt-0 0l-auto"> {value?dayjs(value.rcvddate).fromNow():''}</span> </h6>
                                                      
                                                    </Link>
                                                    {active=='gifts'?(
                                                    <span className="pl-10x font-xssss"> {value?.qty} X
                                                       <img src={`${value?.gift?giftcdn+value?.gift:''}`} className="w45 me-3" /> 
                                                       </span>
                                                    ):''    }
                                                </li>
                                                
                                                
                                                )):''}

                                            </ul>
                                        </div>
                                        
                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                
                   
            </Fragment>
        );
}
