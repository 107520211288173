import { useCallback, useEffect, useState, useRef } from "react";

const Swiper = ({ onSwipeLeft, onSwipeRight, children }) => {
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  const minSwipeDistance = 50; // Minimum distance required to consider it a swipe

  const onTouchStart = (e) => {
    touchEndX.current = 0; // Reset previous touch end value
    touchStartX.current = e.targetTouches[0].clientX;
  };

  const onTouchMove = (e) => {
    touchEndX.current = e.targetTouches[0].clientX;
  };

  const onTouchEnd = () => {
    if (!touchStartX.current || !touchEndX.current) return;

    const distance = touchStartX.current - touchEndX.current;
    const isRightSwipe = distance < -minSwipeDistance;
    const isLeftSwipe = distance > minSwipeDistance;

    if (isLeftSwipe) {
      onSwipeLeft(); // Trigger left swipe action
    } else if (isRightSwipe) {
      onSwipeRight(); // Trigger right swipe action
    }
  };

  return (
    <div
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      style={{ touchAction: 'none' }} // Prevents default behavior like scrolling
    >
      {children}
    </div>
  );
};

export default Swiper;
