import React, { createContext, useState, useEffect, useContext } from "react";
//import io from "socket.io-client";
import { AuthContext } from "./authContext";
import { createSocket } from '../socket';  // Import the `createSocket` function

// Create Notification Context
export const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext); // Access current user from AuthContext
  const [socket, setSocket] = useState(null);
  const [activity, setActivity] = useState(null);

  // Generate unique UUID
  function generateUUID() {
    let uuid = "";
    for (let i = 0; i < 32; i++) {
      if (i === 8 || i === 12 || i === 16 || i === 20) {
        uuid += "-";
      }
      uuid += Math.floor(Math.random() * 16).toString(16);
    }
    return uuid;
  }

  // Initialize socket connection when currentUser changes
  useEffect(() => {
    if (currentUser) {
      //const newSocket = io(process.env.REACT_APP_API_END_POINT);
      const newSocket = createSocket();
      setSocket(newSocket);

      // Clean up socket connection on component unmount
      return () => newSocket.disconnect();
    }
  }, [currentUser]);

  // Join the group when socket is initialized
  useEffect(() => {
    if (socket && currentUser) {
      socket.emit("joinRoom", {
        name: currentUser?.displayName,
        userId: currentUser?.SID,
        picture: currentUser?.picture,
        room: "Lyrics",
      });
    }
  }, [socket, currentUser]);

  // Listen for incoming messages
  useEffect(() => {
    if (socket) {
      socket.on("groupMessageRec", (data) => {
        setActivity(data);
      });

      // Clean up event listeners on component unmount
      return () => {
        socket.off("groupMessageRec");
      };
    }
  }, [socket]);

  // Function to send a message to the group
  const sendMessageToGroup = (message) => {
    message.custom_id = generateUUID();
    if (socket) {
      socket.emit("sendMessage", {
        name: currentUser?.displayName,
        userId: currentUser?.SID,
        picture: currentUser?.picture,
        room: "Lyrics",
      }
      );
    }
  };

  // Context values
  const contextValues = {
    sendMessageToGroup,
    activity,
  };

  return (
    <NotificationContext.Provider value={contextValues}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
