class ApiService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_END_POINT
  }

  async get(path) {
    try {
      const res = await fetch(`${this.baseUrl}${path}`);
      const data = await res.json();
      return data;
    } catch (err) {
      console.error(err);
      throw new Error("Server Error");
    }
  }

  async post(path, body) {
    try {
      const res = await fetch(`${this.baseUrl}${path}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await res.json();
      return data;
    } catch (err) {
      console.error(err);
      throw new Error("Server Error");
    }
  }

  async put(path, body) {
    try {
      const res = await fetch(`${this.baseUrl}${path}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await res.json();
      return data;
    } catch (err) {
      console.error(err);
      throw new Error("Server Error");
    }
  }

  async delete(path) {
    try {
      const res = await fetch(`${this.baseUrl}${path}`, {
        method: "DELETE",
      });
      const data = await res.json();
      return data;
    } catch (err) {
      console.error(err);
      throw new Error("Server Error");
    }
  }
}

export default ApiService