import React, { Fragment, useState , useEffect,useContext} from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import { useNavigate } from 'react-router-dom';
import profileService from "../services/profile.service";
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import alertService from '../services/sweetAlert';
import dayjs from "dayjs";
// import Flash from "../components/Flash";
import useSound from 'use-sound';
import Swiper from "../components/Swiper";
import toastr from "../services/toastr.service";
import "toastr/build/toastr.min.css";
// import Avatars from "./Avatars";
import Chip from '@mui/material/Chip';
import { NotificationContext } from "../context/notificationsContext";
import ResetCountdown from "../components/ResetCountdown";


    export default function DW() {
        //const { singersList } = useContext(EventContext);
        const navigate = useNavigate()
        const { currentUser } = useContext(AuthContext);
        const {sendMessageToGroup,activity} = useContext(NotificationContext);
        const queryParams = new URLSearchParams(window.location.search)
        const [singersList, setSingersList] = useState([]);
        const [owner, setOwner] = useState(null);
        const [dreamer, setDreamer] = useState(null);
        const [audit, setAudit]=useState([]);
        const [processsing,setProcesssing] =useState(false);
        const [bg, setBg] = useState("home/DreamWorld.jpg")
        const [sponsors, setSponsors] = useState(null)
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [lastTapTime, setLastTapTime] = useState(0);


        var celebrate = queryParams.get("celebrate");

        const cdn = 'https://files.geetsuhane.com/';
        const [playSign] = useSound(cdn+'sound/sign.mp3');
        const [playLose] = useSound(cdn+'sound/lose.mp3');
        const [playCoins] = useSound(cdn+'sound/coins.mp3');
        const [playDream] = useSound(cdn+'sound/dream.mp3');
        const [playShock] = useSound(cdn+'sound/lightning.mp3');

        const [playTerminate] = useSound(cdn+'sound/terminate1.mp3');
        // const term = queryParams.get("uid")?
        const refid = queryParams?.get("uid")!=null?queryParams?.get("uid"): currentUser?.SID;
        //console.log(refid)
        //var nextD=0;
        // if(refid==null)
        //     {
        //       localStorage.setItem("redirect", "/dw")
        //       navigate('/login')
        //     } 
        //    else 
           if(currentUser?.status===2) {
              // console.log(currentUser?.status)
              navigate(
                "/denied"
              )
          }
         
        const sendMessage = async (msg) => {
          sendMessageToGroup ({
            message: msg,
            senderId: currentUser.id,
            name: currentUser.name,
            picture: currentUser.picture,
            receiverId: 2,
            groupName : 'Activity',
            time: new Date(),
            msgType: 'text',
            sender: currentUser
          })

        };

     


        const getSingers = async (action,nextD) => {   
          playDream();  
          if(bg!="home/DreamWorld.jpg") {
            setBg("home/DreamWorld.jpg");
          }
            if (action=='refresh') {
                nextD=0;           
            } else if(action=='prev') {
                nextD=nextD-1;
            } else if(action=='next') {
                nextD=nextD+1;
            } 
                
           
             //console.log('nextD'+nextD);                  
                var grpSingers = await profileService.getResult({
                    qry: `CALL sp_dwSigners('${refid}',${nextD}) `
                });
               
                setSingersList(grpSingers?.[0]);      
                //console.log(userData)           
        }

        const getOwner = async () => {                                    
                var ownerData = await profileService.getResult({
                  qry: ` CALL sp_dwOwner('${refid}')`
                });
               
                setOwner(ownerData?.[0]);      
                //console.log(ownerData)           
        }

        const getSponsors = async () => {                                    
          var sData = await profileService.getResult({
            qry: ` CALL sp_getSponsors('dw')`
          });
        // console.log(sData?.[0][0].sponsor_pics)
          setSponsors(sData?.[0]);          
  }

        

        const getDreamer = async () => {                                    
          var dreamerData = await profileService.getResult({
              qry: ` CALL sp_dwUser('${refid}')`
          });
         
          setDreamer(dreamerData?.[0]);      
          //console.log(dreamerData)           
  }
      const getHistory = async () => {                                    
        var auditData = await profileService.getResult({
          qry: ` CALL sp_dwAudit('${refid}')`
        });
      
        setAudit(auditData?.[0]);      
        //console.log(ownerData)           
    }

        const updateUser =async (action,usid,uname,cost) =>  {
          const currentTime = Date.now();
          if (currentTime - lastTapTime < 1000) {
            return;
          }
          setLastTapTime(currentTime);
          
       
          if(bg!="home/DreamWorld.jpg") {
            setBg("home/DreamWorld.jpg");
          }
            if(action=='sign') {
              setProcesssing(true);
                const confirmed = await alertService.confirm(       
                    'Are you sure you want to sign '+ uname + ' it will cost you '+  cost + ' ?'
                  )
                  if (confirmed) {
                    await sendMessage('Just signed '+uname+' in DreamWorld 🤩');
                    var res = await profileService.getResult({
                      qry: `CALL sp_updateDW1('${action}','${usid}','${currentUser?.SID}', ${cost})`
                  });

                     if(res?.[0]?.[0]?.failed==='failed') {
                      alertService.error(
                        'Not Enough Cash!',
                        `you do not have enough cash to sign: `+uname
                      );
                      playLose();
                      setProcesssing(false);

                        return;
                     } else if(res?.[0]?.[0]?.exists==='exists') {
                        alertService.error(
                          'Not available',
                          `Someone already signed `+uname
                        );
                        setProcesssing(false);
                          return;
                        } else if(res?.[0]?.[0]?.duplicate==='duplicate') {
                          alertService.error(
                            'Duplicate',
                            `You have already signed `+uname
                          )
                          playLose();
                          await getSingers();
                          setProcesssing(false);
                          return;
                       } else if(res?.[0]?.[0]?.max==='max') {
                        alertService.error(
                          'Max limit',
                          `You have already signed max number of members`
                        );
                        playLose();
                        setProcesssing(false);
                          return;
                       } 
                      setProcesssing(false);
                    await getDreamer(refid);
                    await getSingers();
                    await getHistory();
                    navigate('/dw')
                  } 
            } else if(action=='perform') {
            
              
                const confirmed = await alertService.confirm(       
                    'Are you sure you want '+ uname + ' to perform?'
                  )
                  if (confirmed) {
                    await sendMessage('Just performed '+uname+' in DreamWorld 🤩💛');
                    var res = await profileService.getResult({
                        qry: `CALL sp_updateDW1('${action}','${usid}','${currentUser?.SID}', ${cost})`
                    });
                     if(res?.[0]) {
                        //console.log(res);
                        if(res?.[0]?.[0]?.failed==='failed') {
                            alertService.error(
                              'Already performed',
                              `You have already performed `+uname
                            );
                            playLose();
                              return;
                        }
                        const change= res?.[0]?.[0]?.dwpoints;
                        // alertService.info(
                        //     'Performed',
                        //     change+` points updated after you performed `+uname
                        //   );

                        

                          const scoreElement = document.createElement('div');
                          scoreElement.className = 'score-change';
                          scoreElement.innerText = change > 0 ? `+${change}` : `${change}`;
                           scoreElement.img="https://files.geetsuhane.com/gifts/gscash.jpg";
                          const actionElment = document.getElementById(`dwaction`);
                          actionElment.appendChild(scoreElement);

                       
                          setTimeout(() => {
                            actionElment.removeChild(scoreElement);
                          }, 3000); // Remove the flying score after 1 second

                          if(change>0) {  
                            toastr.success(change+` points updated after you performed `+uname );
                            playCoins();  
                                                   
                           setBg("icons/rupees.gif");
                          } else {
                            toastr.info(change+` points updated after you performed `+uname );
                            playLose();
                            if(bg!="home/DreamWorld.jpg") {
                              setBg("home/DreamWorld.jpg");
                            }
                          }
                          await getDreamer(refid);
                          await getHistory();
                        return;
                     } 
                    }
                  } else if(action=='terminate') {
                const confirmed = await alertService.confirm(       
                    'Are you sure you want to terminate '+ uname + ' ? You will receive 90% of origincal cost.'
                  )
                  if (confirmed) {
                    await sendMessage('Just terminated '+uname+' from DreamWorld 💔');
                    var res = await profileService.getResult({
                        qry: `CALL sp_updateDW1('${action}','${usid}','${currentUser?.SID}', ${cost})`
                    });
                    if(res?.[0]?.[0]?.terminated==='terminated') {
                        
                        alertService.info(
                            'Terminated',
                            ` you have successfully terminated  `+uname+ ` and cashbash has been deposited in your account.`
                          );
                          await getDreamer(refid);
                        await getSingers();
                        await getHistory();
                        setBg("icons/shooting.gif");
                        playTerminate();
                        return;
                     } else {
                      playLose();
                      alertService.error(
                        'Not allowed',
                        ` you have already termintaed twice, please wait for next reset.`
                      );
                     } 
                  } 
            } else if(action=='shock') {
              await sendMessage('Just gave shock to '+uname+' in DreamWorld 🫢');
             
                const confirmed = await alertService.confirm(       
                    'Are you sure you want give shock to '+ uname + ' ? your 20% points will be transfered if you have not been performed by signer.'
                  )
                  if (confirmed) {
                    playShock();
                    var res = await profileService.getResult({
                        qry: `CALL sp_updateDW1('${action}','${usid}','${currentUser?.SID}', ${cost})`
                    });

                    if(res?.[0]?.[0]?.res==='already') {
                    alertService.error(
                      'Not allowed',
                      ` you have already shocked `+ uname + ` . save something for yourself too 🤷‍♂️`
                    );
                    playLose();
                   }  else {
                        
                        alertService.info(
                            'Trained',
                            ` you have successfully trained and `+uname+`  got  `+res?.[0]?.[0]?.dwpoints+` points`
                          );
                          setBg("icons/wave.gif");
                          await getDreamer(refid);
                          await getHistory();
                        //await getSingers();
                        return;
                     } 
                  } 
            } 
        }
   
        const SmallAvatar = styled(Avatar)(({ theme }) => ({
            width: 90,
            height: 90,
            border: `2px solid ${theme.palette.background.paper}`,
          }));
      
      useEffect(() => {
          async function fetchSingers() {
          
             
            if(refid) {
              await getDreamer(refid);
                await getSingers('refresh');
                await getOwner();
                await getHistory();
               await getSponsors();
            } 

            if(celebrate) {
              setBg("icons/stars.gif");
           
            } else {
              setBg("home/DreamWorld.jpg")
            }
           
          }
           fetchSingers();
        }, [currentUser]);
       
       
        return (
            <Fragment> 
             
                <div className="main-content">
                    <div className="text-center" >
                   
                        <div style={{backgroundImage:`url(${cdn+bg})`,width:"100%", backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundPosition:"center", height: "800px"}}>
                           
                            <div >     
                                       
                                <div className="pt-7 text-center border-black">
                                <div className="bg-black text-white" >
                                <ResetCountdown> </ResetCountdown>
                                   </div>

                                <div className="border-dashed ">
                                <div className=" bg-white font-xssss"> DW Total <img src="https://files.geetsuhane.com/home/points.png" alt="DW Points" className="w20 rounded-3" /> <span className="font-xssss  strong me-5">  {dreamer?.[0]?.monthly_dw}</span> 
                    DW Cash  <img src="https://files.geetsuhane.com/gifts/gscash.jpg" alt="DW Cash" className="w20 rounded-3 " /> <span className="font-xssss  strong "> {dreamer?.[0]?.rs}</span> 
                   
                      </div>
                      <div className="bg-gold font-xssss text-white"> Ranking:                                                        
                      <Link className="m-3 text-yellow" to="/search?searchBy=dwTop50">Monthly Top 50 </Link>
                      <Link className="text-yellow" to="/search?searchBy=dwTop10">Daily Top 10 </Link>
                      </div>
                           {/* <Flash />      */}
                                </div>
                                    <div className="text-white font-xxl text-center fredoka-font">DreamWorld</div>
                              
                              <div className="text-center p-2">
                          
                              <Chip  color="primary" style={{backgroundColor:'green'}} component="a" href={'sponsor?stype=game&pid=0&sname=dw'} clickable  
                              avatar={<Avatar src={sponsors?.[0]?.picture?.includes('googleusercontent')?sponsors?.[0]?.picture:
                                            cdn + "users/"+
                                            sponsors?.[0]?.picture}>$</Avatar>} label= { 'Sponsor' }  />  
                                  {/* {sponsors?.length>0 &&
                                    <Avatars props={sponsors}  />
                                  } */}
                            
                              </div>
                            
                                     <div>
                                 
                                        
                                    <div> <center>
                                  
                                            
                                            <Badge
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            badgeContent={
                                                <Link to={`/dw?uid=${owner?.[0]?.SID}`}>  
                                                <SmallAvatar   src={
                                                    owner?.[0]?.picture?.includes('googleusercontent')?owner?.[0]?.picture:
                                                    cdn + "users/"+
                                                    owner?.[0]?.picture
                                                  }/></Link>
                                                  
                                            }
                                            >

                                        <Avatar  sx={{ width: 150, height: 150 }}  src={dreamer?.[0]?.picture?.includes('googleusercontent')?dreamer?.[0]?.picture:
                                            cdn + "users/"+
                                            dreamer?.[0]?.picture}></Avatar>
                                    </Badge> 
                                    </center>
                                    </div>

                                    <div className="fw-700  font-xss mt-1  text-red">{dreamer?.[0]?.name}
                                    {/* <img src="https://files.geetsuhane.com/home/points.png" alt="DW Points" className="w20 rounded-3 xsss" />0 */}
                                    </div></div> 
                              
                            
                                  <div className="card-body ">
                     
                                  { singersList?.length>0? ( <div>
                                    {singersList?.map((value , index) => (
                                    <div key={index} className="card-body p-0  text-center">
                                      <Swiper onSwipeLeft={()=>getSingers('prev',value?.nextD)} onSwipeRight={()=>getSingers('next',value?.nextD)}>

                                        { refid==currentUser?.SID &&
                                        <div id="dwaction" className="p-2">
                                          
                                          <Link to={`/search/?searchBy=dw`}>                 
                                            <i  className={`font-xs text-white text-center feather-user-plus counter btn-round-sm bg-secondary me-3`}></i>
                                          </Link>
                                      {/* <a href="/search?searchBy=dw"><i  className={`font-xs text-white text-center feather-user-plus counter btn-round-sm bg-secondary me-3`}></i> </a> */}
                                        <i onClick={()=>updateUser('perform',value?.SID,value?.name,value?.cost)} className={`font-xs text-white text-center feather-mic counter btn-round-sm bg-primary-gradiant me-3`}></i>
                                        <i onClick={()=>updateUser('terminate',value?.SID,value?.name,value?.cost)} className={`font-xs text-white text-center feather-user-x counter btn-round-sm bg-mini-gradiant me-3`}></i>
                                        <i onClick={()=>updateUser('shock',value?.SID,value?.name,value?.cost)} className={`font-xs text-white text-center feather-zap counter btn-round-sm bg-gold-gradiant me-3`}></i>

                                        </div>
                                        }
                                   
                                    {value?.nextD>0  &&
                                     <i
                                                onClick={() => {
                                                getSingers('prev',value?.nextD);
                                                }}
                                                className='font-xs text-gray text-center feather-chevron-left cursor-pointer counter btn-round-sm bg-grey me-3'
                                            ></i> 
                                    }
                                    
                                    
                                    <Badge
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            badgeContent={
                                                <SmallAvatar   src={
                                                    dreamer?.[0]?.picture?.includes('googleusercontent')?dreamer?.[0]?.picture:
                                                    cdn + "users/"+
                                                    dreamer?.[0]?.picture
                                                  }/>
                                                  
                                            }
                                            >

                                        <Link to={`/dw?uid=${value?.SID}`}>  
                                            <Avatar  sx={{ width: 255, height: 255 }}  src={value?.picture?.includes('googleusercontent')?value?.picture:
                                            cdn + "users/"+
                                            value?.picture}></Avatar>
                                        </Link>
                                            </Badge> 
                                         
                                            <i
                                                onClick={() => {
                                                    getSingers('next',value?.nextD);
                                                }}
                                                className='font-xs text-gray text-center feather-chevron-right cursor-pointer counter btn-round-sm bg-grey me-3'
                                            ></i>
                                 
                                                                                             
                                   
                                <div className="text-center text-white">{value?.name}</div>
                                <div className="font-xsssss text-red text-right p-0">Swipe Left or Right</div>

                                    {/* <div> { refid!=currentUser?.SID && value?.SID!=currentUser?.SID && !processsing &&
                                      <i
                                      onClick={() => {
                                          updateUser('sign',value?.SID,value?.name,value?.cost);
                                      }}
                                      className='font-xs text-white text-center feather-user-plus counter btn-round-sm bg-primary-gradiant me-3'
                                  ></i>
                                        }
                                  
                                    </div> */}
                                </Swiper>
                                </div>
                                
                                    ))
                                }</div>):
                                (
                                    <div className="row p-0">
                                    
                                    
                                    <Link to="/search?searchBy=dw">
                                
                                    <center>
                                    <Avatar  sx={{  width: 280, height: 280 }}  src={cdn+"home/sign.jpg"}></Avatar>
                                    </center>
                                    </Link>

                                    <i
                                                onClick={() => {
                                                    getSingers('refresh');
                                                }}
                                                className='text-white feather-refresh-ccw cursor-pointer text-white  btn-round-lg font-lg'
                                            ></i>
                                    </div>
                                )}
                                    </div>
                           
                                </div>
                              
                            </div>
                           
                            
                        </div>
                    </div>
                    <div>
                    { audit?.length>0 && ( <div>
                                    {audit?.map((value , index) => (
                                    <div key={index} className="p-0 m-0 ">
                       {value?.audit &&   
                       <div className="p-2">               
                    <h6 className="font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-500 lh-20">
                    <span className="d-block text-grey-500 font-xssss fw-600 mb-0 mt-0 0l-auto"> {value?dayjs(value?.createDate).fromNow():''}</span>
                    <span className="pl-1"> {value?.audit}</span>                                                  
                  
                  </h6> </div>} </div>))} </div>)}
                    </div>
                </div>

            </Fragment>
        );
}
