import React, { useState, useEffect, useRef, useContext } from 'react';
import { YIN } from 'pitchfinder';
import useSound from 'use-sound';
import profileService from "../services/profile.service";
import { AuthContext } from '../context/authContext';

const sargamFrequencies = { Sa: 261.63, Re: 293.66, Ga: 329.63, Ma: 349.23, Pa: 392.00, Dha: 440.00, Ni: 493.88 };
const tolerance = 5;
const noteSequence = Object.keys(sargamFrequencies);

const VoiceAnalysis = () => {
  const [score, setScore] = useState(0);
  const [currentPitch, setCurrentPitch] = useState(null);
  const [targetNote, setTargetNote] = useState("Sa");
  const [targetPitch, setTargetPitch] = useState(sargamFrequencies["Sa"]);
  const [isAscending, setIsAscending] = useState(true);
  const [currentNote, setCurrentNote] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [totalScore, setTotalScore] = useState(0);
  const [showAnimation, setShowAnimation] = useState(false);
  const [isCameraOn, setIsCameraOn] = useState(false); // New state for camera toggle
  const { currentUser } = useContext(AuthContext);

  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const pitchDetector = YIN({ sampleRate: 44100 });
  const canvasRef = useRef(null);
  const videoRef = useRef(null); // Ref for video element
  const [playMusic, { stop: stopMusic }] = useSound('https://files.geetsuhane.com/sound/csharp1.mp3', {
    volume: 0.2,
    loop: true,
  });

  useEffect(() => {
    if (currentUser?.SID && isAnalyzing) {
      getScore();
      startAudioStream();
      playMusic();
    }
    return () => {
      stopAudioStream();
      stopMusic();
      stopCameraStream(); // Stop the camera when unmounting
    };
  }, [currentUser, isAnalyzing]);

  const toggleAnalysis = () => {
    if (isAnalyzing) {
      updateScore();
      stopMusic();
    } else {
      playMusic();
    }
    setIsAnalyzing((prev) => !prev);
  };

  const toggleCamera = () => {
    if (isCameraOn) {
      stopCameraStream();
    } else {
      startCameraStream();
    }
    setIsCameraOn((prev) => !prev);
  };

  const startAudioStream = async () => {
    if (audioContextRef.current) return;
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioContextRef.current.createMediaStreamSource(stream);
      analyserRef.current = audioContextRef.current.createAnalyser();
      analyserRef.current.fftSize = 2048;
      source.connect(analyserRef.current);
      processAudio();
      visualizeWaveform();
    } catch (err) {
      console.error("Error accessing microphone:", err);
    }
  };

  const startCameraStream = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    } catch (err) {
      console.error("Error accessing camera:", err);
    }
  };

  const stopCameraStream = () => {
    const stream = videoRef.current?.srcObject;
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  const getScore = async () => {
    try {
      const res = await profileService.getResult({
        qry: `CALL sp_DailyTask('','${currentUser?.SID}','fetch','sargam',0)`,
      });
      setTotalScore(res?.[0][0]?.res || 0);
    } catch (error) {
      console.error("Error fetching total score:", error);
    }
  };

  const stopAudioStream = () => {
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }
    setCurrentPitch(null);
    setCurrentNote(null);
    clearCanvas();
  };

  const updateScore = async () => {
    if (score === 0) return;
    const currentScore = score;
    setScore(0);

    try {
      const res = await profileService.getResult({
        qry: `CALL sp_DailyTask('','${currentUser?.SID}','update','sargam',${currentScore})`,
      });
      setTotalScore(res?.[0][0]?.res || 0);
    } catch (error) {
      console.error("Error updating total score:", error);
    }
  };

  const processAudio = () => {
    const bufferLength = analyserRef.current.fftSize;
    const dataArray = new Float32Array(bufferLength);

    const detectPitch = () => {
      analyserRef.current.getFloatTimeDomainData(dataArray);
      const pitch = pitchDetector(dataArray);

      if (pitch && pitch >= 80 && pitch <= 1000) {
        setCurrentPitch(pitch);
        const closestNote = getClosestSargamNote(pitch);
        setCurrentNote(closestNote);
        checkMatchAndAdvance(pitch, closestNote);
      } else {
        setCurrentNote(null);
        setCurrentPitch(null);
      }

      if (isAnalyzing) requestAnimationFrame(detectPitch);
    };

    detectPitch();
  };

  const visualizeWaveform = () => {
    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext("2d");
    const bufferLength = analyserRef.current.fftSize;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      analyserRef.current.getByteTimeDomainData(dataArray);
      canvasCtx.fillStyle = "#fff";
      canvasCtx.fillRect(0, 0, canvas.width, canvas.height);
      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = "#4CAF50";
      canvasCtx.beginPath();

      const sliceWidth = (canvas.width * 1.0) / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * canvas.height) / 2;
        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }
        x += sliceWidth;
      }

      canvasCtx.lineTo(canvas.width, canvas.height / 2);
      canvasCtx.stroke();

      if (isAnalyzing) {
        requestAnimationFrame(draw);
      } else {
        canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
      }
    };

    draw();
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const canvasCtx = canvas.getContext("2d");
    canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const checkMatchAndAdvance = (pitch, closestNote) => {
    const difference = Math.abs(pitch - targetPitch);

    if (closestNote === targetNote && difference <= tolerance) {
      setScore((prevScore) => prevScore + 10);
      setShowAnimation(true); 
      setTimeout(() => setShowAnimation(false), 1500);
      advanceToNextNote();
    }
  };

  const advanceToNextNote = () => {
    const currentIndex = noteSequence.indexOf(targetNote);

    if (isAscending) {
      if (currentIndex < noteSequence.length - 1) {
        const nextNote = noteSequence[currentIndex + 1];
        setTargetNote(nextNote);
        setTargetPitch(sargamFrequencies[nextNote]);
      } else {
        setIsAscending(false);
        const previousNote = noteSequence[currentIndex - 1];
        setTargetNote(previousNote);
        setTargetPitch(sargamFrequencies[previousNote]);
      }
    } else {
      if (currentIndex > 0) {
        const previousNote = noteSequence[currentIndex - 1];
        setTargetNote(previousNote);
        setTargetPitch(sargamFrequencies[previousNote]);
      } else {
        setIsAscending(true);
        const nextNote = noteSequence[currentIndex + 1];
        setTargetNote(nextNote);
        setTargetPitch(sargamFrequencies[nextNote]);
      }
    }
  };

  const getClosestSargamNote = (pitch) => {
    let closestNote = null;
    let minDifference = Infinity;

    for (const [note, frequency] of Object.entries(sargamFrequencies)) {
      const difference = Math.abs(pitch - frequency);
      if (difference < minDifference) {
        minDifference = difference;
        closestNote = note;
      }
    }
    return closestNote;
  };

  return (
    <div style={styles.container}>
     
      <div style={styles.card}>
        <h2 style={styles.title}>Voice Analysis (Sargam)</h2>
        <canvas ref={canvasRef} width="300" height="100" style={styles.canvas} />
        <div style={styles.pitchInfo}>
          <h3>Current Pitch: {currentPitch ? `${currentPitch.toFixed(2)} Hz` : "Detecting..."}</h3>
          <h3>Current Note: {currentNote || "Detecting..."}</h3>
          <h3>Target Note: {targetNote} ({targetPitch} Hz)</h3>
          <h3 className="text-red">Score: {score}</h3>
          <h3 className="text-blue">Total Score: {totalScore}</h3>
        </div>
        <div> {isCameraOn && (
        <video ref={videoRef} style={styles.video} autoPlay playsInline muted />
      )}</div>
        <i onClick={toggleAnalysis} className={`font-xs text-white text-center ${isAnalyzing ?'feather-pause':'feather-mic'} counter btn-round-lg bg-mini-gradiant me-1`}></i>
        <i onClick={toggleCamera} className={`font-xs text-white text-center ${isCameraOn ?'feather-video-off':'feather-video'} counter btn-round-lg bg-primary-gradiant me-1`}></i>
        {/* <button style={styles.button} onClick={toggleAnalysis}>
          {isAnalyzing ? "Stop Analysis" : "Start Analysis"}
        </button>
        
        <button style={styles.button} onClick={toggleCamera}>
          {isCameraOn ? "Turn Off Camera" : "Turn On Camera"}
        </button> */}

        {showAnimation && (
          <div style={styles.floatAnimation}>
            <i className="feather-music"></i>
            <span style={styles.animationText}>{targetNote}</span>
          </div>
        )}
      </div>
      
     
    </div>
  );
};

const styles = {
  // Other styles remain unchanged
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    fontFamily: "Arial, sans-serif",
    background: "linear-gradient(135deg, #ff9a9e, #fad0c4, #a18cd1, #fbc2eb)",
    backgroundSize: "400% 400%",
    animation: "gradientShift 15s ease infinite",
  },
  card: {
    background: "rgba(255, 255, 255, 0.9)",
    borderRadius: "10px",
    padding: "30px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    width: "90%",
    maxWidth: "400px",
  },
  title: {
    fontSize: "1.8em",
    fontWeight: "bold",
    color: "red",
    marginBottom: "20px",
  },
  pitchInfo: {
    marginBottom: "20px",
  },
  canvas: {
    margin: "10px 0",
    backgroundColor: "#f0f0f0",
    borderRadius: "5px",
    width: "100%",
    maxWidth: "300px",
    height: "100px",
  },
  button: {
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "5px",
    padding: "10px 20px",
    fontSize: "1em",
    cursor: "pointer",
    transition: "background-color 0.3s",
    margin: "10px 0",
  },
  video: {
    width: "100%",
    borderRadius: "10px",
    margin: "10px 0",
    filter: "brightness(1.2) contrast(1.1) saturate(1.2)", // Filter to enhance appearance
  },
  floatAnimation: {
    position: "fixed",
    bottom: "5%",
    left: "50%",
    transform: "translate(-50%, 0)",
    fontSize: "1.5em",
    color: "#ff6347",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    animation: "floatUp 2s ease",
    pointerEvents: "none",
  },
  animationText: {
    fontWeight: "bold",
    fontSize: "2em",
  },
};


// Keyframes remain the same as before...

export default VoiceAnalysis;
