import React, {Fragment} from "react";


function Community() {


  return (
    <Fragment>
         <div className="main-content bg-white ">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            <div className="row">
                         <h1>Community Policy </h1>   


    <p>
<strong>Content Standards.</strong> <br/>
    </p>
<p>
<br />These content standards apply to all User Contributions and use of the Interactive Services. User Contributions must in their entirety comply with all applicable federal, state, national, provincial, local, and international laws, and regulations. User Contributions must not:
<br />Depict any activity that is illegal or otherwise violates applicable law.
Contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable.
<br />Promote violence or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age.
<br />Promote or depict pedophilia, child exploitation or abuse, age-play, incest, rape or nonconsensual sex, hypnosis, intoxication, sexual assault, extreme violence, nonconsensual pain, blood, cutting, erotic asphyxiation, torture, necrophilia, sadomasochistic abuse or hardcore bondage, extreme fisting, genital mutilation, bestiality, urine, scatological, or excrement-related material, enema play, vomiting, menstrual bleeding, paraphilia, or any other matter that would be considered obscene under the applicable community standards.
<br />Contain unsolicited sexual content or unsolicited language that sexually objectifies another person in a non-consensual way or contains fake or manipulated sexual content in relation to another person (including “deepfakes”).
<br />Promote, depict, or constitute “revenge porn” (being any sexually explicit material featuring any individual who has not given prior, express, and fully informed consent to that material (a) being taken, capture, or otherwise memorialized; or (b) being posted and shared on the Website.
<br />Promote or depict firearms, weapons, or any goods whose sale, possession, or use is subject to prohibitions or restrictions.
<br />Promote or depict alcohol or drugs or drug paraphernalia.
<br />Infringe any copyright, patent, trademark, trade secret, or other intellectual property rights of any other person.
<br />Contain viruses, worms, Trojan horses, or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software, hardware, mobile device, or telecommunications equipment.
<br />Violate the legal rights (including the rights of publicity and privacy) of any person or contain any material that could give rise to any civil or criminal liability under applicable law or otherwise may conflict with this agreement or the Privacy Policy.
</p>
<p>
<br />Be likely to deceive any person.
<br />Promote or solicit any illegal activity, or advocate, promote, or assist any unlawful act, including promoting or facilitating the prostitution of another person, sex trafficking, or human trafficking.
<br />Cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, alarm, or annoy any other person.
<br />Cause any chat screen to “scroll” faster than others can type to it or any action to a similar disruptive effect.
<br />Disrupt the normal flow of dialogue in chat or otherwise act in a manner that negatively affects other participants.
I<br />mpersonate any person or misrepresent your identity or affiliation with any person or organization.
<br /><br />Involve commercial activities or sales, including unsanctioned contests, sweepstakes, and other sales promotions, barter, or advertising, including soliciting creators for commercial ventures.
<br />Give the impression that they emanate from or are endorsed by us or any other person if that is not the case.
Depict private or personal information of any person without their written consent.
<br />Request personal information from or share personal information with any creator or other user, including financial information, email address, telephone number, or mailing address.
Request money from, or otherwise defraud creators or other users of the Website.
</p>
<p>
<br />Copyright Infringement
<br /><br />We do not allow any content that infringes copyright. The use of copyrighted content of others without proper authorization or legally valid reason may lead to a violation of Geet Suhane 's policies.
<br />At the same time, not all unauthorized uses of copyrighted content constitute an infringement. Exceptions to copyright infringement, such as the fair use doctrine or other applicable laws, allow use of copyrighted work under certain circumstances.
Removal of Content; Suspension or Termination of Account
Any user content that infringes another person’s copyright may be removed. The account may be suspended or terminated for multiple copyright violations in connection with the use of the Geet Suhane site, or other violations of the Terms of Service and Community Guidelines. We reserve the right to refuse any account holder whose account was used for improper activities from opening a new account on Geet Suhane site or otherwise hosted by Geet Suhane.
<br />Copyright Infringement Notification
<br /><br />Contacting the user directly may resolve your complaint more quickly and in a way that is more beneficial to you, the user, and our community. You may also file a Copyright Infringement Report.
All complaints should contain the information requested in our online Copyright Infringement Report form. Failure to include necessary information may limit our ability to investigate your claims and may result in your complaint being denied.
<br />We may provide the account holder with your contact information, including the email address and the name of the copyright owner, and/or details of the complaint.
<br />Before submitting a notification, please be aware that intentionally submitting a misleading or fraudulent report may lead to liability for damages under section 512(f) of the United States Digital Millennium Copyright Act (DMCA) or similar laws as may be applicable in other countries.
<br />Copyright Infringement Counter-Notification
If you receive a copyright infringement notification that you believe to be in error or believe that you are authorized to use the content, you can reach out to the copyright owner directly to request a retraction.
<br />You may also provide us with a counter-notification via our Counter Notification Form. All counter-notification should contain the information requested in the Counter Notification Form. Failure to include necessary information may limit our ability to investigate your claims and may result in your counter-notification being denied.
<br />The counter-notification process will take time to complete, and please be patient. During this time, the copyright claimant may file an action seeking a court order to keep the content down pursuant to the United States Digital Millennium Copyright Act (DMCA) or similar laws in other countries. Please note that we will forward the entire counter-notification to the original reporter, including any contact information you provide, in accordance with our Terms of Services and Privacy Policy. The claimant may use this information to file a lawsuit against you.
<br />If we do not receive notice that the original reporter is seeking a court order to prevent further infringement of the material at issue, we may replace or cease disabling access to the material that was removed if the material does not infringe on third-party copyright. The decision to re-post any material is at Geet Suhane sole discretion.
</p>



      </div>
      </div>
      </div>
      </div>
    </Fragment>
  );
}

export default Community;
