import { createContext, useContext, useEffect, useState } from "react";
import postService from "../services/posts.service";
import profileService from "../services/profile.service";
import axios from "axios";
export const PostsContext = createContext([]);


const PAGE_SIZE = 10;

const PostsProvider = ({ children }) => {
  const [posts, setPosts] = useState([]);
  const [postList, setPostList] = useState([]);
  //const [auditionResult, setAuditionResult] = useState([]);
  const [topList, setTopList] = useState([]);
  // const [topShow, setTopShow] = useState([]);
  const [bday, setBday] = useState([]);
  const [singlePost, setSinglePost] = useState(null);
  const [featurePost, setfeaturePost] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [flash, setFlash] = useState(null);

  const loadMore = async () => {
    if (!hasMore) return;

  var eventres = await profileService.getResult({
      qry: `select value  from gsglobal.config c where c.section='Home' and c.key='Flash' and c.status=1`     
    });
  //console.log(eventres);
    if(eventres.length>0) {
      setFlash(eventres[0].value)
    } else {
      setFlash('Stay Tuned!')
    }
    // const postData = await profileService.getResult({         
    //   //qry :`CALL sp_getPosts(30,0,0,1)`
    //   qry :`CALL sp_getSponserPosts()`
    // })
    const query= `CALL sp_getSponserPosts();`
        
    const postData = await axios.post(process.env.REACT_APP_API_END_POINT+'/profile/getResult', {qry: query }).then(r=>{
        setTopList(r?.data?.[0]);
        //console.log(r)
    }
      );
    
    // setTopList(postData?.[0]);

    // const bdaydata = await profileService.getResult({         
    //   qry :`CALL sp_getPosts(0,0,0,3)`
    // })
    // setBday(bdaydata?.[0]);

    // const bqury= `CALL sp_getPosts(0,0,0,3);`    
    // const bdayData = await axios.post(process.env.REACT_APP_API_END_POINT+'/profile/getResult', {qry: bqury }).then(r=>{
    //   setBday(r?.data?.[0]);
    //     // console.log(r)
    // });

  }

  // const getProfilePosts = async (pcount,start,end,uid) => {
 
  //   const query= `CALL sp_getProfilePosts(${pcount},${start},${end},'${uid}');`
        
  //   const postData = await axios.post(process.env.REACT_APP_API_END_POINT+'/profile/getResult', {qry: query }).then(r=>{
  //     if(start===0)
  //       setPosts(r?.data?.[0])
  //     else 
  //       for(var i=0; i <(r?.data?.[0]).length; i++){     
  //         setPosts(posts => [...posts,r.data?.[0]?.[i]] );
  //       }
  //   }
  //  );
       
  // }

  const getPosts = async (pcount,start,end,cat,searchKey ) => {
    // console.log(searchKey)
    if(searchKey) {
      // const postData = await profileService.getResult({         
      //   qry :`CALL sp_searchPosts(${pcount},${start},${end},${cat},'${searchKey}')`
      // })
      const query= `CALL sp_searchPosts(${pcount},${start},${end},${cat},'${searchKey}');`
        
      const postData = await axios.post(process.env.REACT_APP_API_END_POINT+'/profile/getResult', {qry: query }).then(r=>{
        if(start===0)
          setPostList(r?.data?.[0])
        else 
          for(var i=0; i <r?.data?.[0]?.length; i++){     
            setPostList(postList => [...postList,r.data?.[0][i]] );
          }
      }
     );
        
    } else {
       
        // const postData = await profileService.getResult({         
        //   qry :`CALL sp_getPosts(${pcount},${start},${end},${cat})`
        // })

      const query= `CALL sp_getPosts(${pcount},${start},${end},${cat});`
        
      const postData = await axios.post(process.env.REACT_APP_API_END_POINT+'/profile/getResult', {qry: query }).then(r=>{
        //console.log(postData)
        if(cat===2)         
          for(var i=0; i <r?.data?.[0]?.length; i++){     
            setPostList(postList => [...postList,r.data?.[0][i]] );
          }
        else
          for(var i=0; i <r?.data?.[0]?.length; i++){     
            setTopList(topList => [...topList,r.data?.[0][i]] );
          }
      }
     );

       
    }
   
     
        //setTopList(postData?.[0]);
  }

  function organizeComments(comments) {
    if (!Array.isArray(comments)) {
      // Return an empty array if comments is not an array
      return [];
    }
   
    const commentsMap = new Map();
    const rootComments = [];
  
    for (const comment of comments) {
      
      const { id, parentId } = comment;
     
  
      // if (typeof id !== 'string' || id.trim() === '') {
      //   // Skip the comment if it doesn't have a valid ID
      //   continue;
      // }
      
      // if (parentId !== null) {
      //   // Skip the comment if it has an invalid parentId
      //   continue;
      // }
      
      if (!commentsMap.has(id)) {
        
        // If the comment doesn't exist in the map, create a new comment object
        commentsMap.set(id, { ...comment, childComments: [] });
      }
  
      const currentComment = commentsMap.get(id);
   
      if (parentId === null) {
        // If the comment doesn't have a parentId, add it to the root comments
        rootComments.push(currentComment);
      } else  {
       
        // If the parent comment exists, add the current comment as its child
        commentsMap.get(parentId).childComments.push(currentComment);
      }
    }
  
    return rootComments;
  }
  
 
  

  const fetchSinglePost = async (id) => {
    const post = await postService.getPostById(id);
   // console.log(post)
    setSinglePost(post);
  };
  const saveComment = async ({ postId, userId, content, parentId }) => {
    const post = await postService.commentToPost({
      postId,
      userId,
      content,
      parentId,
    });
    fetchSinglePost(postId)
    // setSinglePost(post);
  };

  useEffect(() => {
    loadMore();

    return () => {
      setPosts([]);
      setPage(0);
      setHasMore(false);
    };
  }, []);

  return (
    <PostsContext.Provider
      value={{
        posts,
        //loadMore,
        //hasMore,
        topList,
        fetchSinglePost,
        singlePost,
        saveComment,
        organizeComments,
        flash,
        postList,
        getPosts,
        // getProfilePosts,
        // featurePost,
        bday
      }}
    >
      {children}
    </PostsContext.Provider>
  );
};

export default PostsProvider;
