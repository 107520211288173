import React, { useState, useEffect, useRef } from 'react';
import socketIOClient from "socket.io-client";
import './VoiceChatRoom.css';

const ENDPOINT = process.env.REACT_APP_API_END_POINT || "http://localhost:3005";

export default function VoiceChatRoom() {
    const [seats, setSeats] = useState(Array(9).fill(null)); // 8 seats + host
    const [occupiedSeatIndex, setOccupiedSeatIndex] = useState(null);
    const [messages, setMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState("");
    const [userId, setUserId] = useState(null); // Unique user ID
    const [users, setUsers] = useState(null); // List of users in the room

    const socket = useRef(null);
    const peerConnections = useRef({});
    const localStream = useRef(null);
    const remoteAudioRefs = useRef({});
    const messagesEndRef = useRef(null);

    useEffect(() => {
        socket.current = socketIOClient(ENDPOINT);

        // Listen for seat updates from other users
        socket.current.on('seat-occupied', ({ index, user }) => {
            setSeats(prevSeats => {
                const updatedSeats = [...prevSeats];
                updatedSeats[index] = { user, isMuted: false, isHost: index === 8 };
                return updatedSeats;
            });
        });

        socket.current.on('seat-freed', (index) => {
            setSeats(prevSeats => {
                const updatedSeats = [...prevSeats];
                updatedSeats[index] = null;
                return updatedSeats;
            });
        });

        socket.current.on('user-joined', (userInfo) => {
            console.log(`${userInfo.name} joined the room`);
            setMessages(prevMessages => [...prevMessages, { user: 'System', text: `${userInfo.name} joined the room` }]);
        });

        socket.current.on('room-users', (usersList) => {
            console.log('Updated users list:', usersList);
            setUsers(usersList);
        });

        socket.current.on('chatMessage', (message) => {
            setMessages(prevMessages => [...prevMessages, message]);
            scrollToBottom();
        });

        socket.current.on('assign-user-id', (id) => {
            setUserId(id);
            console.log(`Assigned user ID: ${id}`);
        });

        // Handle WebRTC signaling
        socket.current.on('offer', (description, fromUserId) => handleOffer(description, fromUserId));
        socket.current.on('answer', (description, fromUserId) => handleAnswer(description, fromUserId));
        socket.current.on('ice-candidate', (candidate, fromUserId) => handleNewICECandidateMsg(candidate, fromUserId));

        // Capture local audio stream
        navigator.mediaDevices.getUserMedia({ audio: true, video: false })
            .then(stream => {
                localStream.current = stream;
                console.log('Local stream acquired:', stream);

                // Optional: Play local audio for debugging
                const localAudio = document.createElement('audio');
                localAudio.srcObject = stream;
                localAudio.autoplay = true;
                localAudio.muted = true; // Prevent feedback loop
                document.body.appendChild(localAudio);
            })
            .catch(error => console.error('Error accessing media devices:', error));

        return () => {
            socket.current.disconnect();
        };
    }, []); // Run once on mount

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleSeatClick = (index) => {
        if (occupiedSeatIndex === null && seats[index] === null) {
            const updatedSeats = [...seats];
            updatedSeats[index] = { user: `User ${userId}`, isMuted: false, isHost: index === 8 };
            setSeats(updatedSeats);
            setOccupiedSeatIndex(index);

            socket.current.emit('seat-occupied', { index, user: `User ${userId}` });

            if (!peerConnections.current[userId]) {
                createPeerConnection(userId);
                createOffer(userId);
            }
        }
    };

    const handleMuteClick = (index) => {
        if (seats[index]) {
            const updatedSeats = [...seats];
            updatedSeats[index].isMuted = !updatedSeats[index].isMuted;
            setSeats(updatedSeats);

            localStream.current.getTracks().forEach(track => {
                const sender = peerConnections.current[userId]?.getSenders().find(s => s.track === track);
                if (sender) {
                    track.enabled = !updatedSeats[index].isMuted;
                    console.log('Track mute status changed:', track.enabled);
                }
            });
        }
    };

    const handleLeaveSeatClick = () => {
        if (occupiedSeatIndex !== null) {
            const updatedSeats = [...seats];
            updatedSeats[occupiedSeatIndex] = null;
            setSeats(updatedSeats);

            socket.current.emit('seat-freed', occupiedSeatIndex);
            setOccupiedSeatIndex(null);

            if (peerConnections.current[userId]) {
                peerConnections.current[userId].close();
                delete peerConnections.current[userId];
            }
        }
    };

    const handleSendMessage = () => {
        if (currentMessage.trim()) {
            const message = { user: `User ${userId}`, text: currentMessage };
            socket.current.emit('sendChatMessage', message);
            setMessages(prevMessages => [...prevMessages, message]);
            setCurrentMessage("");
            scrollToBottom();
        }
    };

    const createPeerConnection = (otherUserId) => {
        if (!peerConnections.current[otherUserId]) {
            const configuration = {
                iceServers: [
                    { urls: 'stun:stun.l.google.com:19302' },
                    {
                        urls: 'turn:your.turn.server:3478', // Replace with your TURN server
                        username: 'your_username',
                        credential: 'your_password'
                    }
                ],
                iceTransportPolicy: 'all' // Ensures both UDP and TCP candidates are considered
            };
            const peerConnection = new RTCPeerConnection(configuration);
            peerConnections.current[otherUserId] = peerConnection;

            // Handle ICE candidates
            peerConnection.onicecandidate = event => {
                if (event.candidate) {
                    socket.current.emit('ice-candidate', event.candidate, otherUserId);
                    console.log('New ICE candidate sent:', event.candidate);
                } else {
                    console.log('ICE candidate gathering complete');
                }
            };

            // Handle incoming tracks
            peerConnection.ontrack = event => {
                if (!remoteAudioRefs.current[otherUserId]) {
                    // Create an audio element for the remote user
                    const remoteAudio = new Audio();
                    remoteAudioRefs.current[otherUserId] = remoteAudio;
                    remoteAudio.autoplay = true;
                    document.body.appendChild(remoteAudio); // Append to DOM for playback
                }
                const remoteAudio = remoteAudioRefs.current[otherUserId];
                remoteAudio.srcObject = event.streams[0];
                remoteAudio.muted = false; // Ensure it's not muted
                console.log('Received remote track and playing audio:', event.streams[0]);
            };

            // Monitor connection state
            peerConnection.onconnectionstatechange = () => {
                console.log(`Connection state for ${otherUserId}:`, peerConnection.connectionState);
                if (peerConnection.connectionState === 'failed' || peerConnection.connectionState === 'disconnected') {
                    // Clean up the connection
                    peerConnection.close();
                    delete peerConnections.current[otherUserId];
                }
            };

            // Add local tracks to the peer connection
            localStream.current.getTracks().forEach(track => {
                peerConnection.addTrack(track, localStream.current);
                console.log('Added local track to peer connection:', track);
            });

            return peerConnection;
        }
    };

    const createOffer = (otherUserId) => {
        const peerConnection = peerConnections.current[otherUserId];
        if (peerConnection) {
            peerConnection.createOffer()
                .then(offer => peerConnection.setLocalDescription(offer))
                .then(() => {
                    socket.current.emit('offer', peerConnection.localDescription, otherUserId);
                    console.log('Offer created and sent:', peerConnection.localDescription);
                })
                .catch(error => console.error('Error creating offer:', error));
        } else {
            console.error('PeerConnection not found for user:', otherUserId);
        }
    };

    const handleOffer = async (description, fromUserId) => {
        try {
            if (!peerConnections.current[fromUserId]) {
                createPeerConnection(fromUserId);
            }

            const peerConnection = peerConnections.current[fromUserId];
            await peerConnection.setRemoteDescription(new RTCSessionDescription(description));
            console.log('Remote description set for offer from:', fromUserId);

            const answer = await peerConnection.createAnswer();
            await peerConnection.setLocalDescription(answer);
            console.log('Answer created and set:', peerConnection.localDescription);

            socket.current.emit('answer', peerConnection.localDescription, fromUserId);
            console.log('Answer sent to:', fromUserId);
        } catch (error) {
            console.error('Error handling offer:', error);
        }
    };

    const handleAnswer = async (description, fromUserId) => {
        try {
            const peerConnection = peerConnections.current[fromUserId];
            if (peerConnection) {
                if (peerConnection.signalingState !== "stable") {
                    await peerConnection.setRemoteDescription(new RTCSessionDescription(description));
                    console.log('Remote description set for answer from:', fromUserId);
                } else {
                    console.warn('Skipping setting remote description because connection is already stable.');
                }
            } else {
                console.error('PeerConnection not found for user:', fromUserId);
            }
        } catch (error) {
            console.error('Error setting remote description for answer:', error);
        }
    };

    const handleNewICECandidateMsg = async (candidate, fromUserId) => {
        try {
            const peerConnection = peerConnections.current[fromUserId];
            if (peerConnection) {
                if (peerConnection.remoteDescription) {
                    await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
                    console.log('ICE candidate added for user:', fromUserId);
                } else {
                    console.error('Remote description is not set, cannot add ICE candidate for user:', fromUserId);
                }
            } else {
                console.error(`PeerConnection not found for user ${fromUserId}`);
            }
        } catch (error) {
            console.error('Error adding received ICE candidate:', error);
        }
    };

    const renderSeatControls = (seat, index) => {
        if (seat) {
            return (
                <div className="controls">
                    <button onClick={() => handleMuteClick(index)}>
                        {seat.isMuted ? 
                            <i className="btn-round-xss font-xss bg-mini-gradiant text-white feather-mic"></i> :
                            <i className="btn-round-xss font-xss bg-mini-gradiant text-white feather-mic-off"></i>
                        }
                    </button>
                    {index === occupiedSeatIndex && (
                        <button className="leave-seat" onClick={handleLeaveSeatClick}>
                            <i className="btn-round-xsx font-xss bg-mini-gradiant text-white feather-user-x"></i>
                        </button>
                    )}
                </div>
            );
        }
        return null;
    };

    return (
        <div className="voice-chat-room">
            <div className="room-header">
                <h2>Party Room</h2>
            </div>
            <div className="top-half">
                <div className="seats">
                    {seats.map((seat, index) => (
                        <div key={index} className={`seat ${seat?.isHost ? 'host' : ''}`}>
                            <div className="avatar" onClick={() => handleSeatClick(index)}>
                                {seat ? (
                                    <>
                                        <img 
                                            src="https://files.geetsuhane.com/party/chair.jpg" 
                                            alt={`Avatar ${index + 1}`} 
                                            className="seat-image"
                                        />
                                        <div className="seat-name">{seat.user}</div>
                                        {renderSeatControls(seat, index)}
                                    </>
                                ) : (
                                    <img src="https://files.geetsuhane.com/party/chair.jpg" alt="Empty Chair" className="empty-chair" />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="bottom-half">
                <div className="chat-box">
                    <div className="messages">
                        {messages.map((msg, index) => (
                            <div key={index} className="message">
                                <strong>{msg.user}:</strong> {msg.text}
                            </div>
                        ))}
                        <div ref={messagesEndRef} /> {/* Empty div to track the end of messages */}
                    </div>
                    <div className="chat-input">
                        <input
                            type="text"
                            value={currentMessage}
                            onChange={(e) => setCurrentMessage(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' ? handleSendMessage() : null}
                            placeholder="Type a message..."
                        />
                        <button onClick={handleSendMessage}>Send</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
