import React, {Fragment,useContext,useEffect,useState,useRef } from "react";
import profileService from "../services/profile.service";
import alertService from '../services/sweetAlert';
import { AuthContext } from "../context/authContext";




export default function  Youtube() {
    const inputRefVideo = useRef(null);
    const { currentUser } = useContext(AuthContext);
    const [loading,setLoading] = useState(false);
//https://youtu.be/BFhp7Y0iLSA?si=465CUnKD1LORt8Ki
//https://www.youtube.com/shorts/
//https://www.instagram.com/p/CrBpprOLRvg/?utm_source=ig_web_copy_link/embed
//https://www.instagram.com/reel/C-RR7tINmW9/?igsh=ODdueGJnN3hiNnRz/embed/
// https://www.instagram.com/reel/C-RR7tINmW9/?igsh=ODdueGJnN3hiNnRz/embed/
// https://www.instagram.com/reel/C-P7mMWoAQ4/?igsh=MTJia3V4eWs3dWhtNw==
//https://www.instagram.com/sagun4u2022/reel/C9wM-TToEOM/?igsh=cGd1bXUxN3RuYmZ4
const cdn = 'https://files.geetsuhane.com/';


    const submitYoutube = async () => {
        var youtubeLink= inputRefVideo.current.value;
        //console.log('youtubeLink'+youtubeLink)

        var videolink ='';
        var mtype = 'Youtube'

        if(youtubeLink.includes('https://www.youtube.com/watch?v=')) {
             videolink = youtubeLink.split('v=')[1];
            var ampersandPosition = videolink.indexOf('&');
            if(ampersandPosition != -1) {
                videolink = videolink.substring(0, ampersandPosition);
            }
        } else if(youtubeLink.includes('https://youtu.be/')) {
            videolink = youtubeLink.split('https://youtu.be/')[1];
            const further = videolink.indexOf('?si=');
            if(further != -1) {
                videolink = videolink.substring(0, further);
            }
        } else if(youtubeLink.includes('youtube.com/shorts/')) {
            videolink = youtubeLink.split('youtube.com/shorts/')[1];
            //console.log('hi')
            const further = videolink.indexOf('?si=');
            if(further != -1) {
                videolink = videolink.substring(0, further);
            }
        
        } else if(youtubeLink.includes('https://www.instagram.com/p')) {
            mtype = 'Insta';
            videolink = youtubeLink.split('?utm_source')[0];
            //console.log('hi')
           
            if(videolink?.length>0) {
                videolink = videolink+'embed/';
            }
        } else if(youtubeLink.includes('https://www.instagram.com/reel')) {
            mtype = 'Insta';
            videolink = youtubeLink.split('?igsh=')[0];
            //console.log('hi')
           
            if(videolink?.length>0) {
                videolink = videolink+'embed/';
            }
        } else if(youtubeLink.includes('https://www.instagram.com/')) {
            mtype = 'Insta';
            videolink = youtubeLink.split('/reel/')[1];
            videolink = videolink.split('?igsh=')[0];
           // console.log(videolink)
           
            if(videolink?.length>0) {
                videolink = 'https://www.instagram.com/reel/'+videolink+'embed/';
            }
        }
       
       
      console.log(videolink);
        //return;
        if(videolink==="") {
        alertService.error(
            'Invalid video URL',
            'Please enter youtube video link'
        ); 
        return;

        } else {
            const confirmed = await alertService.confirm(
				'Youtube video?',
				'Are you sure video is valid? please do not submit link, just video id'
			  ) 
			  if (confirmed) {
                setLoading(true);
                var saveLink =await  profileService.getResult({
                    qry: `CALL sp_insertSocial('${videolink}','${currentUser?.id}', '${mtype}')`
                }).then(result => {
                    //console.log(res)
                if(result?.[0][0]?.res==='exists') {
                    alertService.error(
                        'Duplicate video id',
                        'You have already submitted this video, please try another one'
                    ); 
					return;
			   } else if(result?.[0][0]?.res==='success') {
                    alertService.success(
                        'Congratulations!',
                        'Your video has been successfully submitted!'
                    ); 
                    inputRefVideo.current.value="";
               }
            }

        )}
        setLoading(false);
    }
}

    return (
        <Fragment>
              <div  className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3" >
			{loading &&
              <div className=""><img src={cdn + "icons/upload.gif"}></img></div>
            }
          
			
            <div className="form-group">
            <div className=" text-center text-black font-xsss p-2 strong rounded-xl">Post your Insta/Youtube video</div>
      {/* <div className="font-xssss">Please enter video id only e.g. if url is https://www.youtube.com/watch?v=<span className="strong ">S-O2dD6hz-E</span>&t=4s then <span className="strong text-red">S-O2dD6hz-E</span>  is video id </div> */}
                                                <input  type="text"  className="pt-2 rounded-xl form-control" placeholder="Insta/Youtube share link  " ref={inputRefVideo} />                                                   
                                            </div>        
                                            <div className="row justify-content-center p-2">
                                            <a  onClick={()=>submitYoutube()} className="feather-save  bg-secondary text-center text-white font-xssss fw-600 p-2 w175 rounded-3 d-inline-block">Submit</a>
                                        </div>
        
            
        </div>
        </Fragment>
    )

}