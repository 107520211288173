import React, { Component , Fragment,useContext } from "react";
import { Link } from 'react-router-dom';
import { AuthContext } from "../context/authContext";

export function Events() {  

const { currentUser } = useContext(AuthContext);
        return (
            <Fragment> 
              

                <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="middle-wrap">
                                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                    
                                    <div className="card-body p-lg-5 p-4 w-100 border-0">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h4 className="mb-4 font-xxl fw-700 mont-font mb-lg-5 mb-4 font-md-xs">Events</h4>
                                        
                                                <ul className="list-inline mb-4">
                                                <li className="list-inline-item d-block border-bottom me-0">
                                                        <Link                                                      
                                                        to="/nominate?type=generic"
                                                        className="pt-2 pb-2 d-flex align-items-center">
                                                        <i className="btn-round-md  bg-mini-gradiant text-white feather-mic font-md me-3"></i>
                                                        <h4 className="fw-600 font-xsss mb-0 mt-0">Nominations</h4>
                                                        </Link>
                                                    </li> 
                                                    <li className="list-inline-item d-block border-bottom me-0">
                                 <Link to="https://sites.google.com/view/geetsuhanecom/home" className="pt-2 pb-2 d-flex align-items-center">
                                <i className="btn-round-md bg-mini-gradiant text-white feather-bell font-md me-3"></i> 
                                <h4 className="fw-600 font-xsss mb-0 mt-0">Result</h4>
                                <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li>
                                                    <li className="list-inline-item d-block border-bottom me-0">
                                                        <Link                                                      
                                                        to="/judge?vote=Custom"
                                                        className="pt-2 pb-2 d-flex align-items-center">
                                                        <i className="btn-round-md bg-gold-gradiant text-white feather-user-check font-md me-3"></i>
                                                        <h4 className="fw-600 font-xsss mb-0 mt-0">Vote for Best Co-Captian</h4>
                                                        </Link>
                                                    </li> 
                                                    <li className="list-inline-item d-block border-bottom me-0">
                                                        <Link                                                      
                                                        to="/judge?vote=Custom"
                                                        className="pt-2 pb-2 d-flex align-items-center">
                                                        <i className="btn-round-md bg-secondary text-white feather-user font-md me-3"></i>
                                                        <h4 className="fw-600 font-xsss mb-0 mt-0">Vote for Best Member</h4>
                                                        </Link>
                                                    </li> 
                                                  
                                                    <li className="list-inline-item d-block border-bottom me-0">
                                                        <Link                                                      
                                                        to="/judge?vote=Custom"
                                                        className="pt-2 pb-2 d-flex align-items-center">
                                                        <i className="btn-round-md bg-current text-white feather-user font-md me-3"></i>
                                                        <h4 className="fw-600 font-xsss mb-0 mt-0">Vote for Best Boss</h4>
                                                        </Link>
                                                    </li> 

                                                    <li className="list-inline-item d-block border-bottom me-0">
                                                        <Link                                                      
                                                        to="/judge?vote=Custom"
                                                        className="pt-2 pb-2 d-flex align-items-center">
                                                        <i className="btn-round-md bg-secondary text-white feather-user font-md me-3"></i>
                                                        <h4 className="fw-600 font-xsss mb-0 mt-0">Vote for Best Lead</h4>
                                                        </Link>
                                                    </li> 
                                                  
                                                  

                                                    <li className="list-inline-item d-block border-bottom me-0">
                                                        <Link                                                      
                                                        to="/judge?vote=Custom"
                                                        className="pt-2 pb-2 d-flex align-items-center">
                                                        <i className="btn-round-md bg-primary-gradiant text-white feather-gift font-md me-3"></i>
                                                        <h4 className="fw-600 font-xsss mb-0 mt-0">Vote for Custom Gift Participants</h4>
                                                        </Link>
                                                    </li> 
                                                  
                                                <li className="list-inline-item d-block border-bottom me-0">
                                                        <Link                                                      
                                                        to="/judge?vote=Audition"
                                                        className="pt-2 pb-2 d-flex align-items-center">
                                                        <i className="btn-round-md bg-gold-gradiant text-white feather-mic font-md me-3"></i>
                                                        <h4 className="fw-600 font-xsss mb-0 mt-0">Vote for Auditions</h4>
                                                        </Link>
                                                    </li> 
                                                    <li className="list-inline-item d-block border-bottom me-0">
                                                        <Link                                                      
                                                        to="/judge?vote=Titles"
                                                        className="pt-2 pb-2 d-flex align-items-center">
                                                        <i className="btn-round-md bg-red-gradiant text-white feather-award font-md me-3"></i>
                                                        <h4 className="fw-600 font-xsss mb-0 mt-0">Vote for Titles</h4>
                                                        </Link>
                                                    </li> 
                                                  
                                                    {/* <li className="list-inline-item d-block border-bottom me-0"><Link to="/audition" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-mini-gradiant text-white feather-credit-card font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Nomniate</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li> */}
                                                    <li className="list-inline-item d-block border-bottom me-0"><Link to="/participants" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-secondary text-white feather-users font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Participants</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li>
                                                    {/* {currentUser?.lead==1 ?(
                                                     <li className="list-inline-item d-block border-bottom me-0">
                                                        <Link                                                      
                                                        to="/judge"
                                                        className="pt-2 pb-2 d-flex align-items-center">
                                                        <i className="btn-round-md bg-gold-gradiant text-white feather-command font-md me-3"></i>
                                                        <h4 className="fw-600 font-xsss mb-0 mt-0">Judge</h4>
                                                        </Link>
                                                    </li> ):
                                                    <li>                                                    
                                                    </li>                                                    
                                                    } */}
                                <li className="list-inline-item d-block border-bottom me-0">
                                 <Link to="https://forms.gle/AD9b3WbDmCHFiJaQ7" className="pt-2 pb-2 d-flex align-items-center">
                                <i className="btn-round-md bg-primary-gradiant text-white feather-award font-md me-3"></i> 
                                <h4 className="fw-600 font-xsss mb-0 mt-0">Certificates</h4>
                                <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li>
                                <li className="list-inline-item d-block border-bottom me-0">
                                 <Link to="/winners" className="pt-2 pb-2 d-flex align-items-center">
                                <i className="btn-round-md bg-mini-gradiant text-white feather-bell font-md me-3"></i> 
                                <h4 className="fw-600 font-xsss mb-0 mt-0">Result</h4>
                                <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li>
                                

                                                </ul>
                                             
                                               
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>

              
            </Fragment>
        );
    }


export default Events;