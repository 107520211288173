import React, { Fragment, useContext, useEffect, useState,useCallback } from 'react'
export default function GiphyGif(props) {

    const [gifLinks, setGifLinks] = useState([]);
    const [giflen, setgiflen] = useState([]);

  
    //setGifText(props.text);

    const getGif = () => {
        let text = props.text;
        //console.log(text.length)
        if(text.length>2 && text.length<15) {
            let url = `https://api.giphy.com/v1/gifs/search?api_key=rUu7zkHpaqObC5ZkqnZKtTkxykm4HpjS&q=${text}&limit=3`;
 
            fetch(url)
                .then(response => response.json()).then(gifLinks => {
                    
                    //setGifLinks(gifLinks.data[0].images?.fixed_height.url)
                    setGifLinks(gifLinks)
                    setgiflen(gifLinks?.data?.length)
                    console.log(gifLinks?.data?.length);
                })
        }            
    };

    const clickImage = (gif) => {
        props.onClick(gif);
      };

    useEffect(() => {
         getGif();        
     }, [props])
 
 return (
    <div>
    <div className='d-block cursor'>
        {giflen>0 &&
       <img  style={{width:"107px"}} src={gifLinks?.data?.[0]?.images?.fixed_height?.url} onClick={() => clickImage(gifLinks?.data?.[0]?.images?.fixed_height?.url)} alt="gif" />   
        } { giflen>1 &&
       <img style={{width:"107px"}}  src={gifLinks?.data?.[1]?.images?.fixed_height?.url} onClick={() => clickImage(gifLinks?.data?.[1]?.images?.fixed_height?.url)} alt="gif" />
        }
       {giflen>2 &&
       <img style={{width:"107px"}}  src={gifLinks?.data?.[2]?.images?.fixed_height?.url} onClick={() => clickImage(gifLinks?.data?.[2]?.images?.fixed_height?.url)} alt="gif" />
        }
    </div>
    {giflen>0 && 
    <div className='font-xsssss' text-grey>Powered by GIPHY</div> }
    </div>
 )
}