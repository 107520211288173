import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import AuthContextProvider from './context/authContext'
import NotificationProvider from './context/notificationsContext'
import Layout from './layouts/Layout'
import AppRoutes from './AppRoutes'
import { GoogleOAuthProvider } from '@react-oauth/google'

export default function App () {
  return (
    <BrowserRouter basename={'/'}>
      <GoogleOAuthProvider clientId='947992019752-ms82ss55u33ivnmapu2l3id86isdfm59.apps.googleusercontent.com'>
        <AuthContextProvider>
          <NotificationProvider>
          <Layout>
            <AppRoutes />
          </Layout>
          </NotificationProvider>
        </AuthContextProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  )
}
