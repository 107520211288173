import React, { Component , Fragment, useContext } from "react";
import Auction from "../components/Auction";
import { AuthContext } from "../context/authContext";
import Appfooter from "../components/Appfooter";
// import { useNavigate } from 'react-router-dom';


export default function Game() {
     const { currentUser } = useContext(AuthContext);
    // const navigate = useNavigate();
      
        return (
            <Fragment> 
     
 
            <div className="main-content right-chat-active bg-white">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0" >
                        <div className="row">
                            <div className="col-xl-12 cart-wrapper mb-4">
                                <div className="row">
                                    <div className="col-lg-5 mb-3">
                                    <Appfooter></Appfooter>
 
                                    </div>
                                   
                                </div>
                              
                                <div><Auction></Auction></div>

                          


                            </div>
                        </div>
                    </div>
                </div>
            </div>
     
        </Fragment>
        );
        }

