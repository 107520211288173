import React, { useContext, useState, useEffect, useRef } from 'react';
// import Marquee from "react-fast-marquee";
import { NotificationContext } from '../context/notificationsContext';
import { PostsContext } from '../context/postsContext';
import { Link } from 'react-router-dom';

export default function Flash() {
    const { activity } = useContext(NotificationContext);
    const { flash } = useContext(PostsContext);
    const [flashMessage, setFlashMessage] = useState('');
    const messageQueue = useRef([]); // Use a ref to maintain the queue across renders

    useEffect(() => {
        // Function to handle adding new messages to the queue
        const handleNewMessage = (message) => {
            messageQueue.current.push(message);
        };

        // Add the new message to the queue
        if (activity?.msgType === "text") {
            const newMessage = `${activity?.name}: ${activity?.message}`;
            handleNewMessage(newMessage);
        }

        // Process the queue at a rate of 1 message per second
        const processQueue = () => {
            if (messageQueue.current.length > 0) {
                const nextMessage = messageQueue.current.shift(); // Remove the first message in the queue
                setFlashMessage(nextMessage);

                // Clear the message after 5 seconds
                setTimeout(() => {
                    setFlashMessage('');
                }, 10000);
            }
        };

        const interval = setInterval(processQueue, 1000); // Process one message per second

        // Cleanup interval on unmount
        return () => clearInterval(interval);
    }, [activity]);

    return (
        <div>
           <div className="marquee1-horizontal">
                <span
                    className="d-block font-xssss fw-500 mt-1 lh-3 text-white"
                    dangerouslySetInnerHTML={{
                    __html: flash && flashMessage
                        ? `${flash} | ${flashMessage}`  // Adding content with separator
                        : flash || flashMessage
                    }}
                ></span>
                </div>


        </div>
    );
}
