import React, { Fragment, useContext, useEffect, useState,useCallback, useRef } from 'react'
import { createSocket } from '../socket'; 
import { handleUpload } from "../services/cloudinary.service";
// import ChatUsers from '../components/chat/ChatUsers'
// import Popupchat from '../components/Popupchat'
import AudioRecorder from '../components/chat/audioRecorder'
import AudioPlayer from '../components/chat/audioPlayer'

import { AuthContext } from '../context/authContext'

import alertService from '../services/sweetAlert'
import Load from "../components/Load";
import profileService from "../services/profile.service";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import GiphyGif from '../components/GiphySearch'
//import {useSpring, animated} from "react-spring";


export default function GupShup () {
  const { currentUser } = useContext(AuthContext)
  const [newMessage, setNewMessage] = useState('')
  const [taguser, setTaguser] = useState('');
  const [groupPic, setGroupPic] = useState('');
  const [groupId, setGroupid] = useState(null);
  const [groupName, setGroupName] = useState(null);
  const [load,setLoad] = useState(true);
  const [gif,setGif] = useState(null);
  const navigate = useNavigate();


  const [messages, setMessages] = useState([]);
  const { room } = useParams(); // Get the room name from the URL

  const socket = createSocket(); // Initialize the socket connection
  const messageContainerRef = useRef(null); // Create a ref for the bottom of the chat

   // Handle message submission
   const sendMessage = (msgType,gif) => {
    if (newMessage && room) {
      const msgdata = {
        room,
        message: msgType==='gif'?gif: newMessage,
        userId: currentUser?.SID,
        name: currentUser?.name,
        picture: currentUser?.picture,
        msgType: msgType,
      };
      socket.emit('sendMessage', msgdata);
      setNewMessage('');
      setTaguser('');
    }
  };

  const submitHandler = e => {
    if (newMessage.length) {
      sendMessage('text','');
      setNewMessage('');
      setTaguser('');
    }
  }

  const deleteMessageAction = async id => {
    const confirmed = await alertService.confirm(
      'Delete Message',
      'Are you sure you want to delete this message?'
    )

    if (confirmed) {
      // delete the item
      if (room) {
        socket.emit('deleteMessage', { room, messageId:id });
      }
      alertService.success(
        'Message Deleted',
        'The message has been successfully deleted.'
      )
    }
  }

  const sendGif = (gif) => {
    //console.log(gif);
    sendMessage('gif',gif);
  }

  useEffect(() => {
    if (room && currentUser) {
      console.log(currentUser?.gname)
      if(room!='main') { 
        if(currentUser?.gname!=room) {
          alertService.error('Access Denied','You are not allowed to access this planer');
          navigate('/gupshup/main');
        }
      }
      socket.emit('joinRoom', { room, userId: currentUser?.SID });
    }

    socket.on('message', (newMessage) => {
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    });

    socket.on('loadMessages', (loadedMessages) => {
      console.log(loadedMessages);
      setMessages(loadedMessages);
    });

    socket.on('messageDeleted', (messageId) => {
      setMessages((prevMessages) => prevMessages.filter(msg => msg.id !== messageId));
    });


    return () => {
      socket.off('message');
      socket.off('loadMessages');
      socket.off('messageDeleted');
    };
  }, [room,currentUser]);
  

  useEffect(() => {
      messageContainerRef.current.scrollTo({
        top:
          messageContainerRef.current.scrollHeight -
          messageContainerRef.current.clientHeight,
        behavior: "smooth",
      });

    // Scroll to bottom of messages container when messages update
  
  }, [messages, messageContainerRef, currentUser]);

  return (
    <Fragment>
      
      <div  className='main-content right-chat-active modal-fullscreen'>
    
        <div className='middle-sidebar-bottom'>
          <div
            className='middle-sidebar-left pe-0'
            style={{ maxWidth: '100%' }}>
           <div className='font-xss text-blue text-center strong'><i className='feather-message-circle'></i> {groupName} <i className='feather-message-circle'></i> </div>
            <div className='row'>
              <div className='col-lg-12 position-relative'>
              
                <div ref={messageContainerRef}
                  className='chat-wrapper pt-0 w-100 position-relative scroll-bar bg-white theme-dark-bg'>
                  
                  <div  className='chat-body p-3 '>
                    <div className='messages-content pb-3'> 
                    
                      {messages.length
                        ? messages.map(
                            (
                              {
                                id,
                                message,
                                userId,
                                picture,
                                name,   
                                createdAt = new Date(),
                                msgType,
                                SID =userId
                              },
                              index
                            ) => {
                              return (
                                <div
                                  key={`${index}${id}`}
                                  className={`message-item ${
                                    userId === currentUser?.SID
                                      ? 'outgoing-message'
                                      : ''
                                  }`}
                                 
                                >
                                  <div className='message-user pointer' >
                                    <figure className='avatar'>
                                      {picture ? (
                                        <img
                                          className='rounded-img-sm mx-3'
                                          src={
                                            picture.includes('googleusercontent')?picture:
                                            'https://files.geetsuhane.com/users/' +
                                            picture
                                          }
                                          alt=''
                                          onClick={()=>setTaguser(taguser+('@'+name))}
                                        />
                                      ) : (
                                        <div className='align-items-center bg-grey d-flex justify-content-center rounded-img-sm mx-3'>
                                          <span className='ti-user font-sm'></span>
                                        </div>
                                      )}
                                    </figure>
                                    <div className='mt-0' verticalalign='Top'>
                                      <div className='d-flex gap-2' >
                                      <a className='pointer'  href={`/userpage?uid=${SID}`}>
                                        <span className='font-xsssss'>{name}</span>
                                        </a>
                                      </div>
                                      <div className='time'>
                                        {new Date(
                                          createdAt
                                        ).toLocaleTimeString()}
                                        ,{new Date(createdAt).toDateString()}
                                      </div>
                                      
                                    </div>
                                    
                                  </div>
                                  <div className='message-wrap justify-content-between d-flex gap-2 pl-10x align-items-center mt-0 pt-0 font-xssss'>
                                        {msgType === 'audio' ? (
                                          <AudioPlayer url={message} />
                                        ) : (
                                         msgType==='gif'? <img src={message}></img>:
                                         <span className="d-block font-xssss fw-500 mt-1 lh-3 text-blue-500" dangerouslySetInnerHTML={{ __html: (message?.replace('http','').replace('www','')) }}></span>

                                        )}
                                        {userId === currentUser?.SID && id && (
                                          <span
                                            onClick={() => {
                                              deleteMessageAction(id)
                                            }}
                                            className='ti-trash cursor-pointer'
                                          ></span>
                                        )}
                                      </div>
                                </div>
                              )
                            }
                          )
                        : <div>
                        <h2 className='text-center'>Welcome {currentUser?.name} </h2>
                        <div className='align-items-top'>
                          {groupPic? (
                          <img                                          
                          src={
                            'https://files.geetsuhane.com/'+groupPic
                              }
                            />) :
                            (
                              <img                                          
                              src={                                                                        
                                    currentUser?.picture?.includes('googleusercontent')?currentUser?.picture:
                                    'https://files.geetsuhane.com/users/' +
                                    currentUser?.picture
                                  }
                                  className='w-350 h-350'
                                />
                            )
                          }
                        
                        </div>
                          {
                            load? (
                              <Load/>
                             ):''
                          }
                         
                          {/* <img className="rounded-3 w-350 h-350" src= {"https://files.geetsuhane.com/promo/support1.gif"}></img> */}
                        </div>
                        }

                      <div className='clearfix'></div>
                    </div>
                  </div>  
                </div>
                <div
                  className='chat-bottom dark-bg p-2 shadow-none theme-dark-bg'
                  style={{ width: '98%' }}
                >
                    <span className='font-xsssss pr-5x float-right'>You are in {groupName}</span>
                    <i
            onClick={() => {
              navigate(-1);
            }}
            className='feather-chevron-left cursor-pointer text-blue  btn-round-sm font-lg'
          ></i>  {  newMessage.length>2 && newMessage.length<15 &&
           <GiphyGif text={newMessage}  onClick={sendGif}></GiphyGif> }
               <form
                    className='chat-form'
                    onSubmit={e => {
                      e.preventDefault(); submitHandler()              
                    }}
                  >
                   
                    <div className='form-group'>
                     
                      <textarea
                     value= {taguser?(!newMessage.includes(taguser)?taguser+' '+ newMessage:newMessage):newMessage}
                      rows={3}
                      cols ={5}
                      maxLength="250"
                      autoFocus
                      placeholder ='Start typing..'
                      onChange={e => {
                        setNewMessage(e.target.value)
                      }}
                      className='chat-textarea w-100 bg-grey font-xsss'/>
                    </div>
                    
                    <button type='submit' className={`bg-current`}>
                      <i className='ti-arrow-right text-white'></i>
                    </button>
                    
                  </form>
                </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
