import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { routes } from './routes';
import { AuthContext } from './context/authContext';
import Login from './pages/Login';
import Maintenance from './pages/Maintenance';

export default function AppRoutes() {
  const { currentUser, loading, isLoggedIn } = useContext(AuthContext);
  const isUnderMaintenance = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

  if (loading) {
    return (
      <div className='w-100 d-flex justify-content-center'>
        <img
          src="https://files.geetsuhane.com/logo/gs_cupid.GIF"
          alt='icon'
          className='ms-2 w-25 mb-1 me-5'
        />
      </div>
    );
  }

  if (isUnderMaintenance) {
    return <Maintenance />;
  }

  return (
    <Routes>
      {routes.map((item) => (
      <Route
      key={item.path}
      exact
      path={item.path}
      element={item.isProtected && !isLoggedIn ? <Login /> : item.element}
    />
    
      ))}
    </Routes>
  );
}
