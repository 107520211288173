// socket.js
import { io } from 'socket.io-client';

// You can configure the URL and options here if needed
export const createSocket = () => {
  return io(process.env.REACT_APP_API_END_POINT, {
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
  });
};
