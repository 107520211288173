import React, { Fragment, useContext, useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import alertService from "../services/sweetAlert";
import Compose from "../components/chat/Compose";


export default function Composition() {
    const queryParams = new URLSearchParams(window.location.search)
    let linkType = window.location;

    return (
        <Fragment>
 <Compose></Compose>

            </Fragment>
    )
};