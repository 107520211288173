import React, { useContext, useState } from 'react'
import DefaultLayout from '../layouts/DefaultLayout'
import AuthLayout from '../layouts/AuthLayout'
import { useLocation } from 'react-router-dom'
import { AuthContext } from '../context/authContext'
import { routes } from '../routes'

export default function Layout ({ children }) {
  const { loading, currentUser: user } = useContext(AuthContext)
  const location = useLocation()
  const Layouts = [DefaultLayout, AuthLayout]
  const [Layout, setLayout] = useState(0)
  const CurrentLayout = Layouts[Layout]

  React.useEffect(() => {
    const foundRoute = routes.find(item => item.path === location.pathname)
    setLayout(
      foundRoute?.layout === 'auth' || loading ? 1 : !foundRoute ? 0 : 0
    )
  }, [location, loading, user])
  return <CurrentLayout>{children}</CurrentLayout>
}
