import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./authContext";
import eventService from "../services/event.service";

export const EventContext = createContext([]);


const EventProvider = ({ children }) => {

  const [singersList, setSingersList] = useState([]);
  const { currentUser } = useContext(AuthContext);
 


  const loadEvent = async () => {
    const singers = await eventService.certificates(
      {uid :currentUser?.id,
        eventid: '3rd Audition'
      });
    {
      //console.log(singers)
      if(singers) {
        setSingersList(singers.posts);
      }
    }
    
  };


  useEffect(() => {
    loadEvent();


  return () => {
    setSingersList([])
  }
  }, []);

  return (
    <EventContext.Provider value={{ singersList }}>
      {children}
    </EventContext.Provider>
  );
};

export default EventProvider;
