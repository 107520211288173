import React, { Component , Fragment, useContext } from "react";


export default function Maintenance() {
    

    
        return (
            <Fragment> 
     
 
            <div className="main-content right-chat-active bg-white">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0" >
                        <div className="row">
                            <div className="col-xl-12 cart-wrapper mb-4">
                                <div className="row">
                                    <div className="col-lg-5 mb-3">
                                      
                                    </div>
                                   
                                </div>
                             
                                <div>
                            <div>
                            <img src="https://files.geetsuhane.com/home/maintenance.jpg"></img>
                    </div>
                          <div className="text-red strong font-xs">We'll Be Right Back!</div>    
                          <div className="text-black  font-xss">geetsuhane.com is currently undergoing scheduled maintenance to bring you an even better experience. We're working hard to improve our site and will be back online shortly.
                          </div>   
                          <div className="text-black  font-xsss">What’s happening?</div>        
                          <div className="text-black  font-xsss">
                            <ul>
                                <li>
                                Enhancing our website features
                                </li>
                                <li>
                                Improving site speed and security
                                </li>
                                <li>
                                Adding new and exciting content
                                </li>
                               
                            </ul>
                            </div>        
                    <div className="text-red  font-xsss">Estimated downtime: We expect to be back up and running within a few hours. We appreciate your patience and understanding.</div>

       <div className="text-blue  font-xssss">In the meantime, if you have any urgent queries, feel free to reach out to us at support@geetsuhane.com. </div>
       <div className="text-black  font-xssss">Thank you for your support!</div>
 
       

                                </div>

                          


                            </div>
                        </div>
                    </div>
                </div>
            </div>
     
        </Fragment>
        );
        }

