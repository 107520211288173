import React, { Component , Fragment, useState , useContext, useEffect} from "react";
import profileService from "../services/profile.service";
import { AuthContext } from "../context/authContext";
import { useNavigate } from 'react-router-dom';
import alertService from '../services/sweetAlert'
import { event } from "jquery";
import { Link } from "react-router-dom";

//import { EventContext } from "../context/eventContext";
    export default function Nominate() {
       
        //const [rating, setRating] = useState("");

        const navigate = useNavigate()
        const { currentUser } = useContext(AuthContext);
        const [events, setEvents] = useState([]);

        const queryParams = new URLSearchParams(window.location.search)
        var term = queryParams.get("type") 

        term = term?term.replace('--','').replace('//','').replace('*',''):'';       

        if(currentUser==null)
        {
          localStorage.setItem("redirect", "/nominate?type="+term)
          navigate('/login')
        } 
    //console.log(term);
    const nominateSubmit = async(event) => {
      const confirmed = await alertService.confirm(
        'Self Nominations',
        'Are you sure you want to nominate yourself for ' + event + '?'
      )
  
      if (confirmed) {
        var res = await profileService.getResult({
          qry: `CALL sp_updateNominate('${currentUser.id}','${event}')`     
        });
        console.log(res)
        //  if(members.length===0) {
        //   var res = await profileService.getResult({
        //     qry: `insert into gsglobal.nominations(uid,event,seq) values('${currentUser.id}','${event}',1)`     
        //     });
        //     console.log(res);

        //      const result = await profileService.auditData({
        //      userid: currentUser.id, activity: 'nominations'
        //      });

        if(res?.[0][0]?.result=='success') {
          alertService.success(
            'Self Nominations',
            'Thank you for nominating yourself for '+ event
          );
        } else if(res?.[0][0]?.result=='already') {
              alertService.info(
                'Self Nominations',
                'You have alreaedy nominated yourself for '+ event
              )
          } else if(res?.[0][0]?.result=='month') {
            alertService.info(
              'Self Nominations',
              'You have alreaedy nominated yourself this month for '+ event
            )
        } else if(res?.[0][0]?.result=='winner') {
          alertService.info(
            'Self Nominations',
            'You were winner last month so please wait for next month!'
          )
        } else if(res?.[0][0]?.result=='max') {
          alertService.info(
            'Self Nominations',
            'Sorry, already many members have nominated so please wait for the next month!'
          )
      }
      }
    }
    

        const saveNomination = async (id,event) => {            
          
            
          };

          const getList = async () => {    
                 
            //console.log(term)
            var eventres = await profileService.getResult({
                qry: `select c.value as event, c.status,c.key as category from gsglobal.config c where c.section='Nominations' order by c.status desc, c.value`     
              });
            //console.log(eventres);
            
            setEvents(eventres);
            //console.log(grpSingers);
      
          }
     
     
          useEffect(() => {
            async function fetchList() {
              await getList();
            }
            fetchList();
          }, []);
           
        return (
            <Fragment> 
             
                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            <div className="row">
                                <div className="col-xl-12">
                                   <h2><strong>Nominations</strong></h2>

                                    <div className="row ps-2 pe-2">

                                        {events.length>0?events.map((value , index) => (
                                        <div key={index} className="col-md-8 col-sm-4 pe-2 ps-2">
                                            <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                                                <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                                                    {/* <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65 z-index-1"><img src={value.imgpath?'https://files.geetsuhane.com/users/'+value.imgpath:`assets/images/gs.png`} alt="avater" className="float-right p-0 bg-white rounded-circle w-100 shadow-xss" /></figure> */}
                                                    <div className="clearfix w-100"></div>
                                                    <h4 className="fw-700 font-xsss mt-3 mb-0">{value.event} </h4>
                                                    <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">{value.status==='0'?'Closed':value.category}</p>
                                                   
                                                  
                                                    <br/>
                                                    {value.status==='1'?(
                                                    <Link to="#" onClick={() => nominateSubmit(value.event)}
                                                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white">Nominate</Link>
                                                    ):""}
                                                    </div>
                                                  
                                            </div>
                                           
                                        </div> 
                                        
                                        )):""}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        );
}
