import React, {
  Fragment,
  useContext,
  useState,
  useEffect
} from "react";

import alertService from '../services/sweetAlert';
import { AuthContext } from "../context/authContext";
import Appfooter from "../components/Appfooter";


export default function Start() {
  const { currentUser } = useContext(AuthContext);
  

  const cdn = 'https://files.geetsuhane.com/';
  const ssd = process.env.REACT_APP_API_END_POINT+'/gsfiles/';


  const [more, setMore]=useState(true);
//console.log(flash);
  const promo='https://files.geetsuhane.com/promo/promo.mp4'

  const egift =async (typ) => {
    if(typ==='appreciate') {
      const info=  alertService.info(
        "Limited Time Offer: Top Appreciator will be rewarded  one custom gift such as tshirt, mug etc. every week!"
      )
      } else {
          const info=  alertService.info(
            "Limited Time offer: Top GSP Star will be rewarded INR 500 e-gift card every week!"
          )
      }
  }
useEffect(() => {

}, []);


  //console.log(auditionResult)

  return (  
    <Fragment>
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row feed-body">
              <div className="col-xl-8 col-xxl-9 col-lg-8">
              <Appfooter first="home"></Appfooter>
              {currentUser? (
              
              <h6 className="d-flex justify-content-center p-1">Welcome {currentUser.name} 🤗🤗</h6>
          )
          :""}
    
    <div className="card d-block border-0 shadow-000 rounded-xxxl bg-gradiant-bottom overflow-hidden pt-1">
                          <img className="rounded-3 w-100" src= {cdn+"/home/task.gif"}></img>
                        </div>   
         <div className="text-center w-100 p-1" onClick={()=>egift('appreciate')}><img style={{width:"100%"}} src={`${cdn}home/egift-app.gif`}></img></div>
   
       <div className="p-1">

       <a href="/home"><img style={{width:"100%"}} src={`${cdn}home/egift-gsp.gif`}></img></a>
       </div>
          
                  <div className="card d-block border-0 shadow-000 rounded-xxxl overflow-hidden cursor-pointer mb-3 mt-0 me-3">
                    
                    <div className="card d-block border-1 shadow-000 d-flex rounded-3 2">
                
                    
                         
                       
                   

                        <div    className="card d-block border-0  rounded-xxxl pt-1">
                         
                
             <div className="card d-block border-0 shadow-000 rounded-xxxl bg-gradiant-bottom overflow-hidden pt-1">
                <video src={promo+"#t=0.001"} autoPlay={false} muted loop playsInline type="video/mp4" className="rounded-3 w-100" controls></video>
                
              </div> 
                    
       
                 
                </div>
               
                     
          </div>
          <div className="text-center w-100 ">                                                   
                          <a  href="/gupshup" className={`bg-primary pointer mt-1 btn pt-2 pb-2 ps-3 pe-3 ms-1 ls-3 rounded-xl feather-message-square font-xsssss fw-700 ls-lg text-white`}>GupShup</a>
                          <a href="/gupshup?groupChat=group" className="bg-secondary pointer feather-globe mt-1 btn pt-2 pb-2 ps-3 pe-3 ms-1 ls-3 rounded-xl  font-xsssss fw-700 ls-lg text-white">Planet</a>

<a href="/chatlist" className="feather-mail bg-red-gradiant pointer mt-1 btn pt-2 pb-2 ps-3 pe-3 ms-1 ls-3 rounded-xl  font-xsssss fw-700 ls-lg text-white ">Inbox</a>

                            </div>
       
                </div>
         
             
               
             
              </div>
            
      <center>   
      
    
          </center>
          </div> 
            </div>
          </div>
        </div> 
    

    </Fragment>
  );
}
