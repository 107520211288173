import React, { Fragment, useState , useEffect} from "react";
import Pagetitle from '../components/Pagetitle';
//import { EventContext } from "../context/eventContext";
import profileService from "../services/profile.service";


    export default function Certificate() {
        //const { singersList } = useContext(EventContext);
        const [singersList, setSingersList] = useState([]);
        const [dummy, setDummy] = useState([]);

        const getSingers = async () => {     
            var grpSingers = await profileService.getResult({
                qry: `select distinct u.name as uname, u.picture as imgpath  
                from gsglobal.users u  join gsglobal.ratings r on u.id=r.singerid where  MONTH(r.createdAt)=MONTH(CURRENT_DATE) and r.event like '%Audition'
                 order by u.name`
            });
            setSingersList(grpSingers);
            setDummy(grpSingers);
            
            //console.log(grpSingers);
        }
      useEffect(() => {
          async function fetchSingers() {
            await getSingers();
          }
          fetchSingers();
        }, []);
        const searchChange = p => {          
            if(p.length>0) {
                setSingersList(
                    singersList.filter(member => {
                      return member.uname.toLowerCase().includes(p.toLowerCase())
                    })
                  )
            } else {
                //console.log(dummyList);
                setSingersList(dummy);
            }
           
          }
       
        return (
            <Fragment> 
             
                <div className="main-content">
                    <div >
                        <div >
                            <div className="row">
                                <div >
                                    <Pagetitle header="Certificates" onChange={searchChange}/>
                                    <div >

                                        {singersList.map((value , index) => (
                                        <div key={index} id={index}>
                                              <div className="cert-container">      
                                              <div className="cert-person"> {value.uname}</div> 
                                             
                                        </div>
                                        </div>
                                        
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        );
}
