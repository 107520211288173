import React, { useRef } from 'react'

const AudioPlayer = ({ url }) => {
  const audioRef = useRef(null)

  const handlePlay = () => {
    audioRef.current.play()
  }

  const handlePause = () => {
    audioRef.current.pause()
  }

  return (
    <audio src={url} ref={audioRef} controls={true}>
      <source src={url} type='audio/mpeg' />
      Your browser does not support the audio tag.
    </audio>
  )
}

export default AudioPlayer
