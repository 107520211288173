import React,{Component, useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

export default function Pagetitle(props)
 {
    //console.log(props);
    const navigate = useNavigate()

        const title = props.title;
        const header= props.header;
        const text= props.text;
        const searchFilter = event => {
            const option = event.target.value;
            props.onChange(option);
          };
        return (
            <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">

                <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">{header}
                <form action="#" className="pt-0 pb-0 ms-auto">
                    <div className="search-form-2 ms-2">
                        <i className="ti-search font-xss"></i>
                        <input type="text" defaultValue={text} onChange={searchFilter} className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0" placeholder="Search here." />
                    </div>
                </form>
                <div   className="btn-round-md ms-2 bg-greylight theme-dark-bg rounded-3">                   
                 <i onClick={() => { navigate(-1); }} className='feather-chevron-left cursor-pointer text-blue   btn-round-sm font-lg' ></i>
</div>
                </h2>
                <div>
                <span className='font-xss'>{title}</span>
                <span className='font-xsssss text-red'>{props?.mini?'      ('+props.mini+')':''}</span>
                </div>
                
                
            </div>            
        );
    }





