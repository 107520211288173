import React, {Fragment,useContext,useEffect,useState } from "react";
import profileService from "../../services/profile.service";
import { AuthContext } from "../../context/authContext";
import { Link, NavLink } from "react-router-dom";

import { useNavigate } from 'react-router-dom';
import AudioPlayer from '../../components/chat/audioPlayer';
import alertService from '../../services/sweetAlert';



export default function  Compose() {

    const cdn = 'https://files.geetsuhane.com/';
    const [loading, setLoading] = useState(false);
    const [chat,setChat] = useState([]);   
    const { currentUser, sendMessageToGroup,activity } = useContext(AuthContext)
    const [title, setTitle] = useState('')
    const [line, setLine] = useState('')
    const [content, setContent] = useState('')



    const navigate = useNavigate()
 
    const getLyrics = async (gtxnid) => {
        var lyricsData = await profileService.getResult({
            qry: `CALL sp_getLyrics();`
        });
        setChat(lyricsData?lyricsData[0]:'');
        // console.log(lyricsData[0])
    }

    const lockLine = async (id) => {
      var lock = await profileService.getResult({
          qry: `CALL sp_lockLine('${id}');`
      });
     await  getLyrics();
      // console.log(lyricsData[0])
  }


    const getTitle = async (gtxnid) => {
      var titleDetails = await profileService.getResult({
          qry: `CALL sp_getComposeDetails();`
      });
      // console.log(titleDetails[0][0]?.title)
      setTitle(titleDetails?titleDetails[0][0]?.title:'');
      setLine(titleDetails?titleDetails[0][0]?.currentline:'');
      //console.log(chatData[0])
  }

    // console.log(activity)

    const deleteMessageAction = async id => {
      const confirmed = await alertService.confirm(
        'Delete Message',
        'Are you sure you want to delete this line?'
      )
      if (confirmed) {
        // delete the item
        setChat((prevChat) => prevChat.filter((msg) => msg.id !== id));
        var deleteLine = await profileService.getResult({
          qry: `CALL sp_deleteLine(${id}, '${currentUser?.id}');`
      });
        alertService.success(
          'Line Deleted',
          'Your line has been successfully deleted.'
        )
      }
    }

    const SubmitHandler = async (categoty,msg) => {
      if(currentUser==null)
      {
      localStorage.setItem("redirect", "/");
      navigate('/login');
      return;
      };
      sendMessageToGroup ({
        message: msg,
        senderId: currentUser.id,
        name: currentUser.name,
        picture: currentUser.picture,
        receiverId: 22,
        groupName : 'Lyrics',
        time: new Date(),
        msgType: categoty,
        sender: currentUser
      });
      //await getLyrics();
        setContent('');
    }
 

      const sendGif = p => {
        SubmitHandler('gif',p);
      }

      const reactToPost = async (id,sid) => {
   
        if(sid===currentUser?.SID || sid==="008") {
          alertService.info(
            'Prohibited',
            'Your can not like your own or reserved line.'
          )
          return;
        } 
        if (currentUser?.id) {
        
          var updateLikes =  profileService.getResult({
            qry: `CALL sp_updateClaps('${currentUser?.id}', ${id})`
          })
          await getLyrics();
          return;
        }
        navigate("/login");
      };
     
    useEffect(() => {
        async function fetchList() {          
              if(!loading) {
                //console.log(loading);
                await getLyrics();
                await getTitle();
             }              
              setLoading(true);           
        }
        fetchList();
        
         //getAuditdata();
      }, []);

      useEffect(() => {     
        // console.log(activity);
        getLyrics();       
      }, []);

        return (
            <Fragment>
            {/* {windowWidth > 1000 && <ChatUsers title='Participants' />} */}
            <div className='main-content right-chat-active'>           
           
            {currentUser? (
                <h6 className="d-flex justify-content-center p-3">Welcome {currentUser.name} 🤗🤗</h6>               
            )
            :""}
                           <div className='messages-content pb-3'> 
                           <div className='h-50 scroll-bar bg-white theme-dark-bg'> 
                           <h6 className="d-flex justify-content-center text-blue strong">Lets Compose a New Song!</h6>
                           <span className="font-xsss d-flex justify-content-center text-red mt-1 strong">Title - {title}</span>
                           <span className="font-xsssss d-flex justify-content-center text-green mt-1 strong">If your line is selected, you will be rewarded Rs 100 in your GS account everytime!</span>  

                            {chat.length 
                              ? chat.map(
                                  (
                                    {
                                      id,
                                      message,
                                      senderid,
                                      picture,
                                      name,
                                      createdAt = new Date(),
                                      msgType,
                                      gname,
                                      SID,
                                      claps,
                                      status,
                                      rs
                                    },
                                    index
                                  ) => {
                                    return (
                                      <div  key={id}   >
                                 <div  className="card-body p-0 m-0 d-flex position-relative">
                                {/* <a  href={`/userpage?uid=${SID}`}> */}
                                    <figure className="avatar me-0">
                                    {picture ? (
                                        <img
                                          className='rounded-img-sm mx-1'
                                          src={
                                            picture.includes('googleusercontent')?picture:
                                            cdn + "users/"+
                                            picture
                                          }
                                          alt=''
                                         
                                        />
                                      ) : (
                                        <div className='align-items-center bg-grey d-flex justify-content-center rounded-img-sm mx-3'>
                                          <span className='ti-user font-sm'></span>
                                        </div>
                                      )}
                                    </figure> 
                                    {/* </a> */}
                                     {/* <a  href={`/userpage?uid=${SID}`}> */}
                                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                                    {" "}
                                    {name}
                                    Line# 
                                    <span  className="text-red strong">{status>1?status-1:line}</span>
                                    {status>1 && 
                                    <i                                      
                                        className={`feather-lock text-white bg-red-gradiant me-2 btn-round-xs font-xsssss `}
                                      ></i>  
                                    }
                                   
                                    <div className='message-wrap justify-content-between text-blue align-items-center mt-0 pt-0 font-xsssss'>
                                  
                                         <span className="d-block font-xssss fw-500 mt-1 lh-3 text-blue-500" dangerouslySetInnerHTML={{ __html: (message?.replace('http','')?.replace('www','')) }}></span>
                                      
                                      
                                      </div>
                                    
                                      <div className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2">
                                   
                                    <i
                                        onClick={() => {
                                          reactToPost(id, SID);
                                        }}
                                        className={`feather-thumbs-up text-white bg-red-gradiant me-2 btn-round-xs font-xsssss `}
                                      ></i>  
                                      {claps!=0?claps:""} <span style={{marginLeft:"10px"}}></span>
                                  
                                      {currentUser?.SID===SID &&(
                                      <span
                                            onClick={() => {
                                              deleteMessageAction(id)
                                            }}
                                            className='ti-trash cursor-pointer'
                                          ></span>)}
                <img src="https://files.geetsuhane.com/gifts/gscash.jpg" alt="GSC" className="w35 rounded-3 " />  <span className="d-none-xsss"> {rs}</span>
                       {currentUser?.SID==='008' &&
                         <i
                                        onClick={() => {
                                          lockLine(id);
                                        }}
                                        className={`feather-lock text-white bg-red-gradiant me-2 btn-round-xs font-xsssss `}
                                      ></i>  
                                      }
                                     </div>                             
                                    </h4> 
                                    {/* </a> */}
                                    
                                      </div>      
                                                               
                                      </div>
                                    )
                                  }
                                )
                              : <div>
                                            
                              </div>

                              }
              
       <div  className='chat-form'>  
              <div className='form-group'> 
                      <input 
                        value={content}
                        onChange={e => {
                          setContent(e.target.value)
                        }}
                        type='text'
                        className='w-100 bg-grey'
                        placeholder={'Start typing your line...'}
                        autoFocus 
                      />
             
                    </div>
            <button type='submit' onClick={()=>SubmitHandler('text', content)} className={`bg-current`}>
                      <i className='ti-arrow-right text-white w100'></i>
                    </button>
                    
            
          </div>
              
                          </div> 
                         
                 
                          </div> 
                        
                  </div>
        
            
            {/* <Popupchat /> */}
          </Fragment>
        );
}
