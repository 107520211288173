import React, { Component , Fragment, useState , useContext, useEffect} from "react";

import profileService from "../services/profile.service";
import { Link, NavLink } from "react-router-dom";
const cdn = 'https://files.geetsuhane.com/';


    export default function Winners() {
        const [singers, setSingers] = useState([]);
        const [category, setcategory] = useState();
        const [cc, setCC] = useState([]);
        const [event, setEvent] = useState("");
        const usercdn = 'https://files.geetsuhane.com/users/';

        const getSingers = async () => {     
           // console.log('call')
            var eventname =""; 
            var eventres = await profileService.getResult({
                qry: `select value from gsglobal.config c where c.section='Result' and c.key='Event' and status=1`             
              });
            console.log(eventres[0].value);
              if(eventres) {
                eventname = eventres?.[0]?.value
                setEvent(eventres?.[0]?.value)
              } else {
                setEvent('Coming Soon!')
              }
              /*
               eventres = await profileService.getResult({
                qry: `select value from gsglobal.config c where c.section='Audition' and c.key='Result' and status=1`             
              });
            //console.log(eventres[0].value);
              if(eventres) {
                //eventname = eventres[0].value
                setcategory(eventres[0]?.value)
              } else {
                setEvent('Coming Soon!')
              }

            // var grpSingers = await profileService.getResult({
            //   qry: "select * from gsglobal.result r where event='"+eventname+"' and MONTH(r.createdAt)= MONTH(CURRENT_TIMESTAMP)  and status=1 order by stars desc LIMIT 3"
            // });
            // setStars(grpSingers);

            var grpSingers = await profileService.getResult({
                qry: "select distinct r.uname,u.picture,r.avgscore,r.votes,r.points,u.id as uid,u.SID from gsglobal.result r join nominations n on r.nid=n.id join users u on n.uid=u.id  where r.event='"+eventres[0].value+"' and MONTH(r.createdAt)= MONTH(CURRENT_TIMESTAMP) and status=1 and category='Winner' order by r.votes desc LIMIT 50"
              });

              */
              var grpSingers = await profileService.getResult({
                qry: "select distinct r.name as uname,r.picture,r.score, r.uid,u.SID from gsglobal.halloffame r join users u on r.uid=u.id where r.title='"+eventname+" Winner' order by r.score desc LIMIT 50"
              });

              setSingers(grpSingers);
              //console.log(grpSingers)

              // var grpSingers = await profileService.getResult({
              //   qry: "select * from gsglobal.result r where event='"+eventname+"' and  MONTH(r.createdAt)= MONTH(CURRENT_TIMESTAMP) and status=1 and category='CC' order by avgscore desc LIMIT 15"
              // });
              // setCC(grpSingers);
            
            //console.log(grpSingers);
      
          }
          useEffect(() => {
              async function fetchSingers() {
                await getSingers();
              }
              fetchSingers();
            }, []);
       
       
        return (
            <Fragment> 
             
             <div className="main-content theme-dark-bg ">
                    
                    
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="row">
                                <div className="col-lg">
                                    <div className=" p-0 w-100 position-relative  bg-white theme-dark-bg">
                                    <h6 className="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">{event}
                                            <span className="circle-count bg-warning text-white font-xsssss rounded-3 ms-2 ls-3 fw-600 p-2  mt-0">{category}  Result</span>                               
                                    </h6>                                                          
                                    <div className="notification-box mt-0 p-0">
                                    
                                      {singers.length>0?singers.map((value , index) => (

                                                <div key={index}>
                                                    <a  href={`/userpage?uid=${value?value.SID:''}`}>
                                                      <figure className="avatar me-1">
                                                      {value?.picture ? (
                                                          <img
                                                          className='rounded-img-sm mx-1'
                                                          src={
                                                              value?.picture.includes('googleusercontent')?value.picture:value.picture.includes('groups/')?cdn+value.picture:
                                                              cdn + "users/"+
                                                              value.picture
                                                          }
                                                          alt=''
                                                          
                                                          />
                                                          
                                                      ) : (
                                                          <div className='align-items-center bg-grey d-flex justify-content-center rounded-img-sm mx-1'>
                                                          <span className='ti-user font-sm'></span>
                                                          </div>
                                                      )}
                                                       <span className='font-xsssss strong text-black'>{value?.uname}</span>
                                                      </figure></a> 
                                                   
                                                </div>                                                                                                
                                                )):''}
                                               
                                      
                                               </div>
                                        </div>
                                        
                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
            

            </Fragment>
        );
}
