import React, {Fragment,useContext,useEffect,useState } from "react";
import profileService from "../services/profile.service";
import { AuthContext } from "../context/authContext";
import { Link } from "react-router-dom";
import alertService from '../services/sweetAlert';



export default function  Approve() {

    const cdn = 'https://files.geetsuhane.com/';
    const [loading, setLoading] = useState(false);
    const [members,setMembers] = useState([]);   
    const { currentUser } = useContext(AuthContext)
 

 
    const getMembers = async () => {
        var membersList = await profileService.getResult({
            qry: `CALL sp_addToGroup('fetch','${currentUser?.id}','',0);`
        });
        setMembers(membersList?membersList[0]:'');
        // console.log(lyricsData[0])
    }


    const updateAction = async(action,sid, name) => {
      const confirmed = await alertService.confirm(
        'Confirm',
        'Are you sure you want to '+ action + ' ' +name+ '?'
      )
      if (confirmed) {      
        var updt = await profileService.getResult({
            qry: `CALL sp_addToGroup('${action}','${currentUser?.id}','${sid}',0);`
        });
        
        if (action === 'approve') {
            alertService.success(
                'Successful',
                'Your have successfully approved ' +name+ '.'
              )
              
            }
            await getMembers();
        }

       
    }
    useEffect(() => {
        async function fetchList() {          
              if(!loading) {              
                await getMembers();               
             }              
              setLoading(true);           
        }
        fetchList();
        
         //getAuditdata();
      }, [currentUser]);

        return (
            <Fragment>
             <div className="main-content right-chat-active">
                    
                    <div className="middle-sidebar-bottom">
                  
                        <div className="middle-sidebar-left pe-0">
                           <div className="d-flex justify-content-center text-blue strong p-1 bg-mini-gradiant text-white">Approval Queue</div>

                            {members.length 
                              ? members.map(
                                  (
                                    {
                                      SID,
                                      picture,
                                      name                                    
                                    }                                
                                ) => {
                                    return (
                                      <div  key={SID}   >
                                 <div  className="card-body p-0 m-0 d-flex position-relative">
                                {/* <a  href={`/userpage?uid=${SID}`}> */}
                                 <Link  to={`/userpage?uid=${SID}`}>
                                    <figure className="avatar me-0 align-items-center">
                                    {picture ? (
                                        <img
                                          className='rounded-img-md mx-1'
                                          src={
                                            picture.includes('googleusercontent')?picture:
                                            cdn + "users/"+
                                            picture
                                          }
                                          alt=''
                                         
                                        />
                                      ) : (
                                        <div className='align-items-center bg-grey d-flex justify-content-center rounded-img-md mx-1'>
                                          <span className='ti-user font-md'></span>
                                        </div>
                                      )}
                                    </figure> 
                                    </Link>  
                                     {/* <a  href={`/userpage?uid=${SID}`}> */}
                                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                                    <Link  to={`/userpage?uid=${SID}`}>
                                    {name}      
                                     </Link>             
                                      <div className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2 pt-2">
                                   
                                    <i
                                     onClick={() => {
                                        updateAction('approve',SID,name)
                                      }}
                                       
                                        className={`feather-thumbs-up text-white bg-green-gradient me-2 btn-round-xs font-xsssss `}
                                      ></i>                                                                       
                         
                         {currentUser?.lead>0 &&
                         <i
                         onClick={() => {
                            updateAction('reject',SID,name)
                          }}
                                       
                                        className={`feather-thumbs-down text-white bg-red-gradiant me-2 btn-round-xs font-xsssss `}
                                      ></i>  
                                      }
                                     </div>                             
                                    </h4> 
                                    {/* </a> */}
                                    
                                      </div>      
                                                               
                                      </div>
                                    )
                                  }
                                )
                              : <div>
                                            
                              </div>

                              }                            
                          </div> 
                         
                 
                          </div> 
                        
                  </div>
          </Fragment>
        );
}
