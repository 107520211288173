import { useState, useRef,useContext,useEffect, Fragment } from "react";
import { AuthContext } from "../context/authContext";
import profileService from "../services/profile.service";
import alertService from '../services/sweetAlert';
import axios from "axios";
import postService from "../services/posts.service";
import { Link, useNavigate } from "react-router-dom";
import Ratings from "../components/Ratings";

const mimeType = "audio/mpeg";
const cdn = 'https://files.geetsuhane.com/';


const FreeStyle = () => {

	const { currentUser } = useContext(AuthContext);
	const navigate = useNavigate();

	// const vocals = 'https://files.geetsuhane.com/karaoke/vocals.mp3';
	const [permission, setPermission] = useState(false);

	const mediaRecorder = useRef(null);

	const [recordingStatus, setRecordingStatus] = useState("inactive");

	const [stream, setStream] = useState(null);

	const [audio, setAudio] = useState(null);
	const [audioBlob, setAudioBlob] = useState(null);
	
	const [audioChunks, setAudioChunks] = useState([]);
	const [lyricsLines, setLyricsLines] = useState([]);
	const [seconds, setSeconds] = useState(0);
	const [stars, setStars] = useState([]);
	const context = new AudioContext();
	const source = context.createBufferSource();
	const inputRefName = useRef(null);


	useEffect(() => {
	
	  const interval = setInterval(() => {
		setSeconds(seconds + 1);
	  }, 1000);
	  return () => {
		// console.log("component unMount");
		clearInterval(interval);
	  };
	});
	

	var counter =0;

	



	const saveText = async (text, posturl) => {      

		try {
			var insertSong =  profileService.getResult({
				qry: `CALL sp_insertPosts('${text}','${posturl}','song','${currentUser?.id}','')`
			})
		
			console.log(insertSong)
			
			alertService.info(
				'Congratulations!',
				'Freestyle uploaded successfully!'
			); 
			// navigate("/");
		} catch(err) {
			console.log(err);
			alertService.error(
				'Error!',
				'Oops, some issue with database, please try later'
			); 
		}
	}

	var fetchLyrics = async (refresh) => {
		if(refresh) {
			const confirmed = await alertService.confirm(
				'Change Lyrics?',
				'Are you sure you want to try some other lyrics?'
			  ) 
			  if (!confirmed) {
					return;
			   } else {
				if(source)
				try {
					source.pause();
			} catch {}
				
			   }
		}
        var LyricsData =  profileService.getResult({
            qry: `select l.lyrics from gsglobal.lyrics l where l.statusid=1 order by RAND()  limit 1; `
        }).then(LyricsData => {	
			setLyricsLines(LyricsData?.[0]);
			//console.log(LyricsData?.[0]);
			}
		);
	
     };
	


	const startRecording = async () => {
		
		try {
			if(currentUser==null)
			{
			  localStorage.setItem("redirect", "/recording")
			  navigate('/login');
			} 
			
			if ("MediaRecorder" in window) {
				try {
					
					const mediaStream = await navigator.mediaDevices.getUserMedia({
						audio: true,
						video: false,
					});
					setPermission(true);
					setStream(mediaStream);
					setRecordingStatus("recording");
					const media = new MediaRecorder(mediaStream, { type: mimeType });
					mediaRecorder.current = media;

				
					mediaRecorder.current.start();
					//bgmusic.play();
					let localAudioChunks = [];
					
					//audioRef.current.play();
					mediaRecorder.current.ondataavailable = (event) => {
						if (typeof event.data === "undefined") return;
						if (event.data.size === 0) return;
						localAudioChunks.push(event.data);
					};
				setAudioChunks(localAudioChunks);
				} catch (err) {
					console.log(err)
				}
			} else {
				alert("The MediaRecorder API is not supported in your browser.");
			}
			
		} catch (err) {
			console.log(err.message);
		}
		
		
	};
	 const handleUpload = async () => {
		const formData = new FormData();
		const filePath = currentUser.id+'/songs/'+Date.now()+'/test.mp3';
		const fileDir = currentUser.id+'/posts/'+Date.now();

		console.log(filePath)
		
		formData.append('filePath', filePath);
		formData.append('fileDir', fileDir);

		formData.append("filetype", 'song');

		const audiofile = new File([audioBlob], {
			type: audioBlob.mimeType,
		  });
		  formData.append("file", audiofile);

		try {
		const baseUrl = process.env.REACT_APP_API_END_POINT+"/profile/uploadfile";
		//const baseUrl = "http://localhost:3005/profile/uploadfile";
		const confirmed = await alertService.confirm(
			  'Upload Freestyle',
			  'Do you want to post freestyle now?'
			); //enctype': 'multipart/form-data'
			if(confirmed) {

				alertService.alertInput(
				  'Freestyle'
				).then(text => {
					if(text) {
					
					 axios.post(baseUrl, formData, { headers: {'Content-Type': 'multipart/form-data'}})
					 .then((res) => {
						 //console.log(res);
						 if (res.status === 201)
						   saveText(text,filePath);
							 return;
					 })
					} else {
						return;
					}
				 }
				)

				
				.catch((error) => {
				  alertService.info(
					'Upload Freestyle',
					'OOps, some issues while uploading, please try after some time!'
				  ); 
				
				})
			  
			
			return;
			}
			
	
		} catch (error) {
		  console.error(error);
		}
	  };


	const formatTime = (secs) => {
		let hours = Math.floor(secs / 3600);
		let minutes = Math.floor(secs / 60) % 60;
		let seconds = secs % 60;
		return [hours, minutes, seconds]
		  .map((v) => ("" + v).padStart(2, "0"))
		  .filter((v, i) => v !== "00" || i > 0)
		  .join(":");
	  };

   const preview = async (audioUrl) => {
	
		const audioBuffer = await fetch(audioUrl)
				  .then(res => res.arrayBuffer())
				  .then(ArrayBuffer => context.decodeAudioData(ArrayBuffer));
				source.buffer = audioBuffer;
				source.connect(context.destination);

				source.start();


				source.addEventListener("ended", function(){
					source.currentTime = 0;
					//bgmusic.stop();
					//console.log("ended");
			   });

   }


   const delay = async (ms) => new Promise(
	resolve => setTimeout(resolve, ms)
  );


  const reset =async () => {
	const confirmed = await alertService.confirm(
		'Reset',
		'Are you sure you want to reset?'
	  )
  
	  if (confirmed) {
		setAudio(null);
	  }
}

	const stopRecording =async () => {
		

	
		mediaRecorder.current.pause();
		const confirmed = await alertService.confirm(
			'Preview?',
			'Are you sure you want to stop recording for preview?'
		  ) 
		  if (confirmed) { 
			try {
				
				mediaRecorder.current.stop();
				//audioRef.current.pause();
	
			mediaRecorder.current.onstop =async () => {
				const audioBlob = new Blob(audioChunks, { type: mediaRecorder.mimeType });
				const audioUrl = URL.createObjectURL(audioBlob);
				setAudio(audioUrl);
				setAudioChunks([]);
				setAudioBlob(audioBlob);
			
				await delay(1000)
				preview(audioUrl)
			
			};
			
			} catch(err) {
				console.log(err)
	
			}
			
		  } else {
		
				mediaRecorder.current.resume();
				setRecordingStatus("recording");
				return;
		  }
		
		setRecordingStatus("inactive");		
		
	};

	const topStars = async() => {
		var topFSstars =  profileService.getResult({
			qry: `select h.picture,left(h.name,12) as name,h.score as worth,u.SID,h.group as gname,h.title 
			from halloffame h join users u on h.uid=u.id 
			where h.title in ('GSFS Top Star','GSFS Top Comments','GSFS Top Likes') limit 3;`
		}).then (res => {
		//console.log(res)
			setStars(res)
		});
	}

	useEffect(() => {
        async function getLyrics() {
         await fetchLyrics();    
		//  await topStars();      
        }
        getLyrics();
      }, []);

	return (
		<div className="main-content right-chat-active bg-white">
		<div className="middle-sidebar-bottom">
			<div className="middle-sidebar-left pe-0" >
				<div className="row">
	
			 <div className="bg-primary-gradiant text-center text-white font-xssss p-1"> GS Freestyle Challenge <Ratings uid="gfc" type="game" size="small"></Ratings></div>         
          {/* <div className="text-center font-xsssss">Top GFC Stars may win weekly/monthly e-gifts</div> */}
		
		<div >
		
			<div style={{height:"525px"}}>
          <div   style={{backgroundImage:`url("https://files.geetsuhane.com/karaoke/images/vinyl.gif")`,width:"100%", backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundPosition:"center", height: "525px"}}>
		
            <marquee className="card-body strong font-xs text-red" direction="up">
		 
			<div >
				{lyricsLines?.lyrics?.split("\n").map((i,key) => {
					return <div className="p-1" key={key}>{i}</div>;
				})}
			</div>
          
             </marquee>
			
            </div>

                
             
			
		
              </div>
			
								
									
			</div>
			<div  >
			<div className="p-1">

<center>
{audio ? (
<div className="audio-player">
<i onClick={()=>preview(audio)} className="font-xsss text-white text-center feather-repeat btn-round-lg bg-secondary me-1  counter"></i>

<i onClick={handleUpload}  className={`font-xsss text-white text-center feather-upload-cloud counter btn-round-lg bg-primary-gradiant me-1`}></i>

<i onClick={reset} className="font-xsss text-white text-center feather-rotate-ccw btn-round-lg bg-mini-gradiant me-1 counter"></i>

</div>
) : (
<div>

<i  onClick={recordingStatus==='inactive'?startRecording:stopRecording} className={`font-xs text-white text-center ${recordingStatus === 'inactive'?'feather-mic':'feather-pause'} counter btn-round-lg bg-mini-gradiant me-1`}></i>
<i onClick={()=>fetchLyrics('refresh')} className="font-xsss pointer text-white text-center feather-refresh-cw btn-round-lg bg-secondary me-1"></i>

		{recordingStatus==='recording' && 
<span className="text-black">

{formatTime(seconds)}

<span className="blink-n text-red p-2" >
		<i className="feather-circle    bg-red-gradiant"></i>
		</span>

</span>
}


</div>
)}
</center>	
</div>
				<hr/>
            <center>
         
        { stars &&
   
 
            <div  className="card-body p-1 m-0 d-flex position-relative ">
            {stars?.map((value , index) => (
                
                                        <div  style={{maxWidth:'170px'}}  key={index} className="col-sm-5 pe-2 ps-1 ">

           
			<a  href={`/userpage?uid=${value?.SID}`}>
            <figure className="avatar me-1">
            {value?.picture ? (
                <img
                className='rounded-img-sm mx-1'
                src={
                    value?.picture.includes('googleusercontent')?value.picture:value.picture.includes('groups/')?cdn+value.picture:
                    cdn + "users/"+
                    value.picture
                }
                alt=''
                
                />
            ) : (
                <div className='align-items-center bg-grey d-flex justify-content-center rounded-img-sm mx-1'>
                <span className='ti-user font-sm'></span>
                </div>
            )}
            
            </figure></a> 
            
            <h4  className="font-xsssss"><span className="strong ">{value?.name}</span> 
            <div> <Ratings uid={value?.SID} size="small"></Ratings></div>   
            <div  className='message-wrap justify-content-center text-red align-items-center mt-0 pt-0 '>    
			{value?.title==='GSFS Top Star'? (
				<img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Gold" className="w10 rounded-3 " />
			): (
				<i className={`${value?.title==='GSFS Top Likes'?'feather-heart':'feather-message-circle'} text-white bg-red-gradiant  me-1 font-xsssss`}></i>    

			)}
			{value?.worth}                
            </div>            
            <div className='message-wrap justify-content-center text-orange align-items-center mt-0 pt-0 font-xsssssss'>            
           {value?.title}
          
        </div>
       
            <span  className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
            
                <Link to={`/search?groupDetail=${value?.gname}`} className="bg-secondary pointer p-1 font-xssss   text-white">   
                <i className="feather-globe btn-round-xssss bg-primary-gradiant me-1"></i>{value?value?.gname:''}  </Link>
            
            
            </span>
            </h4>
            
            </div>
           ))} 
          
            </div>
 
        }

        </center>
   
        </div>
		
		</div> </div></div></div>
	);
};

export default FreeStyle;
