import React, { useState, useRef, useContext } from 'react'
import { SocketContext } from '../../context/chatContext'

const VoiceRecorder = () => {
  const { setAudioBlob } = useContext(SocketContext)

  const [recording, setRecording] = useState(false)
  const mediaRecorderRef = useRef(null)

  const handleStartRecording = () => {
    setRecording(true)

    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      mediaRecorderRef.current = new MediaRecorder(stream)

      mediaRecorderRef.current.addEventListener('dataavailable', event => {
        const audioBlob = event.data
        setAudioBlob(audioBlob)
      })

      mediaRecorderRef.current.start()
    })
  }

  const handleStopRecording = () => {
    setRecording(false)

    mediaRecorderRef.current.stop()
  }

  return (
    <button
      type='button'
      className='bg-grey float-left'
      onClick={recording ? handleStopRecording : handleStartRecording}
    >
      <i className={recording ? 'ti-control-pause' : 'ti-microphone'}></i>
    </button>
  )
}

export default VoiceRecorder
