import React, {
  Fragment,
  useContext,
  useState,
  useCallback,  
  lazy,
  Suspense,
  useEffect
} from "react";

//import Postview from "../components/Postview";
import Load from "../components/Load";
 import { PostsContext } from "../context/postsContext";
import Gifts from "../components/Gifts";
import Drawer from 'react-bottom-drawer';
// import InfiniteScroll from "react-infinite-scroll-component";
import Pagetitle from "../components/Pagetitle";
import Appfooter from "../components/Appfooter";
import profileService from "../services/profile.service";
import { AuthContext } from "../context/authContext";
import { useNavigate } from 'react-router-dom';
import Sponsor from "./Sponsor";
import { Link } from "react-router-dom";


const Postview = lazy(() => import("../components/Postview"));



export default function Moments({sponsor}) {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [searchKey, setSearchKey] = useState(); 
   const [giftItem, setgiftItem] = useState(0); 
  // const myRef = useRef();
  const [gift, setGift] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  //const [ge, setGe] = useState(false);
  const openDrawer = useCallback(() => setIsVisible(true), [])
  const {  topList,getPosts,bday } = useContext(PostsContext);

  // console.log(topList)

  const closeDrawer = useCallback(() => {
    setIsVisible(false);
   // setGe(false);
  }, []);

  // console.log(sponsor)
  
  const sendGift = (item,usr) => {
    // const success =  alertService.info(
    //   soon,
    //   'Coming soon!'
    // )
    //console.log('itemid'+ item);
    openDrawer();
    setGift(!gift);
    
  }



  //const cdn = 'https://files.geetsuhane.com/';
  const cdn = 'https://files.geetsuhane.com/';
  const ssd = process.env.REACT_APP_API_END_POINT+'/gsfiles/';
  //const { posts, loadMore,hasMore, topList, topShow,flash } = useContext(PostsContext);
  // const {  postList,getPosts } = useContext(PostsContext);
  const [more, setMore]=useState(true);
  const queryParams = new URLSearchParams(window.location.search)
  const searchTerm = queryParams.get("search")
  const postid = queryParams.get("postid")
  const postType = queryParams.get("type")
  const [momentsList, setMomentsList]=useState(null);
  //console.log(queryParams);


//  console.log(topList)
const loadMorePosts = async () =>{
console.log(momentsList[momentsList?.length-1]?.id)
  setMore(true)
  //getPosts(30,postList?.length?postList[postList?.length-1]?.id:0,0,2,searchKey);
  await searchChange(30,momentsList[momentsList?.length-1]?.id,searchKey)
  setMore(false)
} 

const searchChange = async (pcount,pstart,s) => {
  //console.log(s)
  if(s==='songs' || s==='videos') {
    setSearchKey(s);
    setMore(true)
    //getPosts(50,0,0,2,'songs');
    const postData = await profileService.getResult({         
      qry :`CALL sp_searchPosts(${pcount},${pstart},0,2,'${s}')`
    })
    if(postData)
    setMomentsList(postData?.[0])
   
    setMore(false)
  } else if(s?.length>5 ) {
    setSearchKey('#'+s+'#');
    setMore(true)
    // getPosts(30,0,0,2,s.length>0?s:searchTerm);
    setMore(false)
  } else if(sponsor ) {
    // const postData = await profileService.getResult({         
    //   qry :`CALL sp_searchPosts(${pcount},${pstart},0,2,'${s}')`
    // })
    // if(postData)
    // setMomentsList(postData?.[0])
    // setMore(false)
  } else {
    setMore(true)
    // console.log(postid)
    // getPosts(50,postid?postid:0,0,2,'');
    const postData = await profileService.getResult({         
      qry :`CALL sp_getPosts(${pcount},${pstart>0?pstart:0},0,2)`
    })
    if(postData)
    setMomentsList(postData?.[0])
   
    setMore(false)
  }
   
}

if(currentUser?.status===2) {
  navigate(
    "/denied"
  )
}

useEffect(() => {
  // console.log(searchTerm);
  if(postType==='songs' ||postType==='videos') {
    searchChange(30,0,postType);
  } else if(searchTerm) {
    searchChange(30,0,searchTerm);
  } else {
    searchChange(30,0,'');
  }
  //console.log('hello')
  // console.log(postList)  
}, []);


  //console.log(auditionResult)

  return (  
    <Fragment>
      
      <div className={`${sponsor?"":"main-content"} right-chat-active`}>
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
           {!sponsor && <>
          <Appfooter first="home" second="user"></Appfooter>
          <Pagetitle header="Posts" title={searchKey}  onChange={searchChange}></Pagetitle>
          </>
        }
            <div className="row feed-body">
              <div className="col-xl-8 col-xxl-9 col-lg-8">

          
                 {(sponsor?topList:momentsList)?.length>0  &&
                   (sponsor?topList:momentsList).map((item, index) => {     
                      // console.log(item);
                      return (
                      
                        <React.Fragment key={index}>
                        
                          <Suspense fallback={<Load />}>
                          <Postview
                            id={item?.id}
                           
                            postimage={item?.mediaType== 'img' ? cdn+'users/'+item?.mediaUrl:'' }
                            postvideo={item?.mediaType== 'video' ? (item?.status==2?ssd+item?.mediaUrl:cdn+'users/'+item?.mediaUrl):'' }
                            avater={item?.picture?(item?.picture.includes('googleusercontent')?item?.picture:cdn+'users/'+item?.picture):""}
                            user={item?.name?item?.name:''}
                            time={item?.createdAt}
                            des={item?.text}
                            item={item}
                            setGift={sendGift}
                            giftItem={giftItem}
                            
                          />
                          </Suspense>
                        
                        </React.Fragment>
                      );
                    })
                    }
                    {
                      sponsor ? (
                      <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">                                                                               
                 
                   <Link to={`/buzz?type=videos`} className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block  bg-current font-xsssss fw-700 ls-lg text-white">
                   Videos </Link>
                   <Link to={`/buzz?postid=${topList?.length?topList[29]?.id:0}`}
                   className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block  bg-gold-gradiant font-xsssss fw-700 ls-lg text-white"> 
                   Moments </Link>
               </div> ):(
             
                        !more &&
                      <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">                                                                               
                  <a onClick={() => loadMorePosts()}
                   className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block  bg-current font-xsssss fw-700 ls-lg text-white"> 
                   More  </a>
                   {more  && 
               <Load/>}
               </div>) }
              
                 
             
              </div>
            
      <center>   
      
      <Drawer
        duration={250}
        hideScrollbars={true}
        onClose={closeDrawer}
        isVisible={isVisible}
      >
        <div className="fw-700 text-grey-900 font-xsssss mt-1"> <img style={{width:"12px",height:"12x"}} src={localStorage.getItem('userpic')}></img> {localStorage.getItem('user')}</div>
       
        
          <Gifts giftItem={giftItem}  setgiftItem = {setgiftItem}></Gifts>
         
          </Drawer>
          </center>
          </div> 
            </div>
          </div>
        </div>
      
    </Fragment>
  );
}
