import React, { Fragment,useContext,useState,useEffect } from "react";
import { Link } from 'react-router-dom';
import { AuthContext } from "../context/authContext";
import alertService from "../services/sweetAlert";
import { useNavigate } from 'react-router-dom';
import profileService from "../services/profile.service";

export function Admin() {  
const { currentUser } = useContext(AuthContext);
const navigate = useNavigate();
const [controller, setController] = useState(false);



    if(currentUser==null)
    {
    localStorage.setItem("redirect", "/admin");
    navigate('/login')
    } else {
        if (currentUser?.leads<2) {
            alertService.info(
                'Not Authorized!',
                'You are not authorized to view this page'
                );
        }
    }

    const toggleEvent = async (section,keyname, keyval,action) => {
        var confirmed;
        if(action===1) {
           confirmed=  alertService.confirm(
                'Tun on '+ section,
                'Are you sure you want to turn on '+ section +' ?'
                );                
        }  else if(action===0 || action===3) {
             confirmed=  alertService.confirm(
                  'Tun on '+ section,
                  'Are you sure you want to turn off '+ section +' ?'
                  );                
          }

          if(confirmed) {
            const res = await profileService.getResult({
                qry: `CALL sp_updateAdmin('${currentUser?.id}','${section}','${keyname}','${keyval}', ${action});`
            })
            alertService.info(
                'Successful',
                'Event has been successfully update'
                );     

            }          
    }
    const getData = async () => {
        const res = await profileService.getResult({
            qry: `CALL sp_getController();`
        })
        setController(res?.[0][0])
        
    }

    useEffect(() => {
        async function updateData() {
            await getData();
        }
        updateData();
      }, []);
        return (
            <Fragment> 
              

                <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="middle-wrap">
                                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                    
                                    <div className="card-body p-lg-5 p-4 w-100 border-0">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h4 className="mb-4 font-xxl fw-700 mont-font mb-lg-5 mb-4 font-md-xs">Event Controller</h4>
                                        
                                                <ul className="list-inline mb-4">
                                                <li className="list-inline-item d-block border-bottom me-0">
                                                        <Link                                                      
                                                        to="/nominate?type=generic"
                                                        className="pt-2 pb-2 d-flex align-items-center">
                                                        <i onClick={() => toggleEvent('','','','',1)} className="font-xxl p-1 bg-mini-gradiant text-white feather-toggle-left font-xl me-3"></i>
                                                        <h4 className="fw-600 font-xsss mb-0 mt-0">Nominations - 1st Audition</h4>
                                                        </Link>
                                                    </li> 
                                                  
                                                  
                                                </ul>
                                             
                                               
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>

              
            </Fragment>
        );
    }


export default Admin;