import React, { useState, useRef, useContext } from "react";
import { AuthContext } from "../context/authContext";
import axios from "axios";
import { uploadFile} from "../files";
import postService from "../services/posts.service";
import profileService from "../services/profile.service";
import alertService from '../services/sweetAlert';
import GiphyGif from "./GiphySearch";


export default function Createpost() {
  const { currentUser } = useContext(AuthContext);
  const contentid = "textEdit";
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [file, setFile] = useState("");
  const [fileContent, setFileContent] = useState("");
  const [fileType, setFileType] = useState(null);

  const fileInputRef = useRef(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const handleFileChange = (event) => {
    if(currentUser?.banned===1) return;
    const file = event.target.files[0];
    setFileType(
      file.type.startsWith("image/")
        ? "img"
        : file.type.startsWith("video/")
        ? "video"
        : null
    );
    setFile(file);
    const reader = new FileReader();
    console.log(file.size);
    if(file.size<7158567) {
      reader.readAsDataURL(file);
      reader.onload = () => {
      setFileContent(reader.result);
      alertService.info(
        'Create post',
        'please click submit button.'
      ); 
     };
    } else {
      alertService.info(
        'Create post',
        'Preview is not available, please click submit button.'
      ); 
    }
    
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const sendGif = (p) => {
    setLoading(true);
    saveText(p,'','gif');
    setText('')
    setLoading(false);
  }

  const saveText = async (text, posturl,mtype) => {  
    //const content = getInnerHtml(contentid);
    // const content = document.querySelector(`#${contentid}`);
    // text = content.innerHTML;    
   // console.log(content);
    await postService.createPost({
      text,
      mediaUrl:mtype==='gif'? null:posturl,
      mediaType:mtype==='gif'?'gif':fileType,
      userId: currentUser.id,
    });
   
    if(mtype==='gif' || mtype==='text' || mtype==='')
    alertService.info(
      'Create post',
      'Post submitted successfully!'
    ); 
    discardPost();
  };

  const submitFunction = async () => {
    if(loading) return;
    const content = document.querySelector(`#${contentid}`);
    const contentData = content.innerHTML;
    //setText( content.innerHTML);    
    if (!currentUser) {
      alertService.info(
        'Create post',
        'you cannot post without logging in!'
      );  
      return; //alert("you cannot post without logging in");
      

     } else if (contentData==='') {
      alertService.error(
        'Create post',
        'Post text is Empty, please put some text!'
      );  
      return;
    }
    const result = await profileService.checkBanned({
      userid: currentUser.id
      });
      if(parseInt(result?.[0][0].banned)>0) {
        console.log(result?.[0][0].banned);
        return;
      }
     
      setLoading(true);

  //  const res = await profileService.auditData({
  //   userid: currentUser.id, activity: text.substring(0, 39)
  //   });

    //saveText();
    //console.log(file)

    if (contentData || file) {
     
      if (file) {
        const filePath = currentUser.id+'/posts/'+Date.now() +'/'+ file.name;
        const fileDir = currentUser.id+'/posts/'+Date.now();
        let formData = new FormData();

        formData.append('filePath', filePath);
        formData.append('fileDir', fileDir);
        formData.append('file', file);
        //  const baseUrl = "http://localhost:3005/profile/uploadfile";
        const baseUrl = process.env.REACT_APP_API_END_POINT+"/profile/uploadfile";
       
        console.log(file);
        axios.post(baseUrl, formData, { headers: {'Content-Type': 'multipart/form-data'}})
            .then((res) => {
           
                if (res.status === 201)
                  saveText(contentData,filePath,'');
                
                    alertService.info(
                      'Create post',
                      'File uploaded successfully!'
                    ); 
                  
                    return;

            })
            .catch((error) => {
              console.error('Upload error:', error.response || error.message);
              alertService.info('Create post', 'Upload failed. Please try again.');
            });
           //saveText( text.substring(0, 399), filePath,'');
        
        return;
      }
      saveText(contentData,'','');
  
    }
  };
 
  const discardPost = () => {
    setLoading(false);
    setText("");
    document.querySelector(`#${contentid}`).innerHTML="";
    setFile("");
    setFileContent("");
    setIsOpen(false);
    setFileType(null);
  };
  return (
    
    <div className="">
      <div className="font-xsssss text-center"> Submit your post - text with or without formating and images/videos </div>
      {  text.length>2 && text.length<15 &&
        <GiphyGif text={text}  onClick={sendGif}></GiphyGif> 
      } 
       <div onInput={e => setText(e.currentTarget.textContent)}
                className="text-editor"
                id={contentid}
                contentEditable={true}
                suppressContentEditableWarning={true}
                
            >
              
            </div>
     {/* <TextEditor 
     value =""
      editorStyle ={{fontSize: "10px"}}
      className="font-xssss"
        showHeadings={false}
        showUndoRedo={false}
        showJustify ={false}
            id={contentid}        //  required
        /> */}
      {loading && (
        <div className="position-absolute spinner">
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      )}
    
      <div className="card-body p-0 mt-3 position-relative">
        {/* <figure className="avatar position-absolute ms-2 mt-1">
          {currentUser?.picture ? (
            <img
              className="rounded-img-sm"
              src={currentUser ? 'https://files.geetsuhane.com/users/'+currentUser.picture : ""}
              alt=""
            />
          ) : (
            <div className="align-items-center bg-grey d-flex justify-content-center rounded-img-sm">
              <span className="ti-user font-sm"></span>
            </div>
          )}
        </figure>
        {  text.length>2 && text.length<15 &&
        <GiphyGif text={text}  onClick={sendGif}></GiphyGif> 
      } */}
      
     
        {/* <textarea
          name="message"
          className="h100 bor-0 w-100 rounded-xxl p-3 ps-5 font-xssss text-blue-500 fw-500 border-light-md theme-dark-bg"
          cols="30"
          rows="10"
          placeholder="  What's on your mind?"
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
        ></textarea> */}
      </div>
      <div className="card-body d-flex p-0 mt-0">
   
        <div className="d-flex justify-content-between w-100">
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <div
            onClick={handleButtonClick}
            className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 cursor-pointer"
          >
            <i className="font-md text-success feather-upload me-2"></i>
            <span className="d-none-xs">Upload</span>
          </div>

          <div
            onClick={submitFunction}
            className="font-xssss fw-600 ls-1 text-grey-700 text-dark cursor-pointer"
          >
            Submit
          </div>
        </div>

        {/* <a
          href="#activity"
          className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"
        >
          <i className="font-md text-warning feather-camera me-2"></i>
          <span className="d-none-xs">Feeling/Activity</span>
        </a> */}
      </div>

      <div className="">
        {fileContent && (
          <div className="">
            {fileType === "img" ? (
              <img className="w-25 my-4 h-25" src={fileContent} alt="" />
            ) : (
              <video controls className="w-25 my-4 h-25">
                <source src={fileContent} type="video/mp4" />
              </video>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
