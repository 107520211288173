import ApiService from "./api.service";

class EventService {
  constructor(apiService) {
    this.apiService = apiService;
  }

 
  async listSingers() {
    try {
      const response = await this.apiService.get(
        `/event/singers`
      );
      console.log('response:'+response);
      return response;
    } catch (error) {
      console.log(error);
    }
  }


  async saveRatings({ singerid, judgeid, rating, eventid }) {
    try {
      const response = await this.apiService.post("/event/updateRatings", {
        singerid,
        judgeid,
        rating,
        eventid,
      });
      //console.log(response.result)
      return response.message;
    } catch (error) {
      throw new Error('Error: Could not save!');
    }
  }
  async participants({  uid, eventid }) {
    try {
      var response = await this.apiService.post("/event/participants", {
        uid,
        eventid,
      });
      return response;
    } catch (error) {
      throw new Error('Error: Could not save!');
    }
  }

  async certificates({  uid, eventid }) {
    try {
      var response = await this.apiService.post("/event/certificates", {
        uid,
        eventid,
      });
      return response;
    } catch (error) {
      throw new Error('Error: Could not save!');
    }
  }

  async saveNominations({ event,name, uid, imgpath, sid,group,text }) {
    console.log('immmm..'+imgpath)
    try {
      const response = await this.apiService.post("/event/nominations", {
        uid,
        name,
        event,
        imgpath,
        sid,
        group,
        text
      });
      alert(response.message);
    } catch (error) {
      throw new Error('Error: Could not save!');
    }
  }
  


}

// Example usage:
const apiService = new ApiService();
const eventService = new EventService(apiService);

export default eventService;
