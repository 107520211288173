import { createRoot } from 'react-dom/client';
import * as serviceWorker from "./serviceWorker";
import "./main.scss";


//context to stable the login and auth system


import App from "./App"
//const vote = `${user ? "Vote" : "Login"}`;
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab="home" />);
// Register the service worker for PWA capabilities
if (process.env.NODE_ENV === 'production') {
    serviceWorker.register(); // Ensures the service worker is only registered in production
  } else {
    serviceWorker.unregister(); // Unregisters any existing service worker in non-production environments
  }
