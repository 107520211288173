import React, { Component , Fragment, useState,useEffect } from "react";
import { AuthContext } from "../context/authContext";
import { useContext } from "react";
import authService from "../services/auth.Service";
import profileService from "../services/profile.service";
// import { uploadImages } from "../files";
import alertService from '../services/sweetAlert'
import axios from "axios";


const  Profilephoto = ()  => {
    const { currentUser } = useContext(AuthContext);
  const [images, setImages] = useState(null);

  const [imageName, setImageName] = useState([]);
  const cdn ='https://files.geetsuhane.com/';
  const { tab1, isOpen } = useState(false);

  const deleteImage = async(img) => {

    var filePath = String(img).replace("https://files.geetsuhane.com/users/","")
    //console.log(filePath);

    const confirmed = await alertService.confirm(
      'Delete Message',
      'Are you sure you want to delete this message?'
    )

    if (confirmed) {
      // delete the item
      console.log(filePath);
      const res = await profileService.deleteAlbum({
        uid: currentUser.id,
        filePath: filePath
      });
      alertService.success(
        'Album update',
        'The image has been successfully deleted.'
      )
      await getImagesfromStorage();
  
      
      //console.log(images);
      }
  }
  
    
 
   const addImagetoSlider = async (e) => {
    
     if (e.target.files[0] == null) return;
     //setFile(file);
     const file = e.target.files[0];
     const filePath = currentUser.id+'/album/'+ file.name;
     const res = await authService.updateAlbum({
       userId: currentUser.id,
       text: filePath
     });
     if(res.message=='Sucessfully updated!') {
      // const uploadTask = await uploadImages(image, filePath);   
      // alertService.info(
      //   'Profile update',
      //   res.message
      // )
      if(file) {
        let formData = new FormData();
        //const uploadTask = await uploadImages(file,filePath);
        
        formData.append('filePath', filePath);
        formData.append('fileDir', filePath);
        formData.append('file', file);

        // const baseUrl = "http://localhost:3005/profile/uploadfile";
        const baseUrl = process.env.REACT_APP_API_END_POINT+"/profile/uploadfile";
        //setImages((images) => [...images, file.filePath]); 
        axios.post(baseUrl, formData, { headers: {'Content-Type': 'multipart/form-data'}})
            .then((res) => {
                console.log(res);
                if (res.status === 201)
                    
                    alertService.info(
                      'Album update',
                      'Image uploaded successfully!'
                    ); 
                    getImagesfromStorage();
                    return;

            })
            .catch((error) => {
              console.error('Upload error:', error.response || error.message);
              alertService.info('profile upload', 'Upload failed. Please try again.');
            });
    }
 
      //alert(res.message);
      //getImagesfromStorage()
     } else {
      //alert('Opps, please try after some time');
      alertService.info(
        'Profile update',
        'Opps, please try after some time'
      )
     }
    
     console.log(res);
   
   };

  

  const getImagesfromStorage = async () => {

      var albumList = await profileService.getResult({
          qry: `CALL sp_getAlbum('${currentUser?.id}') `
      });
      if(albumList) {
        setImages(albumList?.[0]);
        //console.log(albumList?.[0]);
      }
     
    //   var albumList = await profileService.getResult({
    //     qry: `	select picture  from album  where id='${currentUser?.id}' order by createdAt desc;`
    // });
    // setImages(albumList)

    // setImages((images) => [...images, 'https://files.geetsuhane.com/album/default/1598006f-52c0-4c0e-81d7-32424b5f59d4.jpeg']);

    //console.log(images);
  };
  useEffect(() => {
    async function fetchSliderImages() {
      await getImagesfromStorage();
    }
    fetchSliderImages();
  }, []);
        return (
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
             
                <div className="card-body d-flex align-items-center  p-4">
                    <h4 className="fw-700 mb-0 font-xssss text-grey-900">Album</h4>
                    <h4 style={{paddingRight:"10px"}}></h4>
                    <input
                      type="file"
                      id="fileInput"
                      onChange={addImagetoSlider}
                      className="d-none"
                    
                    />
                <label
                  htmlFor="fileInput"
                  style={{ bottom: "7rem", right: "0", cursor: "pointer", height: "40px",
                    width: "40px",borderRadius: "25px",alignContent: "center",justifyContent: "center",display: "flex",
                    alignItems: "center",backgroundColor: "rgba(0,0,0,0.5)",color: "white",zIndex: "1",}} >
                  <i className="feather-edit-3 font-md"></i> 
                </label>
                    
                </div>
                <div className="card-body d-block pt-0 pb-2">
                { images &&
                    <div className="row ps-3 pe-3">
                    
                    {images?.length && images?.map((value , index) => (
                            <div className="col-6 mb-1 p-1" key={index}>                                                                                  
                                <div>
                              
                                    <a onClick={() => deleteImage(value?.picture)}>
                                        <img src={`${cdn+'users/'+value?.picture}`} alt="Portfolio" className="img-fluid rounded-3 w-100"/>
                                    </a>
                        
                                </div>
                            
                            </div>
                        ))}
                    </div> }
                </div>
              
            </div>
        );
    }


export default Profilephoto;