import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import { AuthContext } from "./authContext";
//import io from "socket.io-client";
import { createSocket } from '../socket';  // Import the `createSocket` function
import { handleUpload } from "../services/cloudinary.service";

export const SocketContext = createContext();

export function generateUUID() {
  let uuid = "";
  for (let i = 0; i < 32; i++) {
    if (i === 8 || i === 12 || i === 16 || i === 20) {
      uuid += "-";
    }
    uuid += Math.floor(Math.random() * 16).toString(16);
  }
  return uuid;
}

const SocketContextProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const [socket, setSocket] = useState(null);
  const [chat, setChat] = useState([]);
  const [users, setUsers] = useState([]);
  const [activeChat, setActiveChat] = useState("");
  const [audioBlob, setAudioBlob] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const messageContainerRef = useRef(null);


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    // Scroll to bottom of messages container when messages update
    messageContainerRef.current.scrollTo({
      top:
        messageContainerRef.current.scrollHeight -
        messageContainerRef.current.clientHeight,
      behavior: "smooth",
    });
  }, [chat]);

  useEffect(() => {
//console.log("activeChat"+activeChat)
    if (socket && activeChat) {
      socket.emit("findAllMessageForAChat", {
        from: currentUser?.id,
        to: activeChat,
      });
    }
  }, [socket, activeChat, currentUser]);

  useEffect(() => {
    //console.log(users)
    if (users?.length) {
      if (!activeChat) {
        const filteredUsers = users?.filter(
          (user) => user.id !== currentUser.id
        );
        const randomUser = filteredUsers?.[0];
        if (randomUser) {
          setActiveChat(randomUser);
        }
      }
    }
  }, [currentUser, users]);

  useEffect(() => {
    if (socket) {
      socket.on("setAllMessagesForAChat", (data) => {
        //console.log(data)
        setChat(data);
      });
      socket.on("messageRec", (data) => {
        console.log(data);
        setChat([...chat, data]);
      });
      // When a message is sent, update the chat state object using the custom ID as the key
      socket.on("messageSent", ({ custom_id, message }) => {
       
        setChat((prevChat) =>
          prevChat.map((msg) => {
            if (msg.custom_id === custom_id) {
              //console.log(prevChat)
              return message;
            }
            return msg;
          })
        );
      });
 
      
      socket.on("messageDeleted", (msgId) => {
        setChat((prevChat) => prevChat.filter((msg) => msg.id !== msgId));
      });
    }
    return () => {
      if (socket) {
        socket.off("messageRec");
        socket.off("messageSent");
        socket.off("setAllMessagesForAChat");
        socket.off("messageDeleted");
      }
    };
  }, [socket, chat]);

  useEffect(() => {
    if (currentUser) {
      //const newSocket = io(process.env.REACT_APP_API_END_POINT);
      const newSocket = createSocket();
      setSocket(newSocket);

      newSocket.on("connect", () => {
        console.log("Connected to server");
        newSocket.emit("join", {
          name: currentUser?.name,
          id: currentUser?.id,
        });
      });

      newSocket.on("updatedUsers", (data) => {
        setUsers(data);
      });
    }
  }, [currentUser]);

  const sendMessage = (message) => {
    console.log(message)
    message.custom_id = generateUUID();   
    setChat([...chat, message]);
    socket.emit("message", message);
  };

  const handleSendAudio = async () => {
    const url = await handleUpload(audioBlob);
    sendMessage({
      message: url,
      senderId: currentUser.id,
      receiverId:activeChat.id,
      time: new Date(),
      msgType: "audio",
      sender: currentUser,
    });
    setAudioBlob(null);
  };

  const deleteMessage = (id) => {
    socket.emit("deleteMessage", { msgId: id, to: activeChat});
    setChat((prevChat) => prevChat.filter((msg) => msg.id !== id));
  };

  return (
    <SocketContext.Provider
      value={{
        socket,
        messages: chat,
        sendMessage,
        users,
        activeChat,
        setActiveChat,
        messageContainerRef,
        audioBlob,
        setAudioBlob,
        handleSendAudio,
        deleteMessage,
        windowWidth
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketContextProvider;
