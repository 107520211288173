import { Cloudinary } from "@cloudinary/url-gen";

const cld = new Cloudinary({
  cloud: {
    cloudName: "zee-cloud",
    apiKey: "336926617531131",
    apiSecret: "vv03Kpn9GdC0pYJYEsjirQpsnNo",
  },
});

export const uploadImage = async (imageUrl) => {
  if (imageUrl) {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      // Upload the image to Cloudinary
      const upload = await cld.upload(blob, {
        folder: "my_folder", // Optional folder name in your Cloudinary account
        resource_type: "image",
      });
      return upload.secure_url;
    } catch (error) {
      console.log(error);
    }
    // Download the image from the URL
  }
};

export const handleUpload = async (audioBlob) => {
  const formData = new FormData();
  formData.append("file", audioBlob);
  try {
    const response = await fetch(
      `https://api.cloudinary.com/v1_1/zee-cloud/upload?upload_preset=mu1p4qn6&resource_type=audio`,
      {
        method: "POST",
        body: formData,
      }
    );
    
    const data = await response.json();

    const url = data.secure_url.replace(/\.webm$/, ".mp3");
    return url
  } catch (error) {
    console.error(error);
  }
};
