import React, { Fragment, useContext, useState } from "react";
import { AuthContext } from "../context/authContext";
import { Link } from "react-router-dom";
import GoogleLogin from "../components/auth/googleLogin";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { loginWithEmailPassword } = useContext(AuthContext);

  // console.log('login')
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    await loginWithEmailPassword({ email, password });
  };


  return (
    <Fragment>
      <div className="main-wrap">
        <div className="nav-header bg-transparent shadow-none border-0">
          <div className="nav-top w-100">
            <button className="nav-menu me-0 ms-auto"></button>

            <Link
              to="/login"
              className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl"
            >
              Login
            </Link>
            <Link
              to="/register"
              className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl"
            >
              Register
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
            <div className="card shadow-none border-0 ms-auto me-auto login-card">
              <div className="card-body rounded-0 text-left">
              <div
                  className="card-body position-relative h300 bg-image-cover bg-image-center"
                  style={{ backgroundImage: `url("assets/images/gs.png")` }}
                ></div>

                <div className="col-sm-12 p-0 text-center mt-2">                
                  <GoogleLogin />                  
                  {/* <FacebookLogin />                   */}
                </div>
                <h2 />

                <form onSubmit={handleSubmit}>
                  <div className="form-group icon-input mb-3">
                    <i className="font-sm ti-email text-grey-500 pe-0"></i>
                    <input
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      type="email"
                      className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                      placeholder="Your Email Address"
                    />
                  </div>
                  <div className="form-group icon-input mb-1">
                    <input
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      type="Password"
                      className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                      placeholder="Password"
                    />
                    <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                  </div>
                  <div className="form-check text-left mb-3 d-flex align-items-center justify-content-between">
                  
                    <Link
                      to="/forgot-password"
                      className="fw-600 font-xsss text-grey-700 mt-1 float-right"
                    >
                      Forgot your Password?
                    </Link>
                  </div>
                  <div>
                  <button
                    type="submit"
                    className="col-12 col-md-12 p-0 border-0 text-left"
                  >
                    <div className="form-group mb-1">
                      <div className="form-control text-center w-100 style2-input text-white fw-600 bg-dark border-0 p-0 ">
                        Login
                      </div>
                    </div>
                  </button>
                  </div>
                
                </form>
                <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                  Dont have account{" "}
                  <Link to="/register" className="fw-700 ms-1">
                    Register
                  </Link>
                </h6>
                <div className="font-xsssss">Please contact support@geetsuhane.com or whatsup +1 332 287 8300 if you have any queries.</div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
