import React, { Fragment} from "react";



export default function Denied() {


    
        return (
            <Fragment> 
     
 
            <div className="main-content right-chat-active bg-white">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0" >
                        <div className="row">
                            <div className="col-xl-12 cart-wrapper mb-4">
                                <div className="row">
                                    <div className="col-lg-5 mb-3">
                                      
                                    </div>
                                   
                                </div>
                              
                                <div>
                                <p className="strong text-red">   It sounds like your account was temporarily locked due to suspicious activity detected by the system. </p>
                            <p>This is a security measure to protect your information. You can try accessing your account again after 24 hours, and if the issue persists, consider reaching out to the support team of the service for further assistance.</p>
                            <p>Whatsup +1 (332) 287-8300</p> 
                                </div>

                          <div className="text-center">
                            <img src="https://files.geetsuhane.com/home/denied.jpg"></img>
                          </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
     
        </Fragment>
        );
        }
