import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';

export default function Avatars(props   ) {
    // console.log(props);
  return (
    <AvatarGroup max={4}>
        
     {props?.props?.length>0 && props?.props?.split(',').map((value , index) => (
      <Avatar key={index} alt="Remy Sharp" src={value?(value.includes('googleusercontent')?value:'https://files.geetsuhane.com/users/'+value):`assets/images/user.png`} /> 
    ))}

    </AvatarGroup>
  );
}