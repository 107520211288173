import React, { useContext } from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import jwt_decode from 'jwt-decode'
import './googlelogin.css'

import { AuthContext } from '../../context/authContext'

const GoogleLoginComponent = () => {
  const { login } = useContext(AuthContext)
  const googleLoginFunction = useGoogleLogin({
    onSuccess: async codeResponse => {
      const request = await fetch(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${codeResponse.access_token}`
      )
      const user = await request.json()
      login(user, 'google')
    }
  })

  return (
    <div className='row mt-5'>
      <div className='col-md-12'>
        <div
          onClick={() => {
            googleLoginFunction()
          }}
          className='google-btn d-flex align-items-center mb-3'
        >
          <div className='align-items-center d-flex google-icon-wrapper justify-content-center'>
            <img
              className='google-icon'
              src='assets/images/goo.svg'
              alt='google'
            />
          </div>
          <p className='btn-text d-flex flex-grow h-100 justify-content-center mb-0 w-100 align-items-center'>
            <b>Sign in with google</b>
          </p>
        </div>
      </div>
    </div>
  )
}

export default GoogleLoginComponent
