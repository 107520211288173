import React, {  Fragment, useState , useContext, useEffect, useRef} from "react";
import Pagetitle from '../components/Pagetitle';

import profileService from "../services/profile.service";

import alertService from '../services/sweetAlert'
import { AuthContext } from "../context/authContext";
import { useNavigate } from 'react-router-dom';


    export default function Result() {
      
        
        const [singers, setSingers] = useState([]);
        const [event, setEvent] = useState("");
        const [stars, setStars] = useState([]);
        const navigate = useNavigate()
        const { currentUser } = useContext(AuthContext);
        const cdn = "https://files.geetsuhane.com/"
        const inputRefStars = useRef(0);


      
        if(currentUser==null)
        {
          localStorage.setItem("redirect", "/judge")
          navigate('/login')
        } 
        else {
            //console.log(currentUser)
           
            if(currentUser.id=='40b7b63d-946b-4d3a-954f-2aa4bc331ce6' || currentUser.id=='fbe89aa8-fdee-4803-aab8-b0a816a4668d') {
               
            } else {
              alertService.info(
                'No Entry',
                'not authorized to view this page!'
                )
                //alert('not authorized to view this page!')
                navigate('/')
            }


        }

      

    //const singerslist =[];
        const getSingers = async () => {   
          var eventval = await profileService.getResult({
            qry: "select c.value from gsglobal.config c where c.section='Vote' and c.key='Audition' and c.status>0 "
          });
          if(eventval){
            //console.log(eventval)
            if(eventval.length) {
                setEvent(eventval[0].value)
                //console.log(eventval)
            }
          }

          var grpSingers = await profileService.getResult({
            qry: `SELECT distinct u.id as uid,n.id as nid,u.sid, u.name as Name, sum(r.rating)+p.monthly_points  as 'Score',sum(r.rating) as Votes ,p.monthly_points as Points FROM gsglobal.nominations n  
            join gsglobal.ratings r on n.uid=r.singerid join users u on u.id=r.singerid  join gsglobal.config c on c.section='Vote'
            and c.key='Audition' and r.event=c.value and n.event=c.value
            join gsglobal.points p on p.userid=r.singerid
            where c.status>0 and r.createdAt>= DATE_SUB(NOW(),INTERVAL 2 day)
            group by u.name,n.stars,u.id,n.sid,n.id order by sum(r.rating)+p.monthly_points desc;`
          });
          setSingers(grpSingers);
    
          //console.log(grpSingers);
    
        }
        useEffect(() => {
            async function fetchSingers() {
              await getSingers();
            }
            fetchSingers();
          }, []);
    
        const saveStars = async (singer) => {     
            console.log('stars'+stars)    ;   
            var res = await profileService.getResult({
                qry: "update gsglobal.nominations set stars="+ stars +" where id='"+singer+"'"
              });
              if(res){
                if(res.affectedRows>=1) {
                  alertService.success(
                    'Thank You!',
                    'Stars has been updated!'
                    )
                    //alert('stars has been updated!')
                }
              } else {
                alert('Error while updating!')
              }
                console.log(res);     
          };

          const publish = async () => {     

            // var res = await profileService.getResult({
            //     qry: "select nid from gsglobal.result r  join  ( select id from gsglobal.nominations order by stars desc limit 3) as n on r.nid=n.id and MONTH(r.createdAt)=MONTH(CURRENT_DATE) where r.event='"+event+"' LIMIT 1"
            //   });
             
            //   if(res){
            //     console.log(res.length)
            //     if(res.length!=0) {
            //         console.log('not required');
            //         //alert('result has been published!')
            //     } else {
            //         console.log('insert');
            //         var res = await profileService.getResult({
            //             qry: "insert into gsglobal.result (nid,uname,usid,event,status, category, stars)  select n.id, u.name,u.sid, '"+event+"', 1, 'Top Stars', stars from gsglobal.nominations n join gsglobal.users u on n.uid=u.id where event='"+event+"' and  MONTH(n.createdAt)=MONTH(CURRENT_DATE) order by stars desc limit 3"
            //           });
            //   } 
            //   }
             
            var res = await profileService.getResult({
                qry: "update gsglobal.result r set r.status=1 where r.event='"+event+"'"
              });
              if(res){
                if(res.affectedRows>=1) {
                  alertService.success(
                    'Thank You!',
                    'Result has been published!'
                    )
                    //alert('result has been published!')
                }
              } else {
                alertService.info(
                  'Error',
                  'Error while updating!!'
                  )
                //alert('Error while updating!')
              }
            
                //console.log(res);     
          };

          const saveResult = async (singer,sid,uname,category,avgscore,starsval,votes) => {     
            
            var res = await profileService.getResult({
                qry: "select rid from gsglobal.result r  where nid="+singer+" and r.event='"+event+"'"
              });
                console.log(res);     
                if(res.length>0) {
                    console.log('update');
                    var res = await profileService.getResult({
                        qry: "update gsglobal.result r set r.category='"+ category +"' where r.nid="+singer+" and r.event='"+event+"'"
                      });
                      if(res){
                        if(res.affectedRows>=1) {
                          alertService.success(
                            'Thank You!',
                            'Result has been updated!'
                            )
                            //alert('result has been updated!')
                        }
                      } else {
                        alert('Error while inserting!')
                      }
                } else {
                    console.log('insert');
                    var res = await profileService.getResult({
                        qry: "insert into gsglobal.result (nid, usid,uname,event,status, category,avgscore,points,votes) values("+singer+",'"+sid+"','"+uname+"','"+event+"',2,'"+category+"',"+avgscore+","+starsval+","+votes+")"
                      });
                      if(res){
                        if(res.affectedRows>=1) {
                          alertService.info(
                            'Sucessful',
                            'Result has been updated!'
                            )
                            //alert('result has been updated!')
                        }
                      } else {
                        alertService.info(
                          'Error',
                          'Error while updating!!'
                          )
                      }
                }
          };

       
        return (
            <Fragment> 
             
                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            <div className="row">
                                <div className="col-xl-12">
                                    <Pagetitle header="Result" title={event} />
                                    <div className="fw-700 font-xsss mt-3 mb-0">
                                        <a href="#" onClick={() => publish()}
                                          className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-secondary font-xsssss fw-700 ls-lg text-white">Publish</a>
                                           
                                        </div>

                                    <div className="row ps-2 pe-2">
                                       
                                        {singers.map((value , index) => (
                                        <div key={index} className="col-md-8 col-sm-4 pe-2 ps-2">
                                            <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                                                <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                                                  
                                                    <div className="clearfix w-100"></div>
                                                    <h4 className="fw-700 font-xsss mt-3 mb-0">{value.Name} </h4>
                                                    <span className="fw-500 font-xssss text-red-500 mt-0 mb-3">Total- {value.Score}</span>
                                                    <span className="fw-500 font-xssss text-red-500 mt-0 mb-3"> Votes- {value.Votes}</span>
                                                    <span className="fw-500 font-xssss text-blue-500 mt-0 mb-3"> Points -{value.Points}</span>
                                                    <div>
                                                    {/* <input  type="number" placeholder = "# of stars" defaultValue={value.Stars} onChange={(e) => {setStars(e.target.value)}} ref={inputRefStars}  /> */}
                                                   
                                                    </div>                              
                                                   
                                                    <br/>
                                                    {/* <a onClick={() => saveStars(value.nid)}
                                                    className="mt- btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-primary font-xsssss fw-700 ls-lg text-white">Update</a>
                                            */}
                                                    <a  onClick={() => saveResult(value.nid, value.sid,value.Name,'Winner',value.Score, value.Points,value.Votes)}
                                                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white">Winner</a>
                                                     {/* <a  onClick={() => saveResult(value.nid, value.sid,value.Name,'CC',value.Score,value.points)}
                                                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-secondary font-xsssss fw-700 ls-lg text-white">CC</a> */}
                                                </div>
                                                  
                                            </div>
                                           
                                        </div> 
                                        
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        );
}
