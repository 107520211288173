import React, { useContext, useMemo,useState } from 'react'
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs';
import { AuthContext } from '../context/authContext'
// import profileService from "../services/profile.service";
import alertService from '../services/sweetAlert'
import profileService from "../services/profile.service";


//import { PostsContext } from '../context/postsContext'

dayjs.extend(relativeTime)
export default function SinglePostComment ({ item, parentId, setParentId,showInput,setTaguser,deleteComments }) {
  const { currentUser } = useContext(AuthContext)
  const [playing, setPlaying] = useState(null);

  //const { organizeComments } = useContext(PostsContext)
  const cdn = 'https://files.geetsuhane.com/';


  var music = new Audio(null);
  var song = new Audio(null);
  const playSong = async(pid) => {
    console.log(playing);
    try{
        if( playing===true) {
         // music.pause();
          song.pause();
          setPlaying(false);
          return;
        }
        setPlaying(true)
        var songs =  profileService.getResult({
          qry: `CALL sp_getMusic(${pid})`
        }).then(songs => {
           //music = new Audio(songs?.[0][0]?.musicUrl);
           song = new Audio(cdn+'users/'+ songs?.[0][0]?.songUrl);
          console.log(cdn+'users/'+ songs?.[0][0]?.songUrl)
          //music.play();
          song.play();
          setPlaying(true)
          //music.maxDistance=40;
          song.addEventListener("ended", function(){
            song.currentTime = 0;
            //music.pause();
            //  console.log("ended");
            setPlaying(false)
          });
        }
          
      )
    } catch(ex) {
      console.log(ex);
      alertService.info(
        'Error',
        'Apologies, unable to play this song');
        setPlaying(false);
    }
   
  }
  
  //   const organizedComments = useMemo(
  //     () => organizeComments(item?.childComments),
  //     [item, item?.childComments]
  //   )
  
  return (
    <div className={`px-3`}>
      <div className={`${parentId === item.id ? 'bg-grey' : ''} p-2`}>
        <div className='d-flex align-items-center'>
        {item?.status!=99 &&
        <a  href={`/userpage?uid=${item?.user?.SID}`}>
            <figure className='avatar me-3 my-0'>
              {item?.user?.picture ? (
                <img
                  src={item?.user?.picture.includes('googleusercontent')?item?.user?.picture:cdn + 'users/' + item?.user?.picture}
                  alt='avater'
                  className='shadow-sm rounded-circle w45'
                />
              ) : (
                <div className='align-items-center bg-grey d-flex justify-content-center rounded-img-sm'>
                  <span className='ti-user font-sm'></span>
                  
                </div>              
              )}
            </figure></a> }
          <div> 
             <div className='fw-500 text-black-500 lh-24 font-xssss mb-0 mx-1'>
             <a  href={`/userpage?uid=${item?.user?.SID}`}>
             <h4 className="fw-700 text-grey-900 font-xssss mt-1">
            {item?.user?.name}            
         </h4>  </a>
          {item?.content?.includes('giphy.com/media')?
          <img src={item?.content}></img>:item?.content
          } 
            { item.mediaType==='song' &&
              <div class="container">
              
              <a onClick={() => playSong(item?.id)}>
                  <img 
                  src={item?.user?.picture} 
                  className="feather-youtube "
                  width="100%"
                  height="100%" 
                  
                  />
               <i className={`btn ${playing?'feather-pause':'feather-youtube'} text-white font-lg`}></i>
                            
              </a>
              </div>
              
              }
          
          <div className='d-flex align-items-center' >
            <p className='my-0'>{dayjs(item.createdAt)?.fromNow()}</p>
            <div
              onClick={() => {
                setParentId(item.id);setTaguser(item?.user?.name);showInput(true);
              }}
              className='mx-4 bg-grey px-2 cursor-pointer'
            >
              Reply
              
            </div>
            <span className='align-items-left'>
            {item?.user?.id === currentUser?.id && item.id && (
                                          <span
                                            onClick={() => {
                                              deleteComments(item.id);
                                            }}
                                            className='ti-trash cursor-pointer'
                                          ></span>
                                        )}
            </span>
          </div>
        </div></div>
                
        </div>
      
      </div>
      {item?.childComments?.map(comment => (
        <SinglePostComment
          key={comment.id}
          item={comment}
          parentId={parentId}
          setParentId={setParentId}
          showInput={showInput}
          setTaguser ={setTaguser}
          deleteComments ={deleteComments}
        />
      ))}
    </div>
  )
}
