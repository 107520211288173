import React,{Component} from 'react';
import { Link } from 'react-router-dom';

// class Appfooter extends Component {
    export default function Appfooter(props)
    {
    // render() {
        return (
            // <div></div>
            
            <div className="app-footer border-0 shadow-lg bg-primary-gradiant">
                 <Link to={props?.first==='home'?'/':'/userpage'} className="nav-content-bttn"><i className={`feather-${props?.first==='home'?'home':'user'}`}></i></Link>
                {/* <Link to="/moments" className="nav-content-bttn nav-center"><i className="feather-cpu"></i></Link> */}
                <Link to={props?.second==='user'?'/userpage':'/moments'} className="nav-content-bttn"><i className={`feather-${props?.second==='user'?'user':'cpu'}`}></i></Link>

                <Link to="/fs" className="nav-content-bttn nav-center"><i className="feather-mic"></i></Link>
                <Link to="/notification" className="nav-content-bttn"><i className="feather-bell"></i></Link>
                <Link to="/chatlist" className="nav-content-bttn" data-tab="chats"><i className="feather-inbox"></i></Link>           
               
               
                
            </div>   
        );
    }


// export default Appfooter;