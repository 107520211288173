import React, { Component , Fragment, useState , useContext, useEffect} from "react";
import Pagetitle from '../components/Pagetitle';
import profileService from "../services/profile.service";
import { AuthContext } from "../context/authContext";
import { useNavigate,Link } from 'react-router-dom';

import { EventContext } from "../context/eventContext";
    export default function Audition() {
        const [singers, setSingers] = useState([]);
        const [dummy, setDummy] = useState([]);
        const navigate = useNavigate()
        const { currentUser } = useContext(AuthContext);

        var dummyList="";
        if(currentUser==null)
        {
          localStorage.setItem("redirect", "/audition")
          navigate('/login')
        } 
        const cdn = "https://files.geetsuhane.com/"
    
        const getSingers = async () => {        
          var singerslist = await profileService.getResult({
            qry: "select mid, mname,smsid,team from gsglobal.members where mname!='' and smsid!='' and team!=''  order by mname, team"
          });
          setSingers(singerslist);
          setDummy (singerslist);
    
          //console.log(singerslist);
    
        }
        useEffect(() => {
            async function fetchSingers() {
              await getSingers();
            }
            fetchSingers();
          }, []);
    
        const saveRating = async (singer) => {            
            // await eventService.saveRatings({
            //   judgeid: currentUser.id,
            //   singerid: singer,  
            //   eventid: '3rd Audition',            
            //   rating:rating
            //})           
          };

          const searchChange = p => {          
            if(p.length>0) {
                setSingers(
                    singers.filter(singer => {
                      return singer.mname.toLowerCase().includes(p.toLowerCase())
                    })
                  )
            } else {
                //console.log(dummyList);
                setSingers(dummy);
            }
           
          }

       
        return (
            <Fragment> 
             
                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            <div className="row">
                                <div className="col-xl-12">
                                    <Pagetitle title="Nominate Now!" onChange={searchChange}/>

                                    <div className="row ps-2 pe-2">
                                    <div  className="col-md-8 col-sm-4 pe-2 ps-2">
                                    <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                                                <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">                                                                               
                                                    <Link to={`/nominations`}
                                                    //bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block
                                                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white">Add New Member</Link>
                                                </div>
                                        </div>
                                            </div>
                                        {singers.map((value , index) => (
                                        <div key={index} className="col-md-8 col-sm-4 pe-2 ps-2">
                                            <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                                                <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                                                    {/* <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65 z-index-1"><img src={value.imgpath?'https://cdn.geetsuhane.com'+value.imgpath:`assets/images/user.png`} alt="avater" className="float-right p-0 bg-white rounded-circle w-100 shadow-xss" /></figure> */}
                                                    <div className="clearfix w-100"></div>
                                                    <h4 className="fw-700 font-xsss mt-3 mb-0">{value.mname} - {value.team}</h4>
                                                    <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">{value.smsid}</p>                                                                             
                                                    <Link to={`/nominations?smsid=${value.smsid}`}
                                                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white">Add Details</Link>
                                                </div>
                                                  
                                            </div>
                                           
                                        </div> 
                                        
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        );
}
