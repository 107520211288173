import React, { useState,useEffect } from "react";
// import {updateUserDescription,getuserProfileData} from '../firestoreService'
import { AuthContext } from "../context/authContext";
import { useContext } from "react";
// import authService from "../services/auth.Service";
import profileService from "../services/profile.service";
import { useNavigate } from 'react-router-dom';



const Profiledetail = (props) => {
 
  //const [ isEdit, setIsEdit] = useState(false);
  const [grpName, setGrpName] = useState(null);
  const [grpPic, setGrpPic] = useState(null);
  const [pdetail, setPdetail] = useState(null);
    //const [profileImage, setProfileImage] = useState("");
    const [location, setLocation] = useState("")
    const [bio, setBio] = useState("")
    const navigate = useNavigate()



  const { currentUser } = useContext(AuthContext);
  const queryParams = new URLSearchParams(window.location.search)
  const term = queryParams.get("uid")

  const getGroupDetails = async () => {     
    var exps = await profileService.getResult({
        qry: `CALL sp_totalExps('${term?term: currentUser?.SID}');`
    }); 
   //console.log(exps[0][0].grppic);
    //setExps(exps[0][0]?exps[0][0].totalExps:0);
    //setGroupPic(exps[0][0]?exps[0][0].grppic:null);
    if(exps[0][0]) {
      setGrpName(exps[0][0].gname?exps[0][0].gname:'General Group');
      setGrpPic(exps[0][0].grppic?exps[0][0].grppic:null);
    }
  }
 
  const profileDetails = async () => {
  //console.log(currentUser);
  var profie = await profileService.getProfile({
    uid: term?term: currentUser?.SID,
  });
  //console.log(profie);
  if(profie!=null  ) {
      if(profie.length>0) {
      //console.log(profie[0].name);
      //let pdetails = profie[0];
      setPdetail(profie[0]);
      // setLocation(pdetails.location?pdetails.location:'');
      // setName(pdetails.name?pdetails.name:'');
      // setBio(pdetails.bio?pdetails.bio:'');      
      }
      //const pdetails=profie[0];
      //console.log(profie[0].name)
  }
}


useEffect(() => {
  async function fetchProfile() {
    await profileDetails();
    await getGroupDetails()
  }
  fetchProfile();
}, []);


  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
   
      <div className="card-body d-flex pt-0">
        {grpPic? (
          <img className="h-25x w-25x pr-5x pointer" src={`https://files.geetsuhane.com/${grpPic}`} />
        ) :
        <i className="feather-users text-grey-500 me-3 font-lg"></i>
        }
   
    <h4  onClick={() => navigate(`/search?groupDetail=${grpName}`)} className="fw-700 text-grey-900 font-xssss mt-1 pl-5x pointer">
      {grpName} 
    </h4>
  </div>

  <div className="card-body border-top-xs d-flex">
    
    <i className="feather-lock text-grey-500 me-3 font-lg"></i>
    <h4 className="fw-700 text-grey-900 font-xssss mt-0">
      About{" "}
      <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
       {bio}
      </span>
    </h4>
  </div>

  <div className="card-body d-flex pt-0">

    <i className="feather-eye text-grey-500 me-3 font-lg"></i>
    <h4 className="fw-700 text-grey-900 font-xssss mt-0">
      Visible{" "}
      <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
        Anyone can find you
      </span>
    </h4>
  </div>
  <div className="card-body d-flex pt-0">
    <i className="feather-map-pin text-grey-500 me-3 font-lg"></i>
    <h4 className="fw-700 text-grey-900 font-xssss mt-1">
      {location?location:'Secret'}
    </h4>
  </div>

</div>

  )
};

export default Profiledetail;

