import React, { Fragment, useContext, useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import ChatUsers from "../components/chat/ChatUsers";
// import Popupchat from "../components/Popupchat";
import AudioRecorder from "../components/chat/audioRecorder";
import AudioPlayer from "../components/chat/audioPlayer";

import { AuthContext } from "../context/authContext";
import { SocketContext } from "../context/chatContext";
import alertService from "../services/sweetAlert";
import GiphyGif from "../components/GiphySearch";
import profileService from "../services/profile.service";

export default function Chat() {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const cdn = 'https://files.geetsuhane.com/';
  const {
    messages,
    sendMessage,
    activeChat,
    messageContainerRef,
    handleSendAudio,
    deleteMessage,
    windowWidth,
    setActiveChat,
  } = useContext(SocketContext);
  const [newMessage, setNewMessage] = useState("");
//console.log(localStorage.getItem("ibid"))

console.log(messages)
const queryParams = new URLSearchParams(window.location.search)
const chatid = queryParams.get("ibid");
const ibpic = queryParams.get("ibpic");
const ibuser = queryParams.get("ibuser");

  if(chatid?.length>0) {
    setActiveChat(chatid);
  } else {
    navigate('/chatlist');
  }
console.log(currentUser?.SID)
  const msgs =  messages.filter (msg => (msg.ib1==chatid && msg.ib2==currentUser?.SID) || (msg.ib1==currentUser?.SID && msg.ib2==chatid) );
 
  //const chatid =localStorage.getItem("ibid");
  
  const submitMessage = (msgType,gif) => {
   
    sendMessage({
      message: msgType==='gif'?gif: newMessage,
      senderId: currentUser.id,
      name: currentUser.name,
      picture: currentUser.picture,
      receiverId: activeChat,
      time: new Date(),
      msgType: msgType,
      sender: currentUser,
      ib1: currentUser?.SID,
      ib2: chatid
    })
  
    setNewMessage('');
}

//console.log(messages)
const submitHandler = e => {
  if (newMessage.length) {
    submitMessage('text','');
    setNewMessage('');
  }
}
  // const submitHandler = (e) => {
  //   console.log(activeChat);
  //   if (newMessage.length) {
  //     sendMessage({
  //       message: newMessage,
  //       senderId: currentUser.id,
  //       receiverId: activeChat,
  //       time: new Date(),
  //       msgType: "text",
  //       sender: currentUser,
  //     });
  //     setNewMessage("");
  //   }
  // };
  const deleteMessageAction = async (id) => {
    const confirmed = await alertService.confirm(
      "Delete Message",
      "Are you sure you want to delete this message?"
    );

    if (confirmed) {
      // delete the item
      await deleteMessage(id);
      alertService.success(
        "Message Deleted",
        "The message has been successfully deleted."
      );
    }
  };


  const sendGif = (gif) => {
    //console.log(gif);
    submitMessage('gif',gif);
  }

  const updatereadStatus =async () => {
    const pupdate = await profileService.getResult({
      qry :`update msgcount m set mcount=0 where m.receiverId='${currentUser?.id}' and m.senderId='${localStorage.getItem("ibid")}';`
    });
    // console.log(pupdate)
  }

  useEffect(() => {
      async function updateStatus() {

        await updatereadStatus();
      } 
      updateStatus();
  },[])
  return (
    <Fragment>
      {/* {windowWidth < 1600 && !activeChat?.id ? (
        <ChatUsers />
      ) : (
        windowWidth >= 1600 && <ChatUsers />
      )} */}
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div
            className="middle-sidebar-left pe-0"
            style={{ maxWidth: "100%" }}
          >
            <div className="row">
              <div className=" position-relative">
              <div className="text-center font-xsss mx-0">
                  <a  href={`/userpage?uid=${localStorage.getItem("ibid")}`}>
                <img
                  className="rounded-img-sm m-0 pointer"
                  src={
                    ibpic?.includes('googleusercontent')?localStorage?.getItem('ibpic'):cdn+'users/'+ibpic
                   
                  }
                  alt={ibuser} 
                /> {ibuser}
                </a> 
                    </div>
                <div
                  className="chat-wrapper pt-0 w-100 position-relative scroll-bar bg-white theme-dark-bg"
                  ref={messageContainerRef}
                >
                  {windowWidth < 1600 && activeChat?.id && (
                    <div
                      onClick={() => {
                        navigate('/chatlist');
                      }}
                      className="chat-back-button"
                    >
                      <i class="ti-arrow-left text-white"></i> 
                    </div>
                  )}
            
                  <div className="chat-body p-3 ">
                    <div className="messages-content pb-5">
                      {msgs.length
                        ? msgs.map(
                            (
                              {
                                id,
                                message,
                                senderid,
                                picture,
                                name,
                                createdAt = new Date(),
                                msgType,
                              },
                              index
                            ) => { 
                              return (
                                <div
                                  key={`${index}${id}`}
                                  className={`message-item ${
                                    senderid === currentUser?.id
                                      ? "outgoing-message"
                                      : ""
                                  }`}
                                >
                                  <div className="message-user">
                                  <figure className='avatar'>
                                      {picture ? (
                                        <img
                                          className='rounded-img-sm mx-3'
                                          src={
                                            picture.includes('googleusercontent')?picture:
                                            'https://files.geetsuhane.com/users/' +
                                            picture
                                          }
                                          alt=''
                                         
                                        />
                                      ) : (
                                        <div className='align-items-center bg-grey d-flex justify-content-center rounded-img-sm mx-3'>
                                          <span className='ti-user font-sm'></span>
                                        </div>
                                      )}
                                    </figure>
                                    <div className="">
                                    <div className='d-flex gap-2' >
                                      <a className='pointer'  href={`/userpage?uid=${senderid}`}>
                                        <span className='font-xsssss'>{name}</span>
                                        </a>
                                      </div>
                                    <div className="time">
                                      {new Date(createdAt).toLocaleTimeString()},{new Date(createdAt).toDateString()}
                                      </div>
                                    <div className="message-wrap justify-content-between d-flex align-items-center">
                                    {msgType === 'audio' ? (
                                          <AudioPlayer url={message} />
                                        ) : (
                                         msgType==='gif'? <img src={message}></img>:
                                         <span className="d-block font-xssss fw-500 mt-1 lh-3 text-blue-500" dangerouslySetInnerHTML={{ __html: (message.replace('http','').replace('www','')) }}></span>

                                        )}
                                    {/* {msgType === "audio" ? (
                                      <AudioPlayer url={message} />
                                    ) : (
                                      message
                                    )} */}
                                    {senderid === currentUser?.id && id && (
                                      <span
                                        onClick={() => {
                                          deleteMessageAction(id);
                                        }}
                                        className="ti-trash cursor-pointer"
                                      ></span>
                                    )}
                                  </div>
                                    </div>
                                  </div>
                                 
                                </div>
                              );
                            }
                          )
                        : ""}

                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
                <div
                  className="chat-bottom dark-bg p-3 shadow-none theme-dark-bg"
                  style={{ width: "98%" }}
                >
                   {  newMessage.length>2 && newMessage.length<15 &&
           <GiphyGif text={newMessage}  onClick={sendGif}></GiphyGif> }
                  <form
                    className="chat-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      const functionToCall = newMessage
                        ? submitHandler
                        : handleSendAudio;
                      functionToCall();
                    }}
                  >
                    <AudioRecorder />
                    <div className='form-group'>
                     
                     <textarea
                    value= {newMessage}
                     rows={3}
                     cols ={5}
                     maxLength="250"
                     autoFocus
                     placeholder ='Start typing..'
                     onChange={e => {
                       setNewMessage(e.target.value)
                     }}
                     className='chat-textarea w-100 bg-grey font-xsss'/>
                   </div>
                   
                   <button type='submit' className={`bg-current`}>
                     <i className='ti-arrow-right text-white'></i>
                   </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Popupchat /> */}
    </Fragment>
  );
}
