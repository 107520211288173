import React, { Component } from "react";
import axios from "axios";

import { useEffect,useContext,useState } from "react";
import { AuthContext } from "../context/authContext";


import profileService from "../services/profile.service";

import authService from "../services/auth.Service";

import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import alertService from "../services/sweetAlert";


export default function Ratings(props) {
  const { currentUser} = useContext(AuthContext);
  const [value, setValue] = useState(0);


   
  const getRating = async () => {   
    //console.log(props?.type)
    //var sql=`select rating from usersum s join users u on s.uid=u.id where u.SID='${props?.uid}';`
    if(props?.type==="game" || props?.type==="Top Planet") {
        //   const sql = `select sum(rating)/count(rating) as rating from stars where uid='${props?.uid}';`
        //   var stars = await profileService.getResult({
        //     qry: sql
        // });
        setValue(parseInt(5))
    }  else {
      try {
        if (typeof props?.uid === 'undefined') return;
        const response = await axios.post(
          `${process.env.REACT_APP_API_END_POINT}/redis/rating`,
          { SID: props?.uid }
        );
  
        if (response.data) {
          //console.log(response.data?.rating)
          setValue(parseInt(response.data?.rating))
        }
       } catch (err) { }
    }
    
   
    //console.log(stars);
    //setValue(parseInt(stars?.[0]?stars[0].rating:0))
  } 

  const updateRating = async (newval) => {  
    if(currentUser==null) {
      alertService.error('Not Allwoed','You need to login first');
      return;
    }
    if(props?.uid===currentUser?.SID) {
      alertService.error('Not Allwoed','You can not rate yourself!');
      return;
    }
    const typ = props?.type==="game"?props.type:"";
    var rating = await profileService.getResult({
        qry: `CALL sp_updateRatings('${props?.uid}','${currentUser?.id}',${newval},'${typ}');`
    });
    //console.log(rating);
  } 
   
  useEffect(() => {
   
    async function fetchRating() {
       await getRating()
    }
    fetchRating();    
  }, []);

  return (
    <div>
    <Box>  
      <Rating
       name="ratings" size='small'
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);updateRating(newValue);
        }}
      />
     
    </Box>
    </div>  
  )
}