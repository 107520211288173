import React from 'react'

export default function AuthLayout ({ children }) {
  return (
    <div>
      {/* <Header /> */}
      {children}
    </div>
  )
}
