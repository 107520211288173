import React, { Fragment, useContext, useState,useEffect } from "react";
import { Link } from 'react-router-dom';
import { AuthContext } from "../context/authContext";
import alertService from "../services/sweetAlert";
import { useNavigate } from 'react-router-dom';


function Password () {
const { resetpassword } = useContext(AuthContext);
const queryParams = new URLSearchParams(window.location.search)
let linkType = window.location;
const [password, setPassword] = useState("");
const [confirmpassword, setConfirmpassword] = useState("");
const navigate = useNavigate();

const handleSubmit = async (e) => {
    const tmpid = queryParams.get("uid");
    console.log(tmpid)
    if(!tmpid) {
        alertService.info("Reset password", "Seems this link is not valid any more, please contact support@geetsuhane.com");
        return;
    }
    e.preventDefault();
    
    if (password === confirmpassword) {
      if(password.length<8) {
        alertService.error("Reset password", "Password must be atleast 8 characters!");
        return;
      }
        alertService.info("Reset password", "Password has been reset successfully");
      const pwdreset = await resetpassword({ tempid:tmpid, password });
     
      navigate('/login');
      //console.log(user)
    } else {
      alertService.info(
        'Error',
        'Passwords must match!');
      return;
    
    }
  };

  
        return (
            <Fragment> 

            <div className="main-wrapper">



                <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
            
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="middle-wrap">
                                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                    <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                    <Link to="/login" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                        <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">
                                            {linkType.toString().includes('resetpwd',0)?'Reset Password':'Change Password'}</h4>
                                    </div>
                                    <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <form onSubmit={handleSubmit}>
                                            <div className="row">
                                            {linkType.toString().includes('changepwd',0) && (
                                                <div className="col-lg-12 mb-3">
                                                    <div className="form-gorup">
                                                        <label className="mont-font fw-600 font-xssss">Current Password</label>
                                                        <input type="cPassword" name="comment-name" className="form-control" 
                                                         value={password}
                                                         onChange={(e)=>{
                                                             setPassword(e.target.value)
                                                         }}
                                                        />
                                                    </div>        
                                                </div>
                                                )}

                                                <div className="col-lg-12 mb-3">
                                                    <div className="form-gorup">
                                                        <label className="mont-font fw-600 font-xssss">Change Password</label>
                                                        <input type="Password" name="comment-name" className="form-control" 
                                                         value={password}
                                                         onChange={(e)=>{
                                                             setPassword(e.target.value)
                                                         }}
                                                        />
                                                    </div>        
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12 mb-3">
                                                    <div className="form-gorup">
                                                        <label className="mont-font fw-600 font-xssss">Confirm Change Password</label>
                                                        <input type="Password" name="comment-name" className="form-control"
                                                         value={confirmpassword}
                                                         onChange={(e)=>{
                                                            setConfirmpassword(e.target.value)
                                                        }}
                                                         />
                                                    </div>        
                                                </div>                                     
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 mb-0">
                                                <button
                                                    type="submit"
                                                    className="col-12 col-md-12 p-0 border-0 text-left"
                                                >
                                                    <div className="form-group mb-1">
                                                    <div className="form-control text-center w-100 style2-input text-white fw-600 bg-dark border-0 p-0 ">
                                                        Reset password
                                                    </div>
                                                    </div>
                                                </button>
                                                </div>
                                            </div>

                                            
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>            
                </div>
            </div>

            </Fragment>
        );
    }


export default Password;