import ApiService from "./api.service";

class PostService {
  constructor(apiService) {
    this.apiService = apiService;
  }

  async getAllPosts(pageNumber = 1, pageSize = 10) {
    try {
      const response = await this.apiService.get(
        `/posts?page=${pageNumber}&size=${pageSize}`
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async toplist() {
    try {
      const response = await this.apiService.get(`/posts/toplist`);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async result() {
    try {
      const response = await this.apiService.get(`/event/result`);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async topShow() {
    try {
      const response = await this.apiService.get(`/posts/topShow`);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async listSingers() {
    try {
      const response = await this.apiService.get(`/posts/singers`);
      console.log("response:" + response);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async getPostById(id) {
    try {
      const response = await this.apiService.get(`/posts/${id}`);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async createPost({ text, mediaUrl, mediaType, userId }) {
    try {
      //console.log(mediaUrl);
      const response = await this.apiService.post("/posts", {
        text,
        mediaUrl,
        mediaType,
        userId,
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async updatePost(id, data) {
    try {
      const response = await this.apiService.put(`/posts/${id}`, data);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async deletePost(id) {
    try {
      const response = await this.apiService.delete(`/posts/${id}`);
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async reactToPost({ postId, userId, reaction }) {
    try {
      const response = await this.apiService.post(`/reactions`, {
        postId,
        userId,
        reaction,
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
  async commentToPost({ postId, userId, content, parentId }) {
    try {
      const response = await this.apiService.post(`/comments`, {
        postId,
        userId,
        content,
        parentId,
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }
}

// Example usage:
const apiService = new ApiService();
const postService = new PostService(apiService);

export default postService;
