import React, { Fragment, useContext, useEffect, useState, useRef } from 'react';
import { createSocket } from '../socket';
import { AuthContext } from '../context/authContext';
import { useParams, useNavigate } from 'react-router-dom';
import alertService from "../services/sweetAlert";
import './Loka.css';

export default function Loka() {
  const { currentUser } = useContext(AuthContext);
  const [messages, setMessages] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [taggedUser, setTaggedUser] = useState('');
  const { room } = useParams();
  const socket = createSocket();
  const cdn = 'https://files.geetsuhane.com/';
  const [backgroundImage, setBackgroundImage] = useState('https://files.geetsuhane.com/loka/vishnu5.gif');
  const navigate = useNavigate();

  const localAudioRef = useRef(null);
  const remoteAudioRef = useRef(null);
  const peerConnections = useRef({}); // Store WebRTC peer connections
  const localStream = useRef(null); // Store local audio stream

  // Dynamically set background based on room
  useEffect(() => {
    switch (room.toLowerCase()) {
      case 'vishnu':
        setBackgroundImage("url('https://files.geetsuhane.com/loka/vishnu5.gif')");
        break;
      case 'shiv':
        setBackgroundImage("url('https://files.geetsuhane.com/loka/shiva.jpeg')");
        break;
      case 'bramh':
        setBackgroundImage("url('https://files.geetsuhane.com/loka/bramha.jpeg')");
        break;
      default:
        setBackgroundImage("url('https://files.geetsuhane.com/loka/vishnu5.gif')");
    }
  }, [room]);

  // Join room and update users/messages
  useEffect(() => {
    if (room) {
      if(currentUser) {
        switch (room.toLowerCase()) {
          case 'vishnu':
            if(currentUser?.gname==='Rockers' || currentUser?.gname==='Tigers' || currentUser?.gname==='Global Stars' || currentUser?.SID==='008') {
            } else {
              alertService.info('Not authorized', 'Only Rockers, Tigers and Global Stars are allowed in this room');
              navigate('/gupshup/'+currentUser?.gname);
            }
            break;
          case 'shiv':
            if(currentUser?.gname==='Bandish' || currentUser?.gname==='Dazzlers' || currentUser?.gname==='Incredibles' || currentUser?.SID==='008') {
            } else {
              alertService.info('Not authorized', 'Only Bandish, Dazzlers and Incredibles are allowed in this room');
              navigate('/gupshup/'+currentUser?.gname);
    
            }
            break;
          case 'bramh':
            if(currentUser?.gname==='Singing Stars' || currentUser?.gname==='Sargam' || currentUser?.gname==='Rhythm' || currentUser?.SID==='008') {
            } else {
              alertService.info('Not authorized', 'Only Sargam, Singing Stars and Rhythm are allowed in this room');
              navigate('/gupshup/'+currentUser?.gname);             
            }
            break;
        }
        socket.emit('joinRoom', { room, userId: currentUser?.SID, picture: currentUser?.picture });
      }

     

      socket.on('user-joined', (userId) => {
        if (!peerConnections.current[userId]) {
            setupPeerConnection(userId); // Create peer connection
        }
    });

      socket.on('roomUsers', (users) => {
        const uniqueUsers = users.filter(
          (user, index, self) => index === self.findIndex(u => u.userId === user.userId)
        );
        setOnlineUsers(uniqueUsers);
      });

      socket.on('message', (newMessage) => {
        setMessages((prevMessages) => [...prevMessages, newMessage].slice(-10));
      });

      socket.on('offer', handleReceiveOffer);
      socket.on('answer', handleReceiveAnswer);
      socket.on('ice-candidate', handleReceiveIceCandidate);


      return () => {
        socket.off('roomUsers');
        socket.off('message');
        socket.off('offer');
        socket.off('answer');
        socket.off('ice-candidate');
      };
    }
  }, [room, currentUser]);

  // Send message when Enter is pressed
  const sendMessage = (e) => {
    if (e.key === 'Enter' && newMessage && room) {
      e.preventDefault();
      const messageData = {
        room,
        message: `${taggedUser} ${newMessage}`.trim(),
        userId: currentUser?.SID,
        name: currentUser?.name,
        picture: currentUser?.picture,
      };
      socket.emit('sendMessage', messageData);
      setNewMessage('');
      setTaggedUser('');
    }
  };

  // Example of setting up a peer connection when a new user joins
const setupPeerConnection = (userId) => {
  const peerConnection = new RTCPeerConnection();
  console.log('Setting up peer connection with:', userId);
  peerConnections.current[userId] = peerConnection; // Add to peerConnections

  // Set up other necessary handlers (e.g., onicecandidate, ontrack) for the peer connection
  peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
          socket.emit('ice-candidate', {
              target: userId,
              candidate: event.candidate,
          });
      }
  };

  peerConnection.ontrack = (event) => {
      // Attach remote track to the audio element, for example:
      remoteAudioRef.current.srcObject = event.streams[0];
  };
};


  const handleTagUser = (user) => {
    if (user.userId !== currentUser.SID) {
      setTaggedUser(`@${user.name}`);
    } else {
      setTaggedUser('');
    }
  };

  // Get avatar position to arrange in rows
  const getAvatarPosition = (index) => {
    const avatarsPerRow = 5;
    const row = Math.floor(index / avatarsPerRow);
    const col = index % avatarsPerRow;
    const verticalOffset = 85 - (row * 10);
    const horizontalSpacing = 18;
    return {
      top: `${verticalOffset}%`,
      left: `${10 + col * horizontalSpacing}%`,
    };
  };

  // Handle WebRTC offer
  const handleReceiveOffer = async ({ offer, fromUserId }) => {
    try {
      console.log('Received offer from:', fromUserId);
      const peerConnection = createPeerConnection(fromUserId);
      await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
      const answer = await peerConnection.createAnswer();
      await peerConnection.setLocalDescription(answer);

      socket.emit('answer', { answer, toUserId: fromUserId });
      console.log('Sent answer to:', fromUserId);
    } catch (error) {
      console.error('Error receiving offer:', error);
    }
  };

  // Handle WebRTC answer
  const handleReceiveAnswer = async ({ answer, fromUserId }) => {
    console.log('Received answer from:', fromUserId);
    const peerConnection = peerConnections.current[fromUserId];
    if (peerConnection) {
      await peerConnection.setRemoteDescription(new RTCSessionDescription(answer));
      console.log('Set remote description for answer.');
    }
  };

  // Handle ICE candidates
  const handleReceiveIceCandidate = ({ candidate, fromUserId }) => {
    console.log('Received ICE candidate from:', fromUserId);
    const peerConnection = peerConnections.current[fromUserId];
    if (peerConnection) {
      peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
      console.log('Added ICE candidate.');
    }
  };

  // Create peer connection
  const createPeerConnection = (userId) => {
    const peerConnection = new RTCPeerConnection({
      iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
    });

    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        console.log('Sending ICE candidate to:', userId);
        socket.emit('ice-candidate', { candidate: event.candidate, toUserId: userId });
      }
    };
    peerConnection.oniceconnectionstatechange = () => {
      console.log('ICE Connection State:', peerConnection.iceConnectionState);
      if (peerConnection.iceConnectionState === 'connected') {
          console.log('Peer connection established');
      }
  };
  
  peerConnection.onnegotiationneeded = async () => {
      try {
          const offer = await peerConnection.createOffer();
          await peerConnection.setLocalDescription(offer);
          socket.emit('offer', { offer, toUserId: userId });
          console.log('Negotiation needed: Sent offer to:', userId);
      } catch (error) {
          console.error('Error during negotiation:', error);
      }
  };
  

    peerConnection.ontrack = (event) => {
      console.log('Remote track added:', event.streams[0]);
      remoteAudioRef.current.srcObject = event.streams[0];
  };

  

    peerConnections.current[userId] = peerConnection;
    return peerConnection;
  };

  // Start broadcasting audio
  const startVoiceBroadcast = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      localStream.current = stream;
      

      if (localAudioRef.current) {
        localAudioRef.current.srcObject = stream;
      }

      console.log(stream.getTracks());
      console.log(peerConnections.current);
      for (const userId in peerConnections.current) {
        console.log('Adding tracks to:', userId);
        stream.getTracks().forEach(track => peerConnections.current[userId].addTrack(track, stream));
      }

      for (const userId of Object.keys(peerConnections.current)) {
        const peerConnection = peerConnections.current[userId];
        const offer = await peerConnection.createOffer();
        await peerConnection.setLocalDescription(offer);
        socket.emit('offer', { offer, toUserId: userId });
        console.log('Sent offer to:', userId);
      }
    } catch (error) {
      console.error('Error starting voice broadcast:', error);
    }
  };

  return (
    <Fragment>
      <div className="main-content virtual-world" style={{ backgroundImage: backgroundImage }}>
      <audio ref={localAudioRef} autoPlay muted />
      <audio ref={remoteAudioRef} autoPlay />
        {onlineUsers.map((user, index) => (
          user?.userId && user?.picture && (
            <div
              key={user?.userId}
              className="avatar-container"
              style={getAvatarPosition(index)}
              onClick={() => handleTagUser(user)}
            >
              <div
                className="avatarlok"
                style={{ backgroundImage: `url(${cdn + 'users/' + user?.picture})` }}
              />

              {messages
                .filter((msg) => msg.userId === user?.userId)
                .slice(-1)
                .map((msg, idx) => (
                  <div key={idx} className="chat-bubble">
                    <span className="message-sender">{msg.name}:</span>
                    <span className="message-content">{msg.message}</span>
                  </div>
                ))}

              {user.userId === currentUser.SID && (
                <div className='input-container'>
                  <input
                    type="text"
                    className="message-input-below-avatar"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={sendMessage}
                    placeholder={taggedUser ? `Reply to ${taggedUser}` : 'Type your message'}
                  />
                     
                <i onClick={startVoiceBroadcast} className="font-xsss text-white text-center feather-mic btn-round-sm bg-gold-gradiant me-1 counter"></i>
           
            </div>
              )}
            </div>
          )
        ))}
      </div>
    </Fragment>
  );
}
