import React, { Component , Fragment, useState , useContext, useEffect} from "react";
import Pagetitle from '../components/Pagetitle';
import profileService from "../services/profile.service";
import { AuthContext } from "../context/authContext";
import { useNavigate } from 'react-router-dom';
import eventService from "../services/event.service";
import alertService from '../services/sweetAlert';
import { Link } from "react-router-dom";


//import { EventContext } from "../context/eventContext";
    export default function Judge() {
       
        const [loading, setLoading] = useState(false);
        const [votestatus, setVoteStatus] = useState('');
        const [sorting, setSorting] = useState('');
        const [members, setMembers] = useState([]);
        const [dummy, setDummy] = useState([]);
        const navigate = useNavigate()
        const { currentUser } = useContext(AuthContext);
        const [event, setEvent] = useState('Loading...');
        const [points, setPoints] = useState(null);

        const queryParams = new URLSearchParams(window.location.search)
        const term = queryParams.get("vote")  
       
      
        if(currentUser==null)
        {
          localStorage.setItem("redirect", "/judge?vote="+term)
          navigate('/login')
        } 

      const updateWinner = async(id, point,purpose) => {
        const pcounts = await profileService.getResult({
          qry :`CALL sp_declareWinners('${id}','${purpose}',${point})`
        }).then (pcounts =>
          alertService.info(
            'Updated!',
            'Winner has been added'
            )
          )
      }

    
    const voteSubmit = async(id,title,text,purpose) => {
        var rating =await  alertService.vote(
            title,
            text
          )
         
          //console.log(rating)
        if (rating) {
            console.log(currentUser.lead)
          //rating= rating* currentUser?.lead:rating;
          if(currentUser?.lead===1) {
            rating =rating*2;
          } else if(currentUser?.lead==2) {
            rating =rating*3;
          }else if(currentUser?.lead>=3 && currentUser?.lead<=6) {
            rating =rating*3;
         
          }else if(currentUser?.lead===7) {
            rating =rating*25;
          
          }else if(currentUser?.lead===8) {
            rating =rating*35;
          } 
          var pcounts = ""
          
          pcounts = await profileService.getResult({
            qry :`CALL sp_getPoints('${currentUser?.id}')`
          });
          console.log(pcounts?.[0][0]?.total);
          if(parseInt(pcounts?.[0][0].total)< (currentUser?.lead>0?1000:2000)) {
            alertService.error(
              'Not Eligible!',
              'You need to have minimum 5K points, only then you can vote'
              );
              return;
          }
          
          getPoints(id);
          // const ipcheck = await profileService.checkIP({
          //   userid: currentUser.id, activity: purpose+''+id
          //   });
          //   if(ipcheck[0])
          //   {
          //     alertService.error(
          //       'Error!',
          //       'You have already voted!'
          //       );
          //       return;
          //   }

          // const res = await profileService.auditData({
          //   userid: currentUser.id, activity: purpose+''+id
          //   });

            saveRating(id,rating,purpose);
            alertService.success(
            'Thank You!',
            'Your vote has been successfully recorded.'
            )
        }
    }
    

        const saveRating = async (singer,rating,purpose) => {            
  console.log('voting')
           const res = await profileService.getResult({
              qry :`CALL sp_insertRatings('${currentUser.id}','${singer}','${purpose}','${rating}')`
            });
                   //console.log(res);
            getList('points');          
          };

          const getList = async (sortby) => {    
           
            setLoading(true);
            var grpList=""
            var prpse=""
           
          

            var eventres = await profileService.getResult({
                qry: `select c.value as value,c.status from gsglobal.config c where c.section='vote' and c.key='${term}' and c.status>0`     
              });
            //console.log(eventres);
              if(eventres.length>0) {
               setEvent(eventres[0].value);
                const evnt = eventres[0].status;
                if(evnt==0) {
                  setVoteStatus('Ended')
                } else if(evnt==1) {
                  setVoteStatus('In progress')
                  setSorting("name");
                  sortby=(sortby!='point'?sortby:'name');
                } else if(evnt==2) {
                  setVoteStatus('Not started yet');
                
                } else if(evnt==3) {
                  setVoteStatus('Ended')
                }
                prpse = eventres[0].value
              } else {
                setEvent('Ended')
              }
               console.log(prpse)

              if(term=="Titles") {
                grpList = await profileService.getResult({
                    // qry: `SELECT  distinct u.id,u.name as uname,u.picture as imgpath, n.event as Purpose, sum(r.rating) as rating
                    // FROM gsglobal.users u join nominations n on u.id=n.uid join gsglobal.config c on c.key='Titles' and c.value=n.event and c.status=1
                    // left join ratings r on r.singerid=n.uid  and
                    // DAY(r.createdAt)=DAY(CURRENT_DATE) AND MONTH(r.createdAt)=MONTH(CURRENT_DATE) AND YEAR(r.createdAt)=YEAR(CURRENT_DATE)
                    // where u.lead=0 and u.picture is not null 
                  
                    // group by u.id,u.name,u.picture, n.event
                    // order by u.name`
                     qry :`CALL sp_getTitlesN('${sortby}')`
                  
                  })
                  //console.log(grpList);
                   
              } 
             else if (term=="Custom" && (prpse=="Best Singers" || prpse==='Best Member')) {
                grpList = await profileService.getResult({
                    // qry: `SELECT distinct u.id, u.name as uname,u.SID, u.picture as imgpath, c.value as Purpose  FROM gsglobal.users u left join gsglobal.config c on c.key='Custom' and c.status=1 
                    // where u.lead=0 and u.name is not null
                    // order by u.name`
                    qry :`CALL sp_getMembersN('${sortby}')`
                  })


                } else if (term=="Custom" && prpse=="Best Judges") {
                    grpList = await profileService.getResult({
                        qry: `SELECT distinct u.id,u.SID, u.name as uname, u.picture as imgpath, c.value as Purpose   FROM gsglobal.users u join gsglobal.ratings 
                        r on u.id=r.judgeid  left join gsglobal.config c on c.key='Custom' and c.status=1
                         where u.lead=1 and u.name is not null and u.id not in ('56ac0b51-5a36-4dd6-91e3-72003e4c8e4c','40b7b63d-946b-4d3a-954f-2aa4bc331ce6')
                        order by u.name`})
                        //Co-captian/Boss
                      } else if (term=="Custom" && prpse=="Co-captian") {
                        grpList = await profileService.getResult({
                          qry: `CALL sp_getLeads('${sortby}',3)`})
                  } else if (term=="Custom" && prpse=="Best Boss") {
                    console.log('best boss')
                    grpList = await profileService.getResult({
                      qry: `CALL sp_getLeads('${sortby}',2)`})
                        }
                  else if (term=="Custom" && prpse=="Best Lead") {
                    //console.log(sorting);
                    grpList = await profileService.getResult({
                          qry: `CALL sp_getLeads('${sortby}',1)`})
                  }  else if (term=="Audition"){
                      grpList = await profileService.getResult({
                          qry: `CALL sp_getWeeklyAuditions('${sortby}')`
                        });
                }
           
            setMembers(grpList[0]);
             setDummy(grpList[0]);
             setLoading(false);
            console.log(grpList[0]);
      
          }

          const getPoints =async (uid) => {
          var pcounts = ""
          pcounts = await profileService.getResult({
            qry :`CALL sp_getPoints('${uid}')`
          });
          if(pcounts) {
            if(pcounts.length>0) {
              setPoints(pcounts?pcounts[0][0].total:0);
            }
          }
          
          //console.log('hi');
          }
     
   

       
            const searchChange = p => {          
                if(p.length>0) {
                    setMembers(
                        members.filter(member => {
                          return member.uname.toLowerCase().includes(p.toLowerCase())
                        })
                      )
                } else {
                    //console.log(dummyList);
                    setMembers(dummy);
                }
               
              }

          useEffect(() => {
            async function fetchList() {
              await getList('points');
            }
            fetchList();
          }, []);
           
        return (
            <Fragment> 
             
                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            <div className="row">
                                <div className="col-xl-12">
                                    <Pagetitle header="Vote" title={event} mini={votestatus} onChange={searchChange}></Pagetitle>
                                    

                                    <div className="row ps-2 pe-2  pl-30">
                                    {votestatus==="In progress"?  (
                                    <div className="fw-500 font-xssss text-gray-500 mt-0 mb-3">Sort by:
                                    <a className="text-blue-500 mt-0  pointer " onClick={() => getList('name')}> Name</a>                                    
                                    <a className="text-blue-500 mt-0  pointer pl-30" onClick={() => getList('points')}> Points</a>                                 
                                     </div>):''}
                                     <span className="pl-30 font-xssss">{loading?'Loading....':''}</span>
                                        {members?.length>0?members.map((value , index) => (
                                        <div key={index} className="col-md-8 col-sm-4 pe-2 ps-2">
                                            <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                                            
                                                <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                                                <a  href={`/userpage?uid=${value?.SID}`}>
                                                    <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65 z-index-1"><img src={value.imgpath?(value.imgpath.includes('googleusercontent')?value.imgpath:'https://files.geetsuhane.com/users/'+value.imgpath):`assets/images/user.png`} alt="avater" className="float-right p-0 bg-white rounded-circle w-100 shadow-xss" /></figure>
                                                    </a>
                                                    <div className="clearfix w-100"></div>
                                                    
                                                    <h4 className="fw-700 font-xsss mt-3 mb-0">{value.uname} </h4>
                                                    <Link to={`/search?groupDetail=${value?.gname}`}  className="bg-secondary pointer p-1 font-xsssss   text-white">   
                                                         <i className="font-xsssss feather-globe btn-round-xsssss bg-primary-gradiant me-1"></i>{value?.gname}  </Link>
                                                    <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">{value.user}</p>
                                                    <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">{value.groupname?value.groupname:(event?'Vote for '+event:'')}</p>
                                                    <p   className="fw-500 font-xssss text-green mt-0 mb-3">{value.points?'Monthly Points: '+ value.points:'' }</p>
                                                    <p className="fw-500 font-xssss text-blue mt-0 mb-3">{value.rating?'Votes: '+value.rating:''}</p>
                                                    <p className="fw-500 font-xssss text-red mt-0 mb-3">{value.total?'Total Points: '+value.total:''}</p>
                                                    
                                                  
                                                    <br/>
                                                    {votestatus==='In progress'?(
                                                    <a  onClick={() => voteSubmit(value.id,value.uname,'Your rating for '+(value.Purpose?value.Purpose:event),(value.Purpose?value.Purpose:event))}
                                                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white">Vote</a>
                                                    ):""}
                                                     {votestatus==='Ended' && currentUser?.id==='40b7b63d-946b-4d3a-954f-2aa4bc331ce6' && (
                                                    <a  onClick={() => updateWinner(value.id,value?.total,(value.Purpose?value.Purpose:event))}
                                                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white">Declare Winner</a>
                                                    )}
                                                    </div>
                                               
                                                  
                                            </div>
                                           
                                        </div> 
                                        
                                        )):""}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        );
}
